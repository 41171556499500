import { installersListColumns } from './inputs';
import React, { useState } from 'react';
import { GetInstallers } from '../../../api/services/userService';
import '../../../App.css'
import CustomTitle from "../../CustomTitle";
import { useHistory } from 'react-router';
import { updateUserPageUrl } from '../costants';
import ResponsiveTable from '../../responsiveTable';
import { updateInstallerTitle } from '../title';
import { installersKey, name } from '../../../costants/costants';

type InstallerTableProps = {
    SetPopupObject: (obj: any) => void
}

const InstallersListTable: React.FC<InstallerTableProps> = ({SetPopupObject}) => {
    
    document.body.style.background = "#f5f5f5c7"
    const [data, setData] = useState<Object[]>([])
    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateUserPageUrl(value))
    }

    return(
        <>
            <CustomTitle title={updateInstallerTitle} />
            <ResponsiveTable 
                columns={installersListColumns(data, SetPopupObject, linkFunction)} 
                getPageAndSortedData={GetInstallers}
                defaultSort={name.name}
                responseDataName={installersKey}
                setData={setData}
            />
        </>
    )
}

export default InstallersListTable;