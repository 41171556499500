import { InputType } from "../../types/form/types";
import { UserOutlined } from "@ant-design/icons";
import { emailRules } from "../../types/form/rules";
import { email } from "../../../costants/costants";

export const inputs: InputType[] = [
    {   
        key: email.key, 
        name: email.name,
        label: email.label,
        type: email.name,
        rules: emailRules,
        icon: <UserOutlined className="site-form-item-icon" />
    }
]