import {MenuType, SubMenuType} from "../types/navbar/types";
import {
    adminPanelIconsUrl,
    alarmsPageUrl,
    createInstallerPageUrl,
    customersListPageUrl,
    installersAssociationsUrl,
    installersListPageUrl,
    subscriptionsListToDataHistoryPageUrl,
    subscriptionsListToInstallationPageUrl,
    subscriptionsListToUpdateUrl} from "../pages/costants";
import {
    addInstallerTitle,
    adminPanelTitle,
    alarmsTitle,
    associationsInstallersTitle,
    dataHistoryTitle,
    dataRealTimeTitle,
    updateInstallerTitle,
    updateSubscriptionTitle,
    updateUserTitle,
} from "../pages/title";
import dataRealTime from "../pages/homePages/img/graph_black.png";
import dataHistory from "../pages/homePages/img/cloud-download_black.png";
import admin from "../pages/homePages/img/admin_black.png";
import installer from "../pages/homePages/img/installer_black.png";
import change from "../pages/homePages/img/change_black.png";
import people from "../pages/homePages/img/people_black.png";
import user from "../pages/homePages/img/user_black.png";
import alarm from "../pages/homePages/img/alert_black.png";
import { adminRole, alarmsKey, createInstallerKey, dataHistoryKey, dataRealTimeKey, installerAssociationsKey, updateInstallerKey, updateSubscriptionKey, updateUserKey } from "../../costants/costants";

const adminItem: SubMenuType[] = [
    {
        key: createInstallerKey,
        label: addInstallerTitle,
        link: createInstallerPageUrl,
        icon: <img alt={"--"} src={installer} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: updateInstallerKey,
        label: updateInstallerTitle,
        icon: <img alt={"--"} src={installer} className={"menu-icon"}/>,
        link: installersListPageUrl,
        elements: []
    },
    {
        key: installerAssociationsKey,
        label: associationsInstallersTitle,
        link: installersAssociationsUrl,
        icon: <img alt={"--"} src={people} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: updateSubscriptionKey,
        label: updateSubscriptionTitle,
        link: subscriptionsListToUpdateUrl,
        icon: <img alt={"--"} src={change} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: updateUserKey,
        label: updateUserTitle,
        link: customersListPageUrl,
        icon: <img alt={"--"} src={user} className={"menu-icon"}/>,
        elements: []
    }
]

export const adminMenuItems: MenuType[] = [
    {   
        key: dataRealTimeKey,
        label: dataRealTimeTitle,
        icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToInstallationPageUrl
    },
    {
        key: dataHistoryKey,
        label: dataHistoryTitle,
        icon: <img alt={"--"} src={dataHistory} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToDataHistoryPageUrl
    },
    {   
        key: alarmsKey, 
        label: alarmsTitle,
        link: alarmsPageUrl,
        icon: <img alt={"--"} src={alarm} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: adminRole,
        label: adminPanelTitle,
        link: adminPanelIconsUrl,
        icon: <img alt={"--"} src={admin} className={"menu-icon"}/>,
        elements: adminItem
    }
]