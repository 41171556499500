import "./resetPassword.css"
import "antd/dist/antd.css"
import ButtonItem from "../../ButtonItem"
import { inputs } from "./inputs"
import { Link, useHistory } from 'react-router-dom'
import { Form, Input, message } from "antd"
import React, { useEffect, useState } from "react"
import { resetPassword} from "../../../api/services/loginService"
import { loginPageUrl, resetPasswordPageUrlResult } from "../costants"
import logo from '../../../logo/logo-armal.png';
import { backgroundColorLogin, loginFormName, primaryType, resetLabel, resetPasswordLabel, returnLoginLabel, textType } from "../../../costants/costants"
import { isTest } from "../../../api/services/httpRequests"

type ResetPanelProps = {

}

const ResetPassword: React.FC<ResetPanelProps> = ({ }) => {
    document.body.style.background = backgroundColorLogin;
    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const [form] = Form.useForm();

    const history = useHistory()
    const submit = () => {
        form.validateFields().then(
            values => {
                resetPassword({
                    email: values.email
                }).then((res)=>{
                    if(res && !res.err){
                        history.push(resetPasswordPageUrlResult)
                    }
                })
            }
        ).catch((e) => {
            if(isTest){
                if(!e.values.email){
                    message.error("email error")
                }
            }
        })
    }

    return (
        <>
        <div className={width <= 768 ? "reset-box-responsive" : "reset-box"}>
            <div className="image-container">
                <img src={logo} className="image" />
            </div>
            <Form name={loginFormName} form={form}>
                <h2> {resetPasswordLabel} </h2>
                {
                    inputs.map((el)=>{ 
                        return(
                            <Form.Item key={el.key} name={el.name} rules={el.rules}>
                                <Input
                                    data-testid={el.key}
                                    size="large"
                                    type={el.type ?? textType}
                                    prefix={el.icon}
                                    placeholder={el.label}
                                />  
                            </Form.Item>
                        )
                    })
                }
                <Link className="reset-form-forgot" to={loginPageUrl}>
                    {returnLoginLabel}
                </Link>
                <div className="reset-btn-container">
                    <Form.Item>
                        <ButtonItem
                            buttonType={primaryType}
                            label={resetLabel}
                            buttonOnClick={submit}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
        </>
    )
}

export default ResetPassword