export const dataRealTimeTitle = "Real Time Data"
export const dataHistoryTitle = "Data History"
export const alarmsTitle = "Alarms"
export const adminPanelTitle = "Admin Panel"
export const addInstallerTitle = "Create Installer"
export const updateInstallerTitle = "Edit Installer"
export const associationsInstallersTitle = "Installers Associations"
export const addSubscriptionTitle = "Create Subscription"
export const updateSubscriptionTitle = "Edit Subscription"
export const addUserTitle = "Create Customer"
export const updateUserTitle = "Edit Customer"
export const addInstallationTitle = "Create Installation"
export const updateInstallationTitle = "Edit Installation"
export const selectInstallationTitle = "Select Installation to Change"
export const installerListTitle = "Installers List"
export const subscriptionsListTitle = "Subscriptions List"
export const installationsListTitle = "Installations List"
export const connectionTitle = "Box Configuration"
export const signalConfigurationTitle = "Signal Configuration"
export const ConfiguratorTitle = "Configurator"
export const technicalTestingTitle: string = "Technical Testing"
export const alarmConfigurationTitle: string = "Alarms"
export const updateAlarmConfigurationTitle: string = "Update Alarm Configuration"