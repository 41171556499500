import './installation.css';
import '../../../App.css';
import React, {useEffect, useState} from 'react';
import {dataRealTimeTitle} from '../title';
import {EBlockModel, EBlockUrl, ETopPlusModel, ETopPlusUrl} from '../costants';
import { getInstallation, maintenanceInstallation } from '../../../api/services/installationsService';
import { Installation } from '../../../api/requests/installationsService';
import { Row, Col, message, Form, Modal, Input } from 'antd';
import AlarmSummary from './summaryCards/AlarmSummary';
import InstallationSummary from './summaryCards/InstallationSummary';
import RealTimeSummary from './summaryCards/RealtimeSummary';
import TitleWithBack from '../../TitleWithBack';
import ButtonConfItem from '../../ButtonConfItem';

type TimeSeriesProps = {
    installation_id: string,
}

const InstallationPage: React.FC<TimeSeriesProps> = ({installation_id}) => {

    document.body.style.background = "#f5f5f5c7"
    const [installation, setInstallation] = useState<Installation | null>(null);
    const [maintenanceDone, setMaintenanceDone] = useState<boolean>(true);
    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true);
    const [iFrameUrl, setIFrameUrl] = useState<string>("");

    useEffect(() => {
        setLoading(true)
        getInstallation({id: installation_id}).then((res: any) => {
            if(res && res.installation) {
                setInstallation(res.installation)
                setMaintenanceDone(!res.installation.maintenance_notified);
            }
            setLoading(false)
        })
    }, []);

    useEffect(() => {
        switch(installation?.model) {
            case ETopPlusModel:
                setIFrameUrl(ETopPlusUrl(installation_id));
                break;
            case EBlockModel:
                setIFrameUrl(EBlockUrl(installation_id));
                break;
            default:
                break;
        }
    } ,[installation])

    const [note] = Form.useForm()
    const submit = () => {
        note.validateFields().then((values: any) => 
            maintenanceInstallation(installation_id, values.note).then((res) => {
                if(res && !res.err){
                    message.success("Maintenance successfully performed.");
                    setMaintenanceDone(!maintenanceDone);
                    setVisible(false)
                }else{
                    message.error(res.err?.message || "Error loading notes.")
                }
            })
        )
    }

    if(loading){
        return null
    }

    return (
        <>
            <TitleWithBack title={dataRealTimeTitle} key={"real_time_data"} />
            <div>
            <Row className='summary-cards'>
                <Col xs={24} xl={6} className="card-responsive">
                    <InstallationSummary
                        selected_installation={installation}
                    />
                </Col>
                <Col xs={24} xl={6} className="card-responsive">
                    <RealTimeSummary
                        selected_installation={installation}
                    />
                </Col>
                <Col xs={24} xl={6} className="card-responsive">
                    <AlarmSummary
                        installation={installation}
                    />
                </Col>
            </Row>
            </div>
            {
                !maintenanceDone ? 
                <div className="maintenance-container">
                    <ButtonConfItem buttonType={'primary'} buttonLabel={"Maintenance done"} buttonOnCancel={() => {}} buttonOnConfirm={() => setVisible(true)} />
                </div> : 
                null
            }
            <Modal title="Maintenance installation" visible={visible} onOk={submit} onCancel={() => setVisible(false)}>
                <Form form={note}>
                    <Form.Item name={"note"} label="Note">
                        <Input.TextArea name="note" />
                    </Form.Item>
                </Form>
            </Modal>
            <iframe
                id={"grafana_iframe"}
                className='responsive-iframe'
                src={iFrameUrl}
                style={{height: "2700px"}}
            />
        </>
    )
}

export default InstallationPage;