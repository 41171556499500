import React, {useEffect} from "react";
import MUIDataTable, { Responsive} from "mui-datatables";
import "./Table.css"
import {Tag} from "antd";
import { useState } from "react";
import {
    dateTimeType,
    dateType,
    iconType,
    linkType,
    localeDateFormatter, localeDateTimeFormatter,
    numberType,
    statusDate,
    stringType,
    tagType
} from "../utilities/utilities";
import CustomTag from "./CustomTag";
import { rowsPerTablePage } from "../api/constants";
import { StyledEngineProvider } from '@mui/material/styles';
import { greenColor, orangeColor, redColor, tableKey } from "../costants/costants";
interface ResponsiveTableProps {
    title?: string
    columns: ResponsiveTableColumnType[]
    data?: Object[],
    expandableRows?: boolean,
    renderExpandableRow?: (rowData: any, rowMeta: any) => JSX.Element,
    icon?: boolean,
    getPageAndSortedData?: any,
    defaultSort?: string,
    responseDataName?: string,
    id?: string
    filter?: boolean
    setFatherData?: (x: any)=> void
    setData ?: React.Dispatch<React.SetStateAction<Object[]>>
}

interface ResponsiveTableCellProps {
    type: string
    value: string
    color?: string
    link?: (...args: any[]) => void
    icon?: React.ReactNode
    background?: string
}

export type ResponsiveTableColumnType = {
    name: string,
    label: string | any,
    options: {
        filter: boolean,
        sort?: boolean,
        customBodyRender: (a: any, b: any, c: any) => React.ReactNode 
    }
}

export const ResponsiveTableCell: React.FC<ResponsiveTableCellProps> = ({type, value, color, link, icon, background}) => {
    switch(type) {
        case stringType:
            return <div className="cell" style={{backgroundColor: background || ""}}> {value} </div>
        case tagType:
            return <div className="cell" style={{backgroundColor: background || ""}}> <CustomTag text={value} color={color ?? "primary"} /> </div>
        case dateType:
            return <div className="cell" style={{backgroundColor: background || ""}}> {localeDateFormatter(value)} </div>
        case dateTimeType:
            return <div className="cell" style={{backgroundColor: background || ""}}> {localeDateTimeFormatter(value)} </div>
        case linkType:
            return <a className="cell" style={{backgroundColor: background || ""}} onClick={link}> {value} </a>
        case iconType:
            return <a className="cell" style={{backgroundColor: background || "", justifyContent: "center"}} onClick={link}> {icon} </a>
        case numberType:
            return <div className="cell" style={{backgroundColor: background || ""}}> {parseFloat(value).toFixed(2)} </div>
        case statusDate:
            if(new Date(value).getFullYear() === 1) {
                return <div className="cell" style={{backgroundColor: background || ""}}> <CustomTag color={orangeColor} text="never" /> </div>
            }
            const minutesDiff = Math.abs((new Date().getTime() - new Date(value).getTime()) / (1000 * 60));
            if(minutesDiff < 10) {
                return <div className="cell" style={{backgroundColor: background || ""}}> <Tag color={greenColor}>{localeDateFormatter(value)}</Tag> </div>
            } else {
                return <div className="cell" style={{backgroundColor: background || ""}}> <Tag color={redColor}>{localeDateFormatter(value)}</Tag> </div>
            }
        default:
            return <div className="cell" style={{backgroundColor: background || ""}}> {value} </div>
    }
}

const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
    title,
    columns,
    data,
    expandableRows,
    renderExpandableRow,
    icon,
    getPageAndSortedData,
    defaultSort,
    filter,
    responseDataName,
    id,
    setFatherData,
    setData
}) => {
    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>(defaultSort ?? "");
    const [currentSearch, setCurrentSearch] = useState<string>("");

    const handleSetData = (res: any) => {
        if(setData){
            setData(res)
        }
    }

    useEffect( () => {
        if(getPageAndSortedData && responseDataName) {
            if (id) {
                getPageAndSortedData(id, "0", defaultSort!, currentSearch).then(
                    (res: Object) => {
                        if( res && (res as any)[responseDataName]) {
                            setTableData((res as any)[responseDataName]);
                            handleSetData((res as any)[responseDataName]);
                            setTotalDataLength((res as any)['total_count'] ?? 0 );
                        }
                    }
                )
            } else {
                getPageAndSortedData("0", defaultSort!, currentSearch).then(
                    (res: Object) => {
                        if( res && (res as any)[responseDataName]) {
                            setTableData((res as any)[responseDataName]);
                            handleSetData((res as any)[responseDataName]);
                            setTotalDataLength((res as any)['total_count'] ?? 0 );
                        }
                    }
                )
            }
        } else if (data) {
            setTableData(data)
            handleSetData(data)
        }
        if(setFatherData) {
            setFatherData(tableData);
        }
    }, [])


    const changePage = (page: number) => {
        setCurrentPage(page);
        if(id){
            getPageAndSortedData(id, String(page), currentSort, currentSearch).then((res: any) => {
                if(res && responseDataName && (res as any)[responseDataName]){
                    setTableData((res as any)[responseDataName])
                    handleSetData((res as any)[responseDataName])
                }
            })
        }else{
            getPageAndSortedData(String(page), currentSort, currentSearch).then((res: any) => {
                if(responseDataName && res && (res as any)[responseDataName]){
                    setTableData((res as any)[responseDataName])
                    handleSetData((res as any)[responseDataName])
                }
            })
        }
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        if(id){
            getPageAndSortedData(id, String(currentPage), sort, currentSearch).then((res: any) => {
                if(res && responseDataName && (res as any)[responseDataName]){
                    setTableData((res as any)[responseDataName])
                    handleSetData((res as any)[responseDataName])
                }
            })
        }else{
            getPageAndSortedData(String(currentPage), sort, currentSearch).then((res: any) => {
                if(responseDataName && res && (res as any)[responseDataName]){
                    setTableData((res as any)[responseDataName])
                    handleSetData((res as any)[responseDataName])
                }
            })
        }
    }

    const changeSearch = (text: string) => {
        setCurrentSearch(text);
        setCurrentPage(0);
        if(id){
            getPageAndSortedData(id, "0", currentSort, text).then((res: any) => {
                if(res && responseDataName){
                    if((res as any)[responseDataName]){
                        setTableData((res as any)[responseDataName])
                        handleSetData((res as any)[responseDataName])
                    }else{
                        setTableData([])
                        handleSetData([])
                    }
                }
            })
        }else{
            getPageAndSortedData("0", currentSort, text).then((res: any) => {
                if(res && responseDataName){
                    if((res as any)[responseDataName]){
                        setTableData((res as any)[responseDataName])
                        handleSetData((res as any)[responseDataName]);
                    }else{
                        setTableData([])
                        handleSetData([]);
                    }
                }
            })
        }
    }

    let options: Object = {}

    if (getPageAndSortedData) {
        options = {
            enableNestedDataAccess:".",
            selectableRows: 'none' as 'none',
            filter: filter ?? false,
            print: false,
            download: false,
            serverSide: true,
            responsive: 'standard' as Responsive,
            isRowSelectable: () => false,
            expandableRows: expandableRows ?? false,
            renderExpandableRow: !!expandableRows ? renderExpandableRow : undefined,
            rowsPerPage: rowsPerTablePage,
            count: totalDataLength,
            rowsPerPageOptions: [],
            onTableChange: (action: string, tableState: any) => {
                switch (action) {
                    case 'changePage':
                        changePage(tableState.page);
                        break;
                    case 'sort':
                        let sort;
                        if(tableState.sortOrder.direction === 'asc') {
                            sort = tableState.sortOrder.name
                            setCurrentSort(sort);
                        } else {
                            sort = `-${tableState.sortOrder.name}`
                            setCurrentSort(sort)
                        }
                        changeSort(sort);
                        break;
                    case 'search':
                        changeSearch(tableState.searchText);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    if(!getPageAndSortedData){
        options = {
            enableNestedDataAccess:".",
            selectableRows: 'none' as 'none',
            filter: filter ?? false,
            download: false,
            responsive: 'standard' as Responsive,
            isRowSelectable: () => false,
            expandableRows: expandableRows ?? false,
            renderExpandableRow: expandableRows===true ? renderExpandableRow : undefined,
        }
    }

    const hideIcons = {
        enableNestedDataAccess:".",
        selectableRows: 'none' as 'none',
        isRowSelectable: () => false,
        expandableRows: expandableRows ?? false,
        renderExpandableRow: expandableRows===true ? renderExpandableRow : undefined,
        download: false,
        filter: false,
        print: true,
        search: false,
        viewColumns: false,
        responsive: 'standard' as Responsive
    }

    if(icon===false){
        return (
            <div key={tableKey} className="verticalBorder">
                <MUIDataTable
                    title={title}
                    data={data ? data : tableData}
                    columns={columns}
                    options={hideIcons}
                />
            </div>
        )
    }else{
        return (
            <StyledEngineProvider injectFirst>
                <div key={tableKey} className="verticalBorder">
                    <MUIDataTable
                        title={title}
                        data={data ? data : tableData}
                        columns={columns}
                        options={options}
                    />
                </div>
            </StyledEngineProvider>
        );
    }
};

export default ResponsiveTable;