import "./Header.css"
import {logout} from '../../api/services/loginService';
import {useHistory} from 'react-router';
import React, { useEffect, useState } from "react";
import {routes} from "../pages/costants";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import {AppBar, Avatar, Hidden, IconButton, Menu, MenuItem, Toolbar} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useStyles} from "../pages/muiStyle";
import clsx from 'clsx';
import { avatarSrc, css, fixedPosition, iconColor, iconEdge, iconLabel, logo as logoKey, logoutLabel, menuId, profiloLabel, successStatusKey } from "../../costants/costants";
import logo from '../../logo/logo-armal.png';

type HeaderItemProps = {
    SetNavbarIsCollapsed: (collapsed: boolean) => void,
    navbar_collapsed: boolean
}

const HeaderItem: React.FC<HeaderItemProps> = ({SetNavbarIsCollapsed, navbar_collapsed}) => {
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [heigth, setHeigth] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeigth(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const history = useHistory()
    const logOut = () => {
		logout().then(res => {
			if(res!== undefined && res.status === successStatusKey){
				history.push(res.location)
			}
		})
	}

    const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar
            position={fixedPosition}
            className={clsx(classes.appBar)}
        >
        <Toolbar className={"header"}>
            {
                width > 768 && heigth > 414 ?
                <img
					key={logoKey}
					className={'logo'}
					alt={logoKey}
					src={logo}
					onClick={
						() => {
							history.push(routes.mainPage)
							SetNavbarIsCollapsed(true);
					}}
				/> :
                null
            }
            <Hidden
                smUp
                implementation={css}
            >
                <IconButton
                    color={iconColor}
                    aria-label={iconLabel}
                    edge={iconEdge}
                    onClick={() => {SetNavbarIsCollapsed(!navbar_collapsed)}}
                    size="large">
                    <MenuIcon />
                </IconButton>
            </Hidden>

            <section className={classes.rightToolbar}>
                <Avatar onClick={openMenu}  src={avatarSrc} style={{cursor: "pointer"}} />
            </section>
            

            <Menu
                id={menuId}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => {history.push(routes.profile)}}> <PersonOutlineOutlinedIcon /> {profiloLabel} </MenuItem>
                <MenuItem onClick={logOut}> <PowerSettingsNewOutlinedIcon /> {logoutLabel} </MenuItem>
            </Menu> 
            </Toolbar>
        </AppBar>
    );
}

export default HeaderItem;