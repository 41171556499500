import { Badge, Card, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getAlarmsCalibration } from "../../../api/services/installationsService";
import { primaryType, editLabel, failedStatus, blueColor, doneStatus, greenColor, pendingStatus, redColor, yellowColor, correctExecLabel, executionLabel, failedLabel, neverDone, alarmConfigurationTitleLabel, batteryVoltage, disabledLabel, enabledLabel, genericSignals, gpsPosition, positioningTilt, roomTemperature, solarPanelVoltage, minLabel, clearWaterLevel, darkWaterLevel, maxLabel, ledFlushStatus } from "../../../costants/costants";
import ButtonItem from "../../ButtonItem";
import TitleWithBack from "../../TitleWithBack";
import { updateAlarmConfigurationUrl } from "../costants";
import { alarmConfigurationTitle } from "../title";

type AlarmConfigurationProps = {
    installation_id: string
}

const AlarmConfiguration: React.FC<AlarmConfigurationProps> = ({ installation_id }) => {

    const history = useHistory()

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const [alarms, setAlarms] = useState<any[]>([])
    useEffect(() => {
        getAlarmsCalibration(installation_id).then((res: any) => {
            if(res && res.alarms){
                setAlarms(res.alarms)
            }
        })
    }, [])

    const checkBadgeColor = (last_set_status: string) => {
        switch (last_set_status) {
            case doneStatus:
                return greenColor
            case pendingStatus:
                return yellowColor
            case failedStatus:
                return redColor
            default:
                return blueColor
        }
    }
    
    const checkLastStatus = (last_set_status: string) => {
        switch (last_set_status) {
            case doneStatus:
                return correctExecLabel
            case pendingStatus:
                return executionLabel
            case failedStatus:
                return failedLabel
            default:
                return neverDone
        }
    }

    const checkName = (name: string) => {
        switch (name) {
            case "battery_voltage_alarm":
                return batteryVoltage.label
            case "solar_panel_alarm":
                return solarPanelVoltage.label
            case "clear_water_level_alarm":
                return clearWaterLevel.label
            case "dark_water_level_alarm":
                return darkWaterLevel.label
            case "internal_lights_status_alarm":
                return ledFlushStatus.label
            default:
                return name
        }
    }

    const checkStatus = (status: boolean) => {
        if(status){
            return enabledLabel
        }
        if(!status){
            return disabledLabel
        }
        return "---"
    }

    return(
        <div style={{marginBottom: "20%"}}>
            <TitleWithBack title={alarmConfigurationTitle} key={alarmConfigurationTitle} />
            {/*
            <Descriptions style={{marginLeft: (width <= 575 || height <= 815) ? "1%" : "20%", marginRight: (width <= 575 || height <= 815) ? "1%" : "20%"}} column={1} bordered>
                <Descriptions.Item label={alarmConfigurationTitleLabel}> 
                    <Badge color={checkBadgeColor("")} text={checkLastStatus("")} /> 
                </Descriptions.Item>
            </Descriptions>
            */}
            <div className={(width <= 575 || height <= 815) ? undefined: "summary-container"} style={{marginTop: "2%"}}>
                <Badge.Ribbon text={alarmConfigurationTitleLabel}>
                    <Card className="badge-container" style={{width: (width <= 575 || height <= 815) ? `100%` : "650px"}} size="default">
                        {
                            alarms.map((a: any, index: number) => {
                                return <>
                                    <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                        <div style={{width: (width <= 575 || height <= 815) ? `100%` : "350px"}}>
                                            <div className="p-style"> 
                                                <div>{checkName(a.name)}:</div> 
                                                <div>{checkStatus(a.config.enabled) ||  "---"}</div> 
                                            </div>
                                            <div className="p-style"> 
                                                <div>{a.config.upper_threshold !== "" ? maxLabel : minLabel}:</div> 
                                                <div>{`${a.config.upper_threshold !== "" ? a.config.upper_threshold : a.config.lower_threshold}` ||  "---"}</div> 
                                            </div>
                                        </div>
                                        <ButtonItem size="small" buttonType={primaryType} label={editLabel} buttonOnClick={() => {history.push(updateAlarmConfigurationUrl(installation_id, a.id))}} />
                                    </div>
                                    {
                                        index !== (alarms.length - 1) ? <Divider /> : null
                                    } 
                                </>
                            })
                        }
                        
                        
                        
                    </Card>
                </Badge.Ribbon>
            </div>
        </div>
    )
}

export default AlarmConfiguration;