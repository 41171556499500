import React, { useState } from "react";
import '../../navbar/Navbar.css'
import { customersListColumns } from "./column";
import '../../../App.css'
import './UsersListTable.css'
import { GetCustomers } from "../../../api/services/userService";
import CustomTitle from "../../CustomTitle";
import {useHistory} from "react-router-dom";
import ResponsiveTable from "../../responsiveTable";
import { updateUserPageUrl } from "../costants";
import { updateUserTitle } from "../title";
import { customersKey, name } from "../../../costants/costants";

type UsersListTableProps = {
    SetPopupObject: (obj: any) => void
}

const UsersListTable: React.FC<UsersListTableProps> = ({SetPopupObject}) => {   

    document.body.style.background = "#f5f5f5c7"
    const [data, setData] = useState<Object[]>([])
    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateUserPageUrl(value))
    }

    return(
        <>
            <CustomTitle title={updateUserTitle}/>
            <ResponsiveTable 
                columns={customersListColumns(data, SetPopupObject, linkFunction)} 
                getPageAndSortedData={GetCustomers}
                defaultSort={name.name}
                responseDataName={customersKey} 
                setData={setData}
            />
        </>
    )
}

export default UsersListTable;