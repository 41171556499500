import { description, model, modelOptions, name, selectType } from "../../../costants/costants";
import { InputType } from "../../types/form/types";

export const installationFields: InputType[] = [
    {
        key: model.key,
        name: model.name,
        label: model.label,
        type: selectType,
        options: modelOptions,
        rules: [ {required: true, message: model.rules} ]
    },
    {
        key: name.key,
        name: name.name,
        label: name.label,
        rules: [ {required: true, message: name.rules} ]
    },
    {
        key: description.key,
        name: description.name,
        label: description.label,
        rules: [ {required: true, message: description.rules} ]
    }
]
