import "./RecoverPassword.css"
import "antd/dist/antd.css"
import ButtonItem from "../../ButtonItem"
import { inputs } from "./inputs"
import { Form, Input, message } from "antd"
import React, { useEffect, useState } from "react"
import queryString from "querystring";
import {RouteComponentProps} from "react-router";
import {recoverPassword} from "../../../api/services/loginService";
import {useHistory} from "react-router-dom";
import { recoverPageUrlResult } from "../costants"
import logo from '../../../logo/logo-armal.png';
import { backgroundColorLogin, confermaLabel, loginFormName, newPasswordLabel, primaryType, recoverPasswordError, successStatusKey, textType } from "../../../costants/costants"
import { isTest } from "../../../api/services/httpRequests"


const RecoverPassword: React.FC<RouteComponentProps> = (props) => {
    document.body.style.background = backgroundColorLogin;
    const [token, setToken] = useState<string|string[]>("")
    const [width, setWidth] = useState<number>(window.innerWidth);
    const history = useHistory();


	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    useEffect(() => {
        const params = queryString.parse(props.location.search)
        if (params['?token']) {
            setToken(params['?token'])
        } else {
            setToken('')
        }
    }, [props.location.search])

    const [form] = Form.useForm();

    const submit = () => {
        form.validateFields().then(
            values => {
                recoverPassword({password: values.password, confirm_password: values.confirm_password, token: token}).then(
                    (response) => {
                        if (response.status === successStatusKey) {
                            history.push(recoverPageUrlResult)
                        }else{
                            message.error(recoverPasswordError)
                        }
                    }
                ).catch(
                    e => {
                        console.log("error:", e)
                    }
                )
            }
        ).catch((e) => {
            if(isTest){
                if(e.values.password !== e.values.confirm_password){
                    message.error(recoverPasswordError)
                }
            }
        })
    }

    return (
        <>
        <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
            <div className="image-container">
                <img src={logo} className="image" />
            </div>
            <Form name={loginFormName} form={form}>
                <h2> {newPasswordLabel} </h2>
                {
                    inputs.map((el)=>{ 
                        return(
                            <Form.Item key={el.key} name={el.name} rules={el.rules}>
                                <Input
                                    data-testid={el.key}
                                    size="large"
                                    type={el.type ?? textType}
                                    prefix={el.icon}
                                    placeholder={el.label}
                                />  
                            </Form.Item>
                        )
                    })
                }
                <div className="recover-btn-container">
                    <Form.Item>
                        <ButtonItem
                            buttonType={primaryType}
                            label={confermaLabel}
                            buttonOnClick={submit}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
        </>
    )
}

export default RecoverPassword;