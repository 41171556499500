import { ResponsiveTableCell, ResponsiveTableColumnType } from "../../responsiveTable";
import { dateType, stringType } from "../../../utilities/utilities";
import "../../../App.css";
import { Button, Popconfirm, Tooltip } from "antd";
import { actionLabel, activationLabel, activationLoading, active, address, askActivation, askDeactivation, companyInfoLabel, deactivationLabel, deactivationLoading, deactive, disabledLabel, enabledLabel, expiryDate, greenColor, no, orangeColor, primaryType, redColor, status, sureQuestion, vat, yes, zip, details, companyLabel, name } from "../../../costants/costants";
import {InfoCircleOutlined} from "@ant-design/icons";

export const tableColumn: (rowData:Object[], SetPopupObject: (obj: any) => void, manageSubscription: (subscription_id: string, requested_status: string) => void) => ResponsiveTableColumnType[] = (rowData, SetPopupObject, manageSubscription) => [
    {
        label: name.label,
        name: name.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <div className="details-container">
                <Tooltip title={details}>
                    <InfoCircleOutlined 
                        className="details-icon"
                        onClick={() => {
                            const tmp: any = rowData[data.rowIndex]
                            const objects: any[] = [
                                {
                                    name: tmp.name,
                                    status: tmp.status,
                                    created_at: tmp.created_at,
                                    expiry_date: tmp.expiry_date
                                },
                                {
                                    detail_title: "Company info",
                                    company: tmp.company_info.name,
                                    address: tmp.company_info.address,
                                    city: tmp.company_info.city,
                                    province: tmp.company_info.province,
                                    country: tmp.company_info.country,
                                    zip_code: tmp.company_info.zip_code,
                                    vat_number: tmp.company_info.vat_number,
                                    phone: tmp.company_info.phone,
                                    sdi: tmp.company_info.sdi,
                                    pec: tmp.company_info.pec
                                },
                                {
                                    detail_title: "Customer info",
                                    name: tmp.customer_name,
                                    address: tmp.customer_info.address,
                                    city: tmp.customer_info.city,
                                    province: tmp.customer_info.province,
                                    country: tmp.customer_info.country,
                                    zip_code: tmp.customer_info.zip_code,
                                    phone: tmp.customer_info.phone
                                }
                            ]
                            SetPopupObject(objects)
                        }} 
                    />
                </Tooltip>
                <div className="details"> 
                    <ResponsiveTableCell
                        type={stringType}
                        value={value}
                    />
                </div>
            </div>
        }
    },
    {
        label: companyLabel,
        name: companyInfoLabel,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.name}
                />
        }
    },
    {
        label: vat.label,
        name: `${companyInfoLabel}.${vat.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: address.label,
        name: `${companyInfoLabel}.${address.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <ResponsiveTableCell
                type={stringType}
                value={`${value}, ${data.tableData[data.rowIndex].company_info.city}, ${data.tableData[data.rowIndex].company_info.province}`}
            />
        }
    },
    {
        label: zip.label,
        name: `${companyInfoLabel}.${zip.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: status.label,
        name: status.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={stringType}
                value={value === enabledLabel ? active : deactive}
            />
        }
    },
    {
        label: expiryDate.label,
        name: expiryDate.key,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <ResponsiveTableCell
                type={dateType}
                value={data.tableData[data.rowIndex].status === enabledLabel ? value : ""}
            />
        }
    },
    {
        label: actionLabel,
        name: status.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => {
                const status: string = value
                const requestedStatus: string = data.tableData[data.rowIndex].requested_status
                return <div style={{display: "flex", justifyContent: "center"}}>
                    {
                        requestedStatus === activationLabel ?
                        <Button type={primaryType} style={{backgroundColor: "orange", border: "None"}}>
                            {activationLoading}
                        </Button> :
                        requestedStatus === deactivationLabel ?
                        <Button type={primaryType} style={{backgroundColor: orangeColor, border: "None"}}>
                            {deactivationLoading}
                        </Button> :
                        status === disabledLabel ?
                        <Popconfirm
                            title={sureQuestion}
                            onConfirm={() => {manageSubscription(data.tableData[data.rowIndex].id, activationLabel)}}
                            onCancel={() => {}}
                            okText={yes}
                            cancelText={no}
                        >
                            <Button type={primaryType} style={{backgroundColor: greenColor, border: "None"}}>
                                {askActivation}
                            </Button> 
                        </Popconfirm> :
                        <Popconfirm
                            title={sureQuestion}
                            onConfirm={() => {manageSubscription(data.tableData[data.rowIndex].id, deactivationLabel)}}
                            onCancel={() => {}}
                            okText={yes}
                            cancelText={no}
                        >
                            <Button type={primaryType} style={{backgroundColor: redColor, border: "None"}}>
                                {askDeactivation}
                            </Button>
                        </Popconfirm>
                    }
                </div>
            }

        }
    }
]