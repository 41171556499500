import { changeStatus, getAllSubscriptions } from '../../../api/services/subscriptionService';
import ResponsiveTable from '../../responsiveTable';
import { tableColumn } from './tableColumn';
import './SubscriptionManagement.css';
import { ChangeStatusRequest } from '../../../api/requests/subscription';
import { expiryDate, subscriptionsLabel } from '../../../costants/costants';
import { useState } from 'react';

type SubscriptionManagementProps = {
    SetPopupObject: (obj: any) => void
}

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({ SetPopupObject }) => {

    document.body.style.background = "#f5f5f5c7"
    const [data, setData] = useState<Object[]>([])
    const manageSubscription = (subscription_id: string, requested_status: string) => {
        const request: ChangeStatusRequest = {
            subscription_id: subscription_id,
            requested_status: requested_status
        }
        changeStatus(request).then((res) => {
            if(res && res.subscription){
                window.location.reload()
            }
        })
    }

    return(
        <>
            <ResponsiveTable 
                columns={tableColumn(data, SetPopupObject, manageSubscription)} 
                getPageAndSortedData={getAllSubscriptions}
                defaultSort={expiryDate.key}
                responseDataName={subscriptionsLabel}
                setData={setData}
            />
        </>
    )
}

export default SubscriptionManagement;