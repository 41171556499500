import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import "../../../App.css"
import {dateType, linkType, stringType} from "../../../utilities/utilities";
import { address, city, createdAt, details, email, name, province, userInfo, zip } from "../../../costants/costants";
import { Tooltip } from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

export const installersListColumns: (rowData: any, SetPopupObject: (obj: any) => void, linkFunction: (value: string) => void) => ResponsiveTableColumnType[] = (rowData, SetPopupObject, linkFunction) => [
    {
        label: name.label,
        name: name.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => {
                return (<div className="details-container">
                    <Tooltip title={details}>
                        <InfoCircleOutlined 
                            className="details-icon"
                            onClick={() => {SetPopupObject([rowData[data.rowIndex]])}} 
                        />
                    </Tooltip>
                    <div className="details"> 
                        <ResponsiveTableCell
                            type={linkType}
                            value={value}
                            link={() => {
                                linkFunction(data.tableData[data.rowIndex].uid)
                            }}
                        />
                    </div>
                </div>)
            }
        }
    },
    {
        label: email.label,
        name: email.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: address.label,
        name: `${userInfo}.${address.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: city.label,
        name: `${userInfo}.${city.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />  
        }
    },
    {
        label: province.label,
        name: `${userInfo}.${province.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: zip.label,
        name: `${userInfo}.${zip.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: createdAt.label,
        name: createdAt.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={dateType}
                value={value}
            />
        }
    }
]
