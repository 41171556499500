import React from "react"
import '../../navbar/Navbar.css'
import './alarmTable.css'
import '../../../App.css'
import CustomTitle from "../../CustomTitle";
import ResponsiveTable from "../../responsiveTable";
import {alarmsTitle} from "../title";
import { GetAllAlarms } from "../../../api/services/installationsService";
import { alarmColumns } from "./columns";
import { alarmsKey, alarmSort } from "../../../costants/costants";
import { useHistory } from "react-router";
import { installationPageUrl } from "../costants";

const AlarmTable: React.FC = () => {

    document.body.style.background = "#f5f5f5c7"

    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(installationPageUrl(value))
    }

    return(
        <>
            <CustomTitle title={alarmsTitle}/>
            <ResponsiveTable 
                columns={alarmColumns(linkFunction)} 
                getPageAndSortedData={GetAllAlarms}
                defaultSort={alarmSort}
                responseDataName={alarmsKey}
            />
        </>
    )
}

export default AlarmTable;