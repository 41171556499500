import 'antd/dist/antd.css';
import './FormInput.css'
import { Checkbox, Form } from 'antd';
import React from "react";
import { checkboxType, divKey, formKey, htmlForName, labelKey } from '../../costants/costants';

type FormInputProps = {
    placeholder: string,
    type: string,
    keyValue: string,
    name?: string,
    value?: string | number| boolean,
    rules?: any,
    disabled?: boolean,
    onchange?: any
}

const FormInput: React.FC<FormInputProps> = ({placeholder, type, keyValue, name, value, rules, disabled, onchange}) => {
    if (type === checkboxType) {
        return(
            <div className="form__group field" key={divKey(keyValue)}>
                <label key={labelKey(keyValue)} htmlFor={htmlForName} className="form__label">{placeholder}</label>
                <Form.Item key={formKey(keyValue)} name={name} rules={rules ?? undefined}>
                    <Checkbox key={keyValue} checked={value === true} onChange={onchange} type={type}> </Checkbox>
                </Form.Item>
            </div>
        )
    } else {
        return(
            <div className="form__group field" key={divKey(keyValue)}>
                <label key={labelKey(keyValue)} htmlFor={htmlForName} className="form__label">{placeholder}</label>
                <Form.Item key={formKey(keyValue)} name={name} initialValue={value} rules={rules ?? undefined}>
                    <input data-testid={keyValue} key={keyValue} className="form__field" placeholder={placeholder} type={type} disabled={disabled} />
                </Form.Item>
            </div>
        )
    }
}

export default FormInput;