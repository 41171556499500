import React from "react";
import 'antd/dist/antd.css';
import { Popconfirm, Button } from 'antd';
import { debounceFunc } from '../utilities/utilities';
import { no, sureQuestion, yes } from '../costants/costants';

type ButtonConfProps = {
    buttonType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed",
    buttonLabel: string,
    buttonOnConfirm: any,
    buttonOnCancel: any,
    questionLabel?: string,
    yesLabel?: string,
    noLabel?:string,
    disabled?: boolean
}

const ButtonConfItem: React.FC<ButtonConfProps> = ({buttonType, buttonLabel, buttonOnConfirm, buttonOnCancel, questionLabel, yesLabel, noLabel, disabled}) => {
    return(
        <Popconfirm
            title={questionLabel ?? sureQuestion}
            onConfirm={debounceFunc(buttonOnConfirm)}
            onCancel={debounceFunc(buttonOnCancel)}
            okText={yesLabel ?? yes}
            cancelText={noLabel ?? no}
        >
            <Button disabled={disabled ? disabled : undefined} size="large" type={buttonType ?? "default"}>
                {buttonLabel}
            </Button>
        </Popconfirm>
    )
}

export default ButtonConfItem

