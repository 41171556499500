import {GetProfileUrl, rowsPerTablePage, UsersServiceUrl} from "../constants";
import {AddUserRequest, UsersRelationRequest, GetUserRequest, UpdateUserRequest} from "../requests/userService";
import {isTest, methodDelete, methodGet, methodPost, methodPut, verticalFetch} from "./httpRequests";
import { getCustomersMock, getInstallersMock, getRelInstallersCustomerMock, getUserMock, getUserProfileMock } from "./mocks";

//Create a new user
export const AddUser = (r: AddUserRequest) => {
    return verticalFetch(methodPost, UsersServiceUrl, JSON.stringify(r));
}

//Get a user
export const GetUser = (r: GetUserRequest) => {
    if(isTest){
        return new Promise((resolve) => resolve(getUserMock))
    }
    return verticalFetch(methodGet, `${UsersServiceUrl}/${r.user_id}`);
}

//Update a user entity
export const UpdateUser = (r: UpdateUserRequest) => {
    return verticalFetch(methodPut, `${UsersServiceUrl}/${r.user_id}`, JSON.stringify(r));
}

//Create relation between user and customer
export const AddUsersRelation = (r: UsersRelationRequest) => {
    return verticalFetch(methodPost, `${UsersServiceUrl}/${r.installer_id}/customers/${r.customer_id}`);
}

//Delete relation between user and customer
export const DeleteUsersRelation = (r: UsersRelationRequest) => {
    return verticalFetch(methodDelete, `${UsersServiceUrl}/${r.installer_id}/customers/${r.customer_id}`);
}

//Get the list of all installers
export const GetInstallers = (page: string, sort: string, search: string) => {
    if(isTest){
        return new Promise((resolve) => resolve(getInstallersMock))
    }
    return verticalFetch(methodGet, `${UsersServiceUrl}/installers?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`);
}

//Get the list of customers associated with an installer
export const GetCustomers = (page: string, sort: string, search: string) => {
    if(isTest){
        return new Promise((resolve) => resolve(getCustomersMock))
    }
    return verticalFetch(methodGet, `${UsersServiceUrl}/customers?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}` );
}

//Get the list of all relationsa between installers and customers
export const GetRelInstallersCustomer = () => {
    if(isTest){
        return new Promise((resolve) => resolve(getRelInstallersCustomerMock))
    }
    return verticalFetch(methodGet, `${UsersServiceUrl}/installers/relations`);
}

//Get user information
export const GetUserProfile = () => {
    if(isTest){
        return new Promise((resolve) => resolve(getUserProfileMock))
    }
    return verticalFetch(methodGet, GetProfileUrl );
}