import React, { useEffect, useState } from "react";
import { Form, message, Switch } from "antd";
import TitleWithBack from "../../TitleWithBack";
import { updateAlarmConfigurationTitle } from "../title";
import { alarmPanelLabel, divKey, formInputKey, htmlForName, invioLabel, labelKey, primaryType, questionLabel, resetLabel, signalConfigurationBtnDivKey } from "../../../costants/costants";
import { getAlarmsCalibration, updateAlarmsCalibration } from "../../../api/services/installationsService";
import FormInput from "../../input/FormInput";
import ButtonConfItem from "../../ButtonConfItem";
import ButtonItem from "../../ButtonItem";
import { AlarmRequest } from "../../../api/requests/installationsService";
import { useHistory } from "react-router";

type UpdateAlarmConfigurationProps = {
    installation_id: string
    alarm_id: string
}

const UpdateAlarmConfiguration: React.FC<UpdateAlarmConfigurationProps> = ({ installation_id, alarm_id }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [alarm, setAlarm] = useState<any>({})
    useEffect(() => {
        getAlarmsCalibration(installation_id).then((res: any) => {
            if(res && res.alarms){
                const tmp_alarm: any[] = (res.alarms as any[]).filter(a => a.id === alarm_id)
                if(tmp_alarm.length === 1){
                    setAlarm(tmp_alarm[0])
                }
            }
            setLoading(false)
        })
    }, [])

    const [form] = Form.useForm();

    const history = useHistory();

    const handleSubmit = () => {
        return form.validateFields().then(
            values => {
                const request: AlarmRequest = {
                    config_id: alarm_id,
                    config: {
                        enabled: Boolean(values.enabled),
                        duration: Number(values.duration)
                    }
                }
                if(alarm.config.upper_threshold !== ""){
                    request.config.upper_threshold = String(values.threshold)
                }
                if(alarm.config.lower_threshold !== ""){
                    request.config.lower_threshold = String(values.threshold)
                }
                updateAlarmsCalibration(installation_id, request).then((res: any) => {
                    if(res && !res.err){
                        message.success("Alarm updated successfully.")
                        history.goBack()
                    }else{
                        message.error(res.err?.message || "Something went wrong.")
                    }
                })
            }
        )
    }

    if(loading) {
        return null
    }

    return(
        <>
            <TitleWithBack title={updateAlarmConfigurationTitle} key={updateAlarmConfigurationTitle}/>
            <div className="my-signalconfiguration-container my-signalconfiguration-container-responsive">
                <Form layout="vertical" key={1} form={form} name={alarmPanelLabel}>
                    <div className="form__group field" key={divKey("enabled")}>
                        <label key={labelKey("enabled")} htmlFor={htmlForName} className="form__label"> Enabled </label>
                        <Form.Item key="enabled" rules={[ {required: true, message: "Required field!"} ]} name="enabled" initialValue={alarm.config.enabled}>
                            <Switch
                                defaultChecked={alarm.config.enabled}
                                checkedChildren={<h3 style={{color: "white"}}> ENABLED </h3>}
                                unCheckedChildren={<h3 style={{color: "white"}}> DISABLED </h3>}
                            />
                        </Form.Item>
                        <FormInput 
                            key={formInputKey("duration")}
                            name="duration"
                            placeholder="Min. Duration"
                            type="number"
                            value={alarm.config.duration}
                            keyValue="duration"
                            rules={[ {required: true, message: "Required field!"} ]}
                        />
                        <FormInput 
                            key={formInputKey("threshold")}
                            name="threshold"
                            placeholder={alarm.config.upper_threshold !== "" ? "Upper threshold" : "Lower threshold"}
                            type="number"
                            value={alarm.config.upper_threshold !== "" ? Number(alarm.config.upper_threshold) : Number(alarm.config.lower_threshold)}
                            keyValue="threshold"
                            rules={[ {required: true, message: "Required field!"} ]}
                        />
                    </div>
                    <div className="btn-container" key={signalConfigurationBtnDivKey}>
                        <ButtonConfItem buttonLabel={resetLabel} buttonOnConfirm={() => {form.resetFields()}} buttonOnCancel={() => {}} questionLabel={questionLabel} />
                        <ButtonItem buttonType={primaryType} label={invioLabel} buttonOnClick={handleSubmit} />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default UpdateAlarmConfiguration;