import { abilitatoLabel, batteryVoltage, clearWaterLevel, clearWaterLevelMax, clearWaterLevelMin, darkWaterLevel, darkWaterLevelMax, darkWaterLevelMin, disabilitatoLabel, doorOpenStatus, firstBathroom, flushButton, gpsPosition, handWashButton, interiorLightsStatus, ledFlushStatus, positioningTilt, roomTemperature, secondBathroom, solarPanelVoltage, status, statusBathroom } from "../../../costants/costants";
import {numberType, radioType} from "../../../utilities/utilities";
import { EBlockModel, ETopPlusModel } from "../costants";

export const modelJob = (model: string, initialJob: any) => {
    switch (model) {
        case EBlockModel:
            return EBlockJob(initialJob)
        case ETopPlusModel:
            return ETopPlusJob(initialJob)
        default:
            return[]
    }
}

export const ETopPlusJob = (value: any) => [
    {
        key: roomTemperature.key,
        name: roomTemperature.name,
        label: roomTemperature.label,
        value: value.temp_en,
        type: radioType,
        rules: [ {required: true, message: roomTemperature.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: positioningTilt.key,
        name: positioningTilt.name,
        label: positioningTilt.label,
        value: value.pos_en,
        type: radioType,
        rules: [ {required: true, message: positioningTilt.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: gpsPosition.key,
        name: gpsPosition.name,
        label: gpsPosition.label,
        value: value.gps_en,
        type: radioType,
        rules: [ {required: true, message: gpsPosition.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: batteryVoltage.key,
        name: batteryVoltage.name,
        label: batteryVoltage.label,
        value: value.batt_en,
        type: radioType,
        rules: [ {required: true, message: batteryVoltage.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: solarPanelVoltage.key,
        name: solarPanelVoltage.name,
        label: solarPanelVoltage.label,
        value: value.solar_en,
        type: radioType,
        rules: [ {required: true, message: solarPanelVoltage.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        divider: clearWaterLevel.label
    },
    {
        key: clearWaterLevel.key,
        name: clearWaterLevel.name,
        label: status.label,
        value: value.cw_en,
        type: radioType,
        disabled: 0,
        rules: [ {required: true, message: clearWaterLevel.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: clearWaterLevelMin.key,
        name: clearWaterLevelMin.name,
        label: clearWaterLevelMin.label,
        rules: [ {required: true, message: clearWaterLevelMin.rules} ],
        value: value.cw_min,
        disabled: 0,
        type: numberType
    },
    {
        key: clearWaterLevelMax.key,
        name: clearWaterLevelMax.name,
        label: clearWaterLevelMax.label,
        rules: [ {required: true, message: clearWaterLevelMax.rules} ],
        value: value.cw_max,
        disabled: 0,
        type: numberType
    },
    {
        divider: darkWaterLevel.label
    },
    {
        key: darkWaterLevel.key,
        name: darkWaterLevel.name,
        label: status.label,
        value: value.dw_en,
        type: radioType,
        disabled: 1,
        rules: [ {required: true, message: darkWaterLevel.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: darkWaterLevelMin.key,
        name: darkWaterLevelMin.name,
        label: darkWaterLevelMin.label,
        rules: [ {required: true, message: darkWaterLevelMax.rules} ],
        value: value.dw_min,
        disabled: 1,
        type: numberType
    },
    {
        key: darkWaterLevelMax.key,
        name: darkWaterLevelMax.name,
        label: darkWaterLevelMax.label,
        rules: [ {required: true, message: darkWaterLevelMax.rules} ],
        value: value.dw_max,
        disabled: 1,
        type: numberType
    },
    {
        divider: statusBathroom
    },
    {
        key: `first_${doorOpenStatus.key}`,
        name: `first_${doorOpenStatus.name}`,
        label: doorOpenStatus.label,
        value: value.door1_en,
        type: radioType,
        rules: [ {required: true, message: doorOpenStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `first_${interiorLightsStatus.key}`,
        name: `first_${interiorLightsStatus.name}`,
        label: interiorLightsStatus.label,
        value: value.pir1_en,
        type: radioType,
        rules: [ {required: true, message: interiorLightsStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `first_${ledFlushStatus.key}`,
        name: `first_${ledFlushStatus.name}`,
        label: ledFlushStatus.label,
        value: value.led1_en,
        type: radioType,
        rules: [ {required: true, message: ledFlushStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },  
    {
        key: `first_${flushButton.key}`,
        name: `first_${flushButton.name}`,
        label: flushButton.label,
        value: value.flush1_en,
        type: radioType,
        rules: [ {required: true, message: flushButton.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `first_${handWashButton.key}`,
        name: `first_${handWashButton.name}`,
        label: handWashButton.label,
        value: value.sink1_en,
        type: radioType,
        rules: [ {required: true, message: handWashButton.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    }
]

export const EBlockJob = (value: any) => [
    {
        key: roomTemperature.key,
        name: roomTemperature.name,
        label: roomTemperature.label,
        value: value.temp_en,
        type: radioType,
        rules: [ {required: true, message: roomTemperature.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: positioningTilt.key,
        name: positioningTilt.name,
        label: positioningTilt.label,
        value: value.pos_en,
        type: radioType,
        rules: [ {required: true, message: positioningTilt.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: gpsPosition.key,
        name: gpsPosition.name,
        label: gpsPosition.label,
        value: value.gps_en,
        type: radioType,
        rules: [ {required: true, message: gpsPosition.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: batteryVoltage.key,
        name: batteryVoltage.name,
        label: batteryVoltage.label,
        value: value.batt_en,
        type: radioType,
        rules: [ {required: true, message: batteryVoltage.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: solarPanelVoltage.key,
        name: solarPanelVoltage.name,
        label: solarPanelVoltage.label,
        value: value.solar_en,
        type: radioType,
        rules: [ {required: true, message: solarPanelVoltage.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        divider: clearWaterLevel.label
    },
    {
        key: clearWaterLevel.key,
        name: clearWaterLevel.name,
        label: status.label,
        value: value.cw_en,
        type: radioType,
        disabled: 0,
        rules: [ {required: true, message: clearWaterLevel.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: clearWaterLevelMin.key,
        name: clearWaterLevelMin.name,
        label: clearWaterLevelMin.label,
        rules: [ {required: true, message: clearWaterLevelMin.rules} ],
        value: value.cw_min,
        disabled: 0,
        type: numberType
    },
    {
        key: clearWaterLevelMax.key,
        name: clearWaterLevelMax.name,
        label: clearWaterLevelMax.label,
        rules: [ {required: true, message: clearWaterLevelMax.rules} ],
        value: value.cw_max,
        disabled: 0,
        type: numberType
    },
    {
        divider: darkWaterLevel.label
    },
    {
        key: darkWaterLevel.key,
        name: darkWaterLevel.name,
        label: status.label,
        value: value.dw_en,
        type: radioType,
        disabled: 1,
        rules: [ {required: true, message: darkWaterLevel.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: darkWaterLevelMin.key,
        name: darkWaterLevelMin.name,
        label: darkWaterLevelMin.label,
        rules: [ {required: true, message: darkWaterLevelMax.rules} ],
        value: value.dw_min,
        disabled: 1,
        type: numberType
    },
    {
        key: darkWaterLevelMax.key,
        name: darkWaterLevelMax.name,
        label: darkWaterLevelMax.label,
        rules: [ {required: true, message: darkWaterLevelMax.rules} ],
        value: value.dw_max,
        disabled: 1,
        type: numberType
    },
    {
        divider: firstBathroom
    },
    {
        key: `first_${doorOpenStatus.key}`,
        name: `first_${doorOpenStatus.name}`,
        label: doorOpenStatus.label,
        value: value.door1_en,
        type: radioType,
        rules: [ {required: true, message: doorOpenStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `first_${interiorLightsStatus.key}`,
        name: `first_${interiorLightsStatus.name}`,
        label: interiorLightsStatus.label,
        value: value.pir1_en,
        type: radioType,
        rules: [ {required: true, message: interiorLightsStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `first_${ledFlushStatus.key}`,
        name: `first_${ledFlushStatus.name}`,
        label: ledFlushStatus.label,
        value: value.led1_en,
        type: radioType,
        rules: [ {required: true, message: ledFlushStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },  
    {
        key: `first_${flushButton.key}`,
        name: `first_${flushButton.name}`,
        label: flushButton.label,
        value: value.flush1_en,
        type: radioType,
        rules: [ {required: true, message: flushButton.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `first_${handWashButton.key}`,
        name: `first_${handWashButton.name}`,
        label: handWashButton.label,
        value: value.sink1_en,
        type: radioType,
        rules: [ {required: true, message: handWashButton.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        divider: secondBathroom
    },
    {
        key: `second_${doorOpenStatus.key}`,
        name: `second_${doorOpenStatus.name}`,
        label: doorOpenStatus.label,
        value: value.door2_en,
        type: radioType,
        rules: [ {required: true, message: doorOpenStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `second_${interiorLightsStatus.key}`,
        name: `second_${interiorLightsStatus.name}`,
        label: interiorLightsStatus.label,
        value: value.pir2_en,
        type: radioType,
        rules: [ {required: true, message: interiorLightsStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `second_${ledFlushStatus.key}`,
        name: `second_${ledFlushStatus.name}`,
        label: ledFlushStatus.label,
        value: value.led2_en,
        type: radioType,
        rules: [ {required: true, message: ledFlushStatus.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },  
    {
        key: `second_${flushButton.key}`,
        name: `second_${flushButton.name}`,
        label: flushButton.label,
        value: value.flush2_en,
        type: radioType,
        rules: [ {required: true, message: flushButton.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    },
    {
        key: `second_${handWashButton.key}`,
        name: `second_${handWashButton.name}`,
        label: handWashButton.label,
        value: value.sink2_en,
        type: radioType,
        rules: [ {required: true, message: handWashButton.rules} ],
        option: [
            {
                value: true,
                label: abilitatoLabel
            },
            {
                value: false,
                label: disabilitatoLabel
            }
        ]
    }
]