import "./LoginPanel.css"
import "antd/dist/antd.css"
import ButtonItem from "../../ButtonItem"
import { inputs } from "./inputs"
import { Form, Input, message } from "antd"
import {Link, useHistory} from 'react-router-dom'
import React, { useEffect, useState } from "react"
import { login } from "../../../api/services/loginService"
import { LoginRequest } from "../../../api/requests/loginService"
import {routes} from "../costants";
import logo from '../../../logo/logo-armal.png';
import { loginLabel, primaryType, resetPasswordLabel, textType, email as emailInput, wrongCredentials, whiteColor, backgroundColorLogin, successStatusKey } from "../../../costants/costants"

const LoginPanel: React.FC = () => {
    document.body.style.background = backgroundColorLogin;

    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const setChange = (e: any) =>{
        if(e.target.placeholder === emailInput.label){
            setEmail(e.target.value)
        }else{
            setPassword(e.target.value)
        }
    }

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [logError, setLogError] = useState(false)

    const history = useHistory();
    const checkLogin = () => {
        login({email: email, password: password} as LoginRequest).then((res: any) => {
            if(res !== undefined && res.status === successStatusKey){
                history.push("/");
                document.body.style.background = whiteColor;
            }else{
                message.error(res?.error || wrongCredentials, 4);
                setLogError(true)
            }
        }).catch((e)=>{
            message.error(wrongCredentials, 4);
            setLogError(true)
            console.log(e)
        })
    }

    return (
        <>
        <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
            <div className="image-container">
                <img src={logo} className="image" />
            </div>
            <Form name="normal_login">
                {
                    inputs.map((el)=>{ 
                        return(
                            <Form.Item 
                                key={el.key} 
                                name={el.name} 
                                rules={el.rules}
                                validateStatus= {logError ? "error" : "success"}
                            >
                                <Input
                                    data-testid={el.key}
                                    className="my-input"
                                    size="large"
                                    type={el.type ?? textType}
                                    prefix={el.icon}
                                    placeholder={el.label}
                                    onChange={setChange}
                                />  
                            </Form.Item>
                        )
                    })
                }
                <Link className="login-form-forgot" to={routes.recoverStart}>
                    {resetPasswordLabel}
                </Link>
                <div className="btn-container">
                    <Form.Item>    
                        <ButtonItem buttonType={primaryType} label={loginLabel} buttonOnClick={checkLogin} />
                    </Form.Item>
                </div>
            </Form>
        </div>
        </>
    )
}

export default LoginPanel