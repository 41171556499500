type InputType = {
    label: string
    name: string
    key: string
    rules: string
}

type TableType = {
    name: string
    label: string
}

//GENERAL
export const logo: string = "logo"
export const doneStatus: string = "done"
export const pendingStatus: string = "pending"
export const failedStatus: string = "failed"
export const fatalStatus: string = "fatal"
export const warningStatus: string = "warning"
export const installerRole: string = "installer"
export const adminRole: string = "admin"
export const customerRole: string = "viewer"
export const checkboxType: string = "checkbox"
export const switchType: string = "switch"
export const stringType: string = "string"
export const passwordType: string = "password"
export const selectType: string = "select"
export const numberType: string = "number"
export const textType: string = "text"
export const tagType: string = "tag"
export const dateType: string = "date"
export const floatType: string = "float"
export const linkType: string = "link"
export const installationLabel: string = "Installation"
export const tableKey: string = "table_div"
export const secondaryListKey: (k: string) => string = (k: string) => `${k}_secondary_list`
export const textKey: (k: string) => string = (k: string) => `${k}text`
export const collapseKey: (k: string) => string = (k: string) => `${k}collapse`
export const iconKey: (k: string) => string = (k: string) => `${k}_icon`
export const divKey: (k: string) => string = (k: string) => `${k}div`
export const colKey: (k: string) => string = (k: string) => `${k}col`
export const labelKey: (k: string) => string = (k: string) => `${k}label`
export const formKey: (k: string) => string = (k: string) => `${k}form`
export const formInputKey: (k: string) => string = (k: string) => `${k}forminput`
export const gridKey: (k: string) => string = (k: string) => `${k}grid`
export const gridCardKey: (k: string) => string = (k: string) => `${k}card`
export const invioLabel: string = "OK"
export const allLabel: string = "Run All Tests"
export const selectedLabel: string = "Run Selected Tests"
export const resetLabel: string = "Reset"
export const primaryType: "link" | "text" | "ghost" | "default" | "primary" | "dashed" | undefined = "primary"
export const defaultype: "link" | "text" | "ghost" | "default" | "primary" | "dashed" | undefined = "default"
export const questionLabel: string = "Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
export const backgroundColorLogin: string = "radial-gradient(circle, #FCE9E1 10%, #F4A889 20%, #E85112 100%)"

//FORM INPUTS
export const name: InputType = {
    label: "Name",
    name: "name",
    key: "name",
    rules: "Insert name!"
}
export const description: InputType = {
    label: "Description",
    name: "description",
    key: "description",
    rules: "Insert description!"
}
export const createdAt: InputType = {
    label: "Creation Date",
    name: "created_at",
    key: "created_at",
    rules: "Insert creation date!"
}
export const sdi: InputType = {
    label: "SDI",
    name: "sdi",
    key: "sdi",
    rules: "Insert SDI!"
}
export const pec: InputType = {
    label: "PEC",
    name: "pec",
    key: "pec",
    rules: ""
}
export const vat: InputType = {
    label: "VAT",
    name: "vat_number",
    key: "vat_number",
    rules: "Insert VAT!"
}
export const phone: InputType = {
    label: "Phone",
    name: "phone",
    key: "phone",
    rules: "Insert phone!"
}
export const subscriptionOverview: string = "Subscription overview"
export const subscriptonName : InputType = {
    label: "Name",
    name: "subscription_name",
    key: "subscription_name",
    rules: "Insert name!"
}
export const address: InputType = {
    label: "Address",
    name: "address",
    key: "address",
    rules: "Insert address!"
}
export const city: InputType = {
    label: "City/Town",
    name: "city",
    key: "city",
    rules: 'Insert city!'
}
export const province: InputType = {
    label: "State/Province",
    name: "province",
    key: "province",
    rules: 'Insert province!'
}
export const country: InputType = {
    label: "Country",
    name: "country",
    key: "country",
    rules: 'Insert country!'
}
export const zip: InputType = {
    label: "ZIP/Postal Code",
    name: "zip_code",
    key: "zip_code",
    rules: 'Insert Zip code!'
}
export const email: InputType = {
    label: "Email",
    name: "email",
    key: "email",
    rules: ""
}
export const password: InputType = {
    label: "Password",
    name: "password",
    key: "password",
    rules: ""
}
export const confirmPassword: InputType = {
    label: "Confirm password",
    name: "confirm_password",
    key: "confirm_password",
    rules: ""
}
export const companyPhone: InputType = {
    label: "Phone",
    name: "company_phone",
    key: "company_phone",
    rules: "Insert phone!"
}
export const companyAddress: InputType = {
    label: "Address",
    name: "company_address",
    key: "company_address",
    rules: "Insert address!"
}
export const companyCity: InputType = {
    label: "City/Town",
    name: "company_city",
    key: "company_city",
    rules: "Insert city!"
}
export const companyProvince: InputType = {
    label: "State/Province",
    name: "company_province",
    key: "company_province",
    rules: "Insert province!"
}
export const companyCountry: InputType = {
    label: "Country",
    name: "company_country",
    key: "company_country",
    rules: "Insert country!"
}
export const companyZip: InputType = {
    label: "ZIP/Postal Code",
    name: "company_zip_code",
    key: "company_zip_code",
    rules: "Insert CAP!"
}
export const companyName: InputType = {
    label: "Company",
    name: "company_name",
    key: "company_name",
    rules: "Insert company!"
}
export const companySdi: InputType = {
    label: "SDI",
    name: "company_sdi",
    key: "company_sdi",
    rules: "Insert SDI!"
}
export const companyPec: InputType = {
    label: "PEC",
    name: "company_pec",
    key: "company_pec",
    rules: ""
}
export const companyVat: InputType = {
    label: "VAT",
    name: "company_vat_number",
    key: "company_vat_number",
    rules: "Insert VAT!"
}
export const customerName: InputType = {
    label: "Name",
    name: "customer_name",
    key: "customer_name",
    rules: "Insert name!"
}
export const customerPhone: InputType = {
    label: "Phone",
    name: "customer_phone",
    key: "customer_phone",
    rules: "Insert phone!"
}
export const customerAddress: InputType = {
    label: "Address",
    name: "customer_address",
    key: "customer_address",
    rules: "Insert address!"
}
export const customerCity: InputType = {
    label: "City/Town",
    name: "customer_city",
    key: "customer_city",
    rules: "Insert city!"
}
export const customerProvince: InputType = {
    label: "State/Province",
    name: "customer_province",
    key: "customer_province",
    rules: "Insert province!"
}
export const customerCountry: InputType = {
    label: "Country",
    name: "customer_country",
    key: "customer_country",
    rules: "Insert country!"
}
export const customerZip: InputType = {
    label: "ZIP/Postal Code",
    name: "customer_zip_code",
    key: "customer_zip_code",
    rules: "Insert CAP!"
}

//HEADER
export const fixedPosition: "fixed" | "absolute" | "relative" | "static" | "sticky" | undefined = "fixed"
export const css: "css" | "js" | undefined = "css"
export const iconColor : "inherit" | "default" | "primary" | "secondary" | undefined = "inherit"
export const iconLabel: string = "open drawer"
export const iconEdge: false | "end" | "start" | undefined = "end"
export const avatarSrc: string = "/broken-image.jpg"
export const menuId: string = "header-menu"
export const profiloLabel: string = "Profile"
export const logoutLabel: string = "Logout"

//INPUTS
export const htmlForName: string ="name"

//NAVBAR
export const listKey: string = "list-navbar"
export const drawerKey: string = "drawer-navbar"
export const persistentVariant: "persistent" | "permanent" | "temporary" | undefined = "persistent"
export const dataRealTimeKey: string = "data_real_time"
export const alarmsKey: string = "alarms"
export const dataHistoryKey: string = "data_history"
export const historyKey: string = "history"
export const customerKey: string = "customer"
export const customersKey: string = "customers"
export const updateInstallationKey: string = "update_installation"
export const createInstallationKey: string = "create_installation"
export const updateSubscriptionKey: string = "update_subscription"
export const createSubscriptionKey: string = "create_subscription"
export const updateCustomerKey: string = "update_customer"
export const addCustomerKey: string = "add_customer"
export const updateUserKey: string = "update_user"
export const createInstallerKey: string = "create_installer"
export const updateInstallerKey: string = "update_installer"
export const installerAssociationsKey: string = "installer_associations"

//ADD INSTALLATION
export const successStatus: "success" | "error" | null = "success"
export const errorStatus: "success" | "error" | null = "error"
export const addInstallationTitleKey: string = "add_installation_title"
export const addInstallationDivKey: string = "add_installation_div"
export const formName: string = "user_panel"
export const subscription: InputType = {
    label: "Select subscription",
    name: "subscription_id",
    key: "select_subscription",
    rules: ""
}
export const placeholder: string = "..."
export const addInstallationBtnDivKey: string = "add_installation_btn"
export const model: InputType = {
    key: "model",
    name: "model",
    label: "Model",
    rules: "Insert model!"
}
export const modelOptions: any[] = [
    {"e_block": "E-Block"},
    {"e_top_plus": "E-Top Plus"}
]

//ADD SUBSCRIPTION
export const companyInfoKey: string = "companyInfo"
export const user: InputType = {
    label: "Select customer",
    name: "user_panel",
    key: "selectCustomer",
    rules: ""
}
export const children: string = "children"

//ALARMS
export const alarmSort: string = "-start"
export const start: TableType = {
    label: "Start",
    name: "start"
}
export const finish: TableType = {
    label: "Finish",
    name: "finish"
}
export const duration: TableType = {
    label: "Duration",
    name: "duration"
}
export const installationName: TableType = {
    label: "Installation",
    name: "installation_name"
}
export const tag: TableType = {
    label: "Alarm",
    name: "tag"
}
export const level: TableType = {
    label: "Level",
    name: "level"
}
export const orangeColor: string = "orange"
export const redColor: string = "red"
export const greenColor: string = "green"
export const yellowColor: string = "yellow"
export const blueColor: string = "blue"
export const whiteColor: string = "white"

//CREATE CUSTOMER
export const createCustomerTitleKey: string = "add_customer_title"
export const createCustomerDivKey: string = "add_customer_div"
export const createCustomerKey: string = "companyInfo"
export const createCustomerName: string = "user_panel"

//DATA HISTORY PANEL
export const sevenDaysError: string = "A maximum of 7 days (period range) can be selected"
export const downloadError: string = "File download error"
export const dataHistoryTitleKey: string = "data_history_title"
export const dataHistoyFormKey: string = "datahistory_panel"
export const dataHistoryPicker: InputType = {
    label: "Select the dates to download the data",
    name: "date",
    key: "",
    rules: "Insert date!"
}

//USER LIST
export const userInfo: string = "user_info"

//UPDATE USER
export const updateUserBtnDivKey: string = "btn_div"
export const updateUserDivKey: string = "update_user_div"
export const updateUserTitle: string = "update_user_title"

//UPDATE SUBSCRIPTION
export const customerLabel: string = "Customer"
export const updateSubscriptionTitleKey: string = "update_subscription_title"
export const updateSubscriptioSummaryKey: string = "update_subscription_summary"
export const updateSubscriptionDivKey: string = "update_subscription_div"
export const updateSubscriptionRowKey: string = "first_row"
export const divider: TableType = {
    label: "Subscription expiration",
    name: "third_divider"
}
export const expiryDate: InputType = {
    label: "Expiration",
    name: "datepicker",
    key: "expiry_date",
    rules: ""
}

//UPDATE INSTALLATION
export const cardKey: string = "card"
export const updateInstallationBtnKey: string = "update_installation_btn"
export const updateInstallationTitleKey: string = "update_installation_title"
export const updateInstallationDivKey: string = "update_installation_div"
export const updateInstallationCardTitle: string = "Installation Options"
export const updateInstallationCardDescription: string = "Select the desired option"
export const updateInstallationError: string = "To carry out this operation, use the configurator."
export const updateInstallationActionFirst: string = "Device Configuration"
export const updateInstallationActionSecond: string = "Calibration"
export const updateInstallationActionThird: string = "Alarms"
export const technicalTesting: string = "Technical testing"

//INSTALLER CUSTOMER RELATIONS
export const installerNameRel: TableType = {
    label: "Installer Name",
    name: "installer_name"
}
export const installerEmailRel: TableType = {
    label: "Installer Email",
    name: "installer_email"
}
export const customerNameRel: TableType = {
    label: "Customer Name",
    name: "customer_name"
}
export const customerEmailRel: TableType = {
    label: "Customer Email",
    name: "customer_email"
}
export const disassocia: string = "Disassociate"
export const associa: string = "Associate Installer"
export const installerPlaceholderRel: string = "Installer"
export const installerSelectRel: InputType = {
    label: "Select installer",
    name: "installer",
    key: "installer",
    rules: "Insert installer!"
}
export const customerPlaceholderRel: string = "Cliente"
export const customerSelectRel: InputType ={
    label: "Select customer",
    name: "client",
    key: "client",
    rules: "Insert customer!"
}
export const operationFailed: string = "Operation failed."
export const associationFailed: string = "Association failed."

//SUBSCRIPTION MANAGEMENT
export const subscriptionsLabel: string = "subscriptions"
export const companyInfoLabel: string = "company_info"
export const subscriptionLabel: string = "Subscription"
export const companyLabel: string = "Company"
export const status: TableType = {
    label: "Status",
    name: "status"
}
export const active: string = "Active"
export const deactive: string = "Deactive"
export const enabledLabel: string = "enabled"
export const disabledLabel: string = "disabled"
export const activationLabel: string = "activation"
export const deactivationLabel: string = "deactivation"
export const actionLabel: string = "Action"
export const sureQuestion: string = "Are you sure?"
export const yes: string = "Yes"
export const no: string = "No"
export const deactivationLoading: string = "Deactivation in progress"
export const activationLoading: string = "Activation in progress"
export const askDeactivation: string = "Request deactivation"
export const askActivation: string = "Request activation"

//SIGNAL CONFIGURATION
export const consumoPotenzaLabel: string = "Power Consumption"
export const velocitaVolanoLabel: string = "Flywheel Speed"
export const velocitaTrascinamentoLabel: string = "Drag Speed"
export const automaticoMAnualeLabel: string = "Automatic Manual"
export const neverDone: string = "Never Done"
export const calibrationLabel: string = "calibration"
export const signalConfigurationTitleKey: string = "signal_configuration_title"
export const signalConfigurationDivKey: string = "signal_configuration_div"
export const jobPanelLabel: string = "job_panel"
export const alarmPanelLabel: string = "alarm_panel"
export const signalConfigurationDivJob1: string = "Power Consumption"
export const signalConfigurationDivJob2: string = "Flywheel Speed"
export const signalConfigurationDivJob3: string = "Drag Speed"
export const signalConfigurationDivJob4: string = "Machine Status Automatic/Manual"
export const signalConfigurationBtnDivKey: string = "signal_configuration_btn"

export const cw_en: string = "cw_en"
export const dw_en: string = "dw_en"
export const cw_max: string = "cw_max"
export const cw_min: string = "cw_min"
export const dw_max: string = "dw_max"
export const dw_min: string = "dw_min"
export const door1_en: string = "door1_en"
export const door2_en: string = "door2_en"
export const pir1_en: string = "pir1_en"
export const pir2_en: string = "pir2_en"
export const led1_en: string = "led1_en"
export const led2_en: string = "led2_en"
export const flush1_en: string = "flush1_en"
export const flush2_en: string = "flush2_en"
export const sink1_en: string = "skin1_en"
export const sink2_en: string = "skin2_en"
export const temp_en: string = "temp_en"
export const pos_en: string = "pos_en"
export const gps_en: string = "gps_en"
export const batt_en: string = "batt_en"
export const solar_en: string = "solar_en"

export const genericSignals: string = "Generic signals"
export const statusBathroom: string = "Status bathroom"
export const firstBathroom: string ="First bathroom"
export const secondBathroom: string ="Second bathroom"
export const clearWaterLevel: InputType = {
    label: "Clear water level",
    name: "clear_water_level",
    key: "clear_water_level",
    rules: "Required field!"
}
export const clearWaterLevelMin: InputType = {
    label: "Minimum value",
    name: "clear_water_level_min",
    key: "clear_water_level_min",
    rules: "Required field!"
}
export const clearWaterLevelMax: InputType = {
    label: "Maximum value",
    name: "clear_water_level_max",
    key: "clear_water_level_max",
    rules: "Required field!"
}
export const darkWaterLevel: InputType = {
    label: "Dark water level",
    name: "dark_water_level",
    key: "dark_water_level",
    rules: "Required field!"
}
export const darkWaterLevelMin: InputType = {
    label: "Minimum value",
    name: "dark_water_level_min",
    key: "dark_water_level_min",
    rules: "Required field!"
}
export const darkWaterLevelMax: InputType = {
    label: "Maximum value",
    name: "dark_water_level_max",
    key: "dark_water_level_max",
    rules: "Required field!"
}
export const doorOpenStatus: InputType = {
    label: "Door open status",
    name: "door_open_status",
    key: "door_open_status",
    rules: "Required field!"
}
export const interiorLightsStatus: InputType = {
    label: "Interior lights status",
    name: "interior_lights_status",
    key: "interior_lights_status",
    rules: "Required field!"
}
export const ledFlushStatus: InputType = {
    label: "Led flush status",
    name: "led_flush_statud",
    key: "led_flush_status",
    rules: "Required field!"
}
export const flushButton: InputType = {
    label: "Flush button",
    name: "flush_button",
    key: "flush_button",
    rules: "Required field!"
}
export const handWashButton: InputType = {
    label: "Hand wash button",
    name: "hand_wash_button",
    key: "hand_wash_button",
    rules: "Required field!"
}
export const roomTemperature: InputType = {
    label: "Room temperature",
    name: "room_temperature",
    key: "room_temperature",
    rules: "Required field!"
}
export const positioningTilt: InputType = {
    label: "Positioning and Tilt",
    name: "positioning_tilt",
    key: "positioning_tilt",
    rules: "Required field!"
}
export const gpsPosition: InputType = {
    label: "GPS position",
    name: "gps_position",
    key: "gps_position",
    rules: "Required field!"
}
export const batteryVoltage: InputType = {
    label: "Battery voltage",
    name: "battery_voltage",
    key: "battery_voltage",
    rules: "Required field!"
}
export const solarPanelVoltage: InputType = {
    label: "Solar Panel Voltage",
    name: "solar_panel_voltage",
    key: "solar_panel_voltage",
    rules: "Required field!"
}
export const abilitatoLabel: string = "Enabled"
export const disabilitatoLabel: string = "Disabled"
export const failedLabel: string = "Failed"
export const executionLabel: string = "Running"
export const correctExecLabel: string = "Executed Correctly"
export const signalConfigurationTitleLabel: string = "Signal Calibration Status"
export const alarmConfigurationTitleLabel: string = "Alarm Calibration Status"
export const modificaLabel: string = "Change"
export const editLabel: string = "Edit"
export const minLabel: string = "Alarm if value is less than"
export const maxLabel: string = "Alarm if value is greater than"

//SELECT SUBSCRIPTION
export const activationRequest: string = "Activation request"
export const deactivationRequest: string = "Deactivation request"
export const acceptLabel: string = "Accept"
export const acceptKey: string = "accept"
export const declineLabel: string = "Decline"
export const declineKey: string = "decline"

//RESET PASSWORD
export const loginFormName: string = "normal_login"
export const returnLoginLabel: string = "Back to login"
export const resetPasswordLabel: string = "Reset password"
export const successStatusKey: string = "success"
export const resetPasswordResultTitle: string = "Email correctly sent"
export const resetPasswordResultSubTitle: string ="Check your email to continue with password recovery."

//RECOVER PASSWORD
export const recoverPasswordResultTitle: string ="Password changed successfully"
export const recoverPasswordResultSubTitle: string ="Now you can log in with the new password."
export const loginLabel: string = "Login"
export const newPasswordLabel: string = "Insert the new password"
export const confermaLabel: string = "Confirm"
export const recoverPasswordError: string = "Something went wrong, the password was not changed correctly."

//PROFILO
export const ruoloLabel: string = "Role"
export const userDataLabel: string = "User Data"

//INSTALLER LIST TABLE
export const installersKey: string = "installers"
export const deviceStatus: string = "Device Status"
export const onlineKey: string = "online"
export const company: TableType = {
    label: "Company",
    name: "company"
}
export const customer: TableType = {
    label: "Customer",
    name: "customer"
}
export const installationsKey: string = "installations"
export const wrongCredentials: string = "Incorrect credentials!"

//DEVICE CONNECTION CONFIGURATION
export const dccTitleKey: string = "add_connection"
export const dccFormName: string = "connection_panel"
export const waitLabel: string = "We are updating the device with the entered credentials, the operation may take a few minutes."
export const remainingTimeLabel: string = "Estimated remaining time:"
export const waitHereLabel: string = "Please, stay on this page until the operation is complete."

// DETAILS
export const details: string = "Details"

//ALARMS
export const alarm = {
    offline: "offline",
    batteryVoltage: "battery_voltage_alarm",
    solarPanel: "solar_panel_alarm",
    clearWaterLevel: "clear_water_level_alarm",
    darkWaterLevel: "dark_water_level_alarm",
    internalLightStatus: "internal_lights_status_alarm"
} 