import "./resetPassword.css"
import "antd/dist/antd.css"
import { Result } from "antd"
import React, { useEffect, useState } from "react"
import logo from '../../../logo/logo-armal.png';
import { backgroundColorLogin, resetPasswordResultSubTitle, resetPasswordResultTitle } from "../../../costants/costants";

type ResultResetPasswordProps = {
}

const ResultResetPassword: React.FC<ResultResetPasswordProps> = () => {
    document.body.style.background = backgroundColorLogin;
    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    return (
        <>
            <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
                <div className="image-container">
                    <img src={logo} className="image" />
                </div>
                <Result
                    status="success"
                    title={resetPasswordResultTitle}
                    subTitle={resetPasswordResultSubTitle}
                />
            </div>
        </>
    )
}

export default ResultResetPassword;