import "../../../App.css"
import { Tooltip } from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import {dateType, linkType, modelLabel, stringType, tagType} from "../../../utilities/utilities";
import { company, createdAt, customer, description, details, deviceStatus, greenColor, model, name, onlineKey, redColor, status } from "../../../costants/costants";

export const installationsListColumns: (rowData: any, SetPopupObject: (obj: any) => void, linkFunction: (value: string) => void) => ResponsiveTableColumnType[] = (rowData, SetPopupObject, linkFunction) => [
    {
        label: name.label,
        name: name.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <div className="details-container">
                <Tooltip title={details}>
                    <InfoCircleOutlined 
                        className="details-icon"
                        onClick={() => {
                            const tmp: any = rowData[data.rowIndex]
                            const objects: any[] = [
                                {
                                    name: tmp.name,
                                    model: tmp.model,
                                    description: tmp.description,
                                    created_at: tmp.created_at,
                                    status: tmp.status,
                                    last_connection: tmp.last_connection,
                                    last_disconnection: tmp.last_disconnection
                                }
                            ]
                            SetPopupObject(objects)
                        }} 
                    />
                </Tooltip>
                <div className="details"> 
                    <ResponsiveTableCell
                        type={linkType}
                        value={value}
                        link={() => linkFunction(data.tableData[data.rowIndex].id)}
                    />
                </div>
            </div>
        }
    },
    {
        label: model.label,
        name: model.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={modelLabel(value)}
                />
        }
    },
    {
        label: description.label,
        name: description.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: createdAt.label,
        name: createdAt.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={dateType}
                    value={value}
                />
        }
    },
    {
        label: deviceStatus,
        name: status.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={tagType}
                    value={value}
                    color={value === onlineKey ? greenColor : redColor}
                />
        }
    },
]

export const updateInstallationTableColumns: (rowData: any, SetPopupObject: (obj: any) => void, linkFunction: (value: string) => void) => ResponsiveTableColumnType[] = (rowData, SetPopupObject, linkFunction) => [
    ...installationsListColumns(rowData, SetPopupObject, linkFunction),
    {
        label: company.label,
        name: company.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => 
            <ResponsiveTableCell 
                type={stringType} 
                value={value} 
            />
        },
    },
    {
        label: customer.label,
        name: customer.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        },
    }
]