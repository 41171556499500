import { alarm, duration, fatalStatus, finish, greenColor, installationName, level, linkType, orangeColor, redColor, start, tag, tagType, warningStatus } from "../../../costants/costants";
import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import {dateTimeType, stringType} from "../../../utilities/utilities";
import "../../../App.css";

const switchAlarm = (tag: string) => {
    switch(tag){
        case alarm.offline:
            return `The device is offline`
        case alarm.batteryVoltage: 
            return `Battery voltage alarm`
        case alarm.solarPanel:
            return `Solar panel alarm`
        case alarm.clearWaterLevel: 
            return `Clear water level alarm`
        case alarm.darkWaterLevel:
            return `Dark water level alarm`
        case alarm.internalLightStatus:
            return `Light on for more than one hour`
        default:
            return ``
    }
} 

const diffDateAndToString = (small: Date, big: Date) => {
    const Difference_In_Time = big.getTime() - small.getTime()
    const Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24))
    const Hours = Math.floor(((Difference_In_Time / (60 * 1000)) / 60) - (24 * Days))
    const Mins = Math.floor((Difference_In_Time / (60 * 1000)) - (1440 * Days) - (60 * Hours))
    const diffDate = new Date(Difference_In_Time)
    var result = ''
    if (Mins < 60) {
        result = Mins + 'm'
    } else{
        if (Hours < 24) {
            result = diffDate.getMinutes() + 'h'
        }else{
            result = Days + 'd'
        }
    }
    return { result, Days, Mins, Hours }
}

export const alarmColumns: (linkFunction: (link: string) => void) => ResponsiveTableColumnType[] = (linkFunction) => [
    {
        label: "",
        name: "installation_id",
        options: {
            sort: false,
            filter: true,
            display: false,
            customBodyRender: (value) => <ResponsiveTableCell type={stringType} value={value} />
        },
    },
    {
        label: installationName.label,
        name: installationName.name,
        options: {
            sort: false,
            filter: true,
            customBodyRender: (value, data) => <ResponsiveTableCell 
                type={linkType} 
                value={value} 
                link={() => linkFunction(data.rowData[0])} 
            />
        },
    },
    {
        label: start.label,
        name: start.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: any) => <ResponsiveTableCell type={dateTimeType} value={value}/>
        },
    },
    {
        label: finish.label,
        name: finish.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) =>  <ResponsiveTableCell type={value !== undefined ? dateTimeType : stringType} value={value !== undefined ? value : ""}/>
        },
    },
    {
        label: duration.label,
        name: duration.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value, data) => {
                const start = new Date(String(data.tableData[data.rowIndex][2] as string))
                const finish = data.tableData[data.rowIndex][3] !== undefined ? new Date(String(data.tableData[data.rowIndex][3] as string)) : new Date()
                const difference = diffDateAndToString(start, finish)
                return <ResponsiveTableCell 
                    type={stringType} 
                    value={`${difference.Days} days ${difference.Hours} hours ${difference.Mins} mins`}
                />
            }
        },
    },
    {
        label: tag.label,
        name: tag.name,
        options: {
            sort: false,
            filter: true,
            customBodyRender: (value) =>  <ResponsiveTableCell type={stringType} value={switchAlarm(value)} />
        },
    },
    {
        label: level.label,
        name: level.name,
        options: {
            sort: false,
            filter: true,
            customBodyRender: (value) =>  <ResponsiveTableCell type={tagType} value={value} color={value === fatalStatus ? redColor : value === warningStatus ? orangeColor : greenColor} />
        },
    }
]
