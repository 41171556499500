import './summary.css'
import React, {useEffect, useState} from 'react';
import {Alert, Card} from 'antd';
import { Installation } from '../../../../api/requests/installationsService';
import { GetAlarms } from '../../../../api/services/installationsService';
import {localeDateTimeFormatter} from "../../../../utilities/utilities";
import {WarningOutlined} from "@ant-design/icons";
import { useHistory } from 'react-router';
import { alarmsPageUrl } from '../../costants';
import { alarm } from '../../../../costants/costants';

type AlarmSummaryProps = {
    installation: Installation | null
}

type Alarm = {
	installation_id: string          
	tag: string
	level: string
    info: {}
    start: string
    finish: string
}

const AlarmSummary: React.FC<AlarmSummaryProps> = ({installation}) => {

    const [alarms, setAlarms] = useState<Alarm[]>([])

    useEffect(() => {
        const d = new Date();
        const start = new Date(new Date().setDate(d.getDate() - 1)).toISOString();
        const end = d.toISOString();
        if(installation){
            GetAlarms(installation.id, start, end).then((res) => {
                if(res && res.alarms){
                    setAlarms(res.alarms)
                }else{
                    setAlarms([])
                }
            })
        }
    }, [installation])

    const switchAlarm = (a: Alarm) => {
        switch(a.tag){
            case alarm.offline:
                if(a.finish === undefined){
                    return {
                        message: `Device offline from ${localeDateTimeFormatter(a.start)}`,
                        type: "warning" as "warning"
                    }
                }else{
                    return {
                        message: `Device offline from ${localeDateTimeFormatter(a.start)} to ${localeDateTimeFormatter(a.finish)}`,
                        type: "warning" as "warning"
                    }
                }
        }
    } 

    const history = useHistory()

	return(
        <Card
            style={{minHeight: "290px", maxHeight: "290px", overflow: "scroll"}}
            hoverable
            title={<div className="test"> Last 24H alarms </div>}
            bordered
            headStyle={{maxHeight: "4vh"}}
            extra={<WarningOutlined style={{fontSize: "medium"}} onClick={() => {history.push(alarmsPageUrl)}} />}
        >
            <div key={`container_div_3`}>
                {
                    alarms.map((a: Alarm, i: number) => {
                        const alarmResult = switchAlarm(a)
                        if (alarmResult){
                            return (
                                <div key={i} className="alarm-container">
                                    <Alert message={alarmResult.message} type={alarmResult.type} />
                                </div>
                            )
                        }
                    })
                }
            </div>
        </Card>
    )
}

export default AlarmSummary;
