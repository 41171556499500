import React, { useState } from "react";
import '../../../App.css'
import {updateInstallationTableColumns} from "./inputs";
import {GetAllInstallations} from "../../../api/services/installationsService";
import CustomTitle from "../../CustomTitle";
import ResponsiveTable from "../../responsiveTable";
import { useHistory } from "react-router";
import { updateInstallationUrl } from "../costants";
import { updateInstallationTitle } from "../title";
import { company, installationsKey } from "../../../costants/costants";

type InstallationListPageProps = {
    SetPopupObject: (obj: any) => void
}

const InstallationsListPage: React.FC<InstallationListPageProps> = ({ SetPopupObject }) => {

    document.body.style.background = "#f5f5f5c7"
    const [data, setData] = useState<Object[]>([])
    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateInstallationUrl(value))
    }

    return (
        <>
            <CustomTitle title={updateInstallationTitle} />
            <ResponsiveTable 
                columns={updateInstallationTableColumns(data, SetPopupObject, linkFunction)} 
                getPageAndSortedData={GetAllInstallations}
                defaultSort={company.name}
                responseDataName={installationsKey}
                setData={setData}
            />
        </>
    )
}

export default InstallationsListPage;