import React from "react";
import {LeftCircleTwoTone} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import '../App.css';
import {Divider} from "antd";
import {useHistory} from "react-router-dom";
import {subscriptionsListToInstallationPageUrl} from "./pages/costants";
import { dataRealTimeTitle } from "./pages/title";

type TitleBackProps = {
    title: string
    link?: string 
    onClickProp?: () => void
    disabled?: boolean
}

const TitleWithBack: React.FC<TitleBackProps> = ({ title, link, onClickProp,disabled}) => {
    const history = useHistory();
    return (
        <>
        <div className={"titleContainer titleContainerResponsive titleContainerHeigth"}>
            <Title>
                <LeftCircleTwoTone
                    className="back-button"
                    twoToneColor={disabled ? "#808080" : "#e85112"}
                    onClick={
                        !disabled ?
                            onClickProp ?
                            onClickProp :
                            () => {
                                if(link) {
                                    history.push(link);
                                } else {
                                    if(title === dataRealTimeTitle){
                                        history.push(subscriptionsListToInstallationPageUrl)
                                    }else{
                                        history.goBack();
                                    }
                                }
                            } :
                            () => {}
                    }
                />
            </Title>
            <Title className={"titleBack responsiveTitle"} level={2}>
                {title}
            </Title>
        </div>
        <Divider/>
        </>
    )
}

export default TitleWithBack;