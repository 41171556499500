import {MenuType, SubMenuType} from "../types/navbar/types";
import {SettingOutlined} from "@ant-design/icons";
import "./Navbar.css";
import {
    alarmsPageUrl,
    createCustomerPageUrl,
    createInstallationUrl,
    createSubscriptionPageUrl,
    customersListPageUrl,
    installationsListToUpdateUrl,
    installerPanelIconsUrl,
    subscriptionsListToDataHistoryPageUrl,
    subscriptionsListToInstallationPageUrl,
    subscriptionsListToUpdateUrl
} from "../pages/costants";
import {
    addInstallationTitle,
    addSubscriptionTitle,
    addUserTitle,
    alarmsTitle,
    ConfiguratorTitle,
    dataHistoryTitle,
    dataRealTimeTitle,
    updateInstallationTitle,
    updateSubscriptionTitle,
    updateUserTitle
} from "../pages/title";
import dataHistory from "../pages/homePages/img/cloud-download_black.png";
import dataRealTime from "../pages/homePages/img/graph_black.png";
import user from "../pages/homePages/img/user_black.png";
import change from "../pages/homePages/img/change_black.png";
import device from "../pages/homePages/img/device_black.png";
import alarm from "../pages/homePages/img/alert_black.png";
import {
    addCustomerKey,
    alarmsKey,
    createInstallationKey,
    createSubscriptionKey,
    dataRealTimeKey,
    historyKey,
    installerRole,
    updateCustomerKey,
    updateInstallationKey,
    updateSubscriptionKey
} from "../../costants/costants";

const installer: SubMenuType[] = [
    {
        key: addCustomerKey,
        label: addUserTitle,
        link: createCustomerPageUrl,
        icon: <img alt={"--"} src={user} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: updateCustomerKey,
        label: updateUserTitle,
        icon: <img alt={"--"} src={user} className={"menu-icon"}/>,
        link: customersListPageUrl,
        elements: []
    },
    {
        key: createSubscriptionKey,
        label: addSubscriptionTitle,
        icon: <img alt={"--"} src={change} className={"menu-icon"}/>,
        link: createSubscriptionPageUrl,
        elements: []
    },
    {
        key: updateSubscriptionKey,
        label: updateSubscriptionTitle,
        icon: <img alt={"--"} src={change} className={"menu-icon"}/>,
        link: subscriptionsListToUpdateUrl,
        elements: []
    },
    {
        key: createInstallationKey,
        label: addInstallationTitle,
        icon: <img alt={"--"} src={device} className={"menu-icon"}/>,
        link: createInstallationUrl,
        elements: []
    },
    {
        key: updateInstallationKey,
        label: updateInstallationTitle,
        link: installationsListToUpdateUrl,
        icon: <img alt={"--"} src={device} className={"menu-icon"}/>,
        elements: []
    }
]

export const installerMenuItems: MenuType[] = [
    {   
        key: dataRealTimeKey,
        label: dataRealTimeTitle,
        icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToInstallationPageUrl
    },
    {
        key: historyKey,
        label: dataHistoryTitle,
        icon: <img alt={"--"} src={dataHistory} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToDataHistoryPageUrl
    },
    {   
        key: alarmsKey, 
        label: alarmsTitle,
        link: alarmsPageUrl,
        icon: <img alt={"--"} src={alarm} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: installerRole,
        label: ConfiguratorTitle,
        icon: <SettingOutlined />,
        elements: installer,
        link: installerPanelIconsUrl
    }
]