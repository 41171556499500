import './SubscriptionAdminTable.css'
import React, { useCallback, useEffect, useState } from "react";
import {Button, Form, message, Select} from 'antd';
import '../../../App.css'
import { AddUsersRelation, DeleteUsersRelation, GetCustomers, GetInstallers, GetRelInstallersCustomer } from '../../../api/services/userService';
import { User, UserRelation, UsersRelationRequest } from '../../../api/requests/userService';
import CustomTitle from "../../CustomTitle";
import ResponsiveTable from '../../responsiveTable';
import { installersListColumns } from './inputs';
import { associationsInstallersTitle } from '../title';
import { associa, associationFailed, customerPlaceholderRel, customerSelectRel, installerPlaceholderRel, installerSelectRel, operationFailed, primaryType } from '../../../costants/costants';
import { debounceFunc } from '../../../utilities/utilities';

const { Option } = Select;


type InstallerCustomerRelationsPageProps = {
    SetPopupObject: (obj: any) => void
}

const InstallerCustomerRelationsPage: React.FC<InstallerCustomerRelationsPageProps> = ({ SetPopupObject }) => {

    document.body.style.background = "#f5f5f5c7"

    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const [form] = Form.useForm()
    const [relInstallers, setRelInstallers] = useState<UserRelation[]>([])
    const [installers, setInstallers] = useState<User[]>([])
    const [customers, setCustomers] = useState<User[]>([])
    useEffect(() => {
        GetInstallers("-1", "", "").then((res: any) => {
			if(res && res.installers){
				setInstallers(res.installers)
			}
		})
    }, [])
    useEffect(() => {
        GetCustomers("-1", "", "").then((res: any) => {
			if(res && res.customers){
				setCustomers(res.customers)
			}
		})
    }, [])
    useEffect(() => {
        getRelations()
    }, [])

    const getRelations = useCallback( () => {
        GetRelInstallersCustomer().then((res: any) => {
			if(res && res.relations){
				setRelInstallers(res.relations)
			}
		})
    }, [] )

    const addRelation = useCallback( () => {
        form.validateFields().then(
            values =>
            AddUsersRelation({installer_id: values.installer, customer_id: values.client} as UsersRelationRequest).then(res => {
                if(res && res.result){
                    message.success(res.result, 4)
                    getRelations()
                }else{
                    message.error(res?.err?.message || associationFailed, 4)
                }
            })
        )
    }, [])

    const deleteRelation = useCallback( (record: UserRelation) => {
        DeleteUsersRelation({installer_id: record.installer_id, customer_id: record.customer_id} as UsersRelationRequest).then(res => {
                if(res && res.result){
                    message.success(res.result, 4)
                    getRelations()
                }else{
                    message.error(res?.err?.message || operationFailed, 4)
                }
            })
    }, [])

    return(
        <>
            <CustomTitle title={associationsInstallersTitle}/>
            <Form className={width > 575 ? "form-installer" : undefined} layout="vertical" key={1} form={form} name={installerSelectRel.name} requiredMark="optional">
                <Form.Item className={width > 575 ? "select-item" : undefined} name={installerSelectRel.name} label={installerSelectRel.label} rules={[ {required: true, message: installerSelectRel.rules} ]} >
                    <Select size='large'
                        showSearch
                        placeholder={installerPlaceholderRel}
                        optionFilterProp="children"
                        filterOption={(input, option: any) => {
                            if(option && option.children) {
                                return option?.children.filter((option: string) => option.toLowerCase().includes(input.toLowerCase())).length > 0
                            }
                            return false
                        }}
                    >
                    {
                        installers.map((el, index) =>
                            <Option value={el.uid} key={index}> {el.email} </Option>
                        )
                    }
                    </Select>
                </Form.Item>
                <Form.Item className={width > 575 ? "select-item" : undefined} name={customerSelectRel.name} label={customerSelectRel.label} rules={[ {required: true, message: customerSelectRel.rules} ]} >
                    <Select size='large'
                        showSearch
                        placeholder={customerPlaceholderRel}
                        optionFilterProp="children"
                        filterOption={(input, option: any) => {
                            if(option && option.children) {
                                return option?.children.filter((option: string) => option.toLowerCase().includes(input.toLowerCase())).length > 0
                            }
                            return false
                        }}
                    >
                    {
                        customers.map((el, index) =>
                            <Option value={el.uid} key={index}> {el.email} </Option>
                        )
                    }
                    </Select>
                </Form.Item>
            </Form>
            <div className="btn-confirm-container">
                <Button className="btn-confirm" type={primaryType} size="large" onClick={debounceFunc(() => addRelation())}> {associa} </Button>
            </div>
            <ResponsiveTable columns={installersListColumns(relInstallers, SetPopupObject, deleteRelation)} data={relInstallers} />
        </>
    )
}

export default InstallerCustomerRelationsPage;