import { Badge, Descriptions } from "antd"
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router";
import { Installation } from "../../../api/requests/installationsService";
import { getInstallation, getJob } from "../../../api/services/installationsService"
import { blueColor, calibrationLabel, correctExecLabel, doneStatus, executionLabel, failedLabel, failedStatus, greenColor, modificaLabel, neverDone, pendingStatus, primaryType, redColor, signalConfigurationBtnDivKey, signalConfigurationTitleKey, signalConfigurationTitleLabel, yellowColor } from "../../../costants/costants";
import { EBlockModel, ETopPlusModel, updateSignalConfigurationUrl } from "../costants";
import ButtonItem from "../../ButtonItem";
import TitleWithBack from "../../TitleWithBack";
import { signalConfigurationTitle } from "../title"
import EBlockSummary from "./EBlockSummary";
import ETopPlusSummary from "./ETopPlusSummary";

type SignalConfigurationTableProps = {
    installation_id: string
}

const SignalConfigurationTable: React.FC<SignalConfigurationTableProps> = ({ installation_id }) => {

    const history = useHistory()

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const [loading, setLoading] = useState<boolean>(true)
    const [initialJob, setInitialJob] = useState({
        config: {},              
        installation_id: "",
        last_set_status: neverDone
    })

    const [installation, setInstallation] = useState<Installation | null>(null)
    useEffect(() => {
        getJob(installation_id, calibrationLabel).then((res: any) => {
            if(res && res.calibration){
                setInitialJob(res.calibration)    
            }
            getInstallation({id: installation_id}).then((res: any) => {
                if (res && res.installation) {
                    setInstallation(res.installation)
                }
                setLoading(false)
            })
        })
    }, [installation_id])

    const checkBadgeColor = (last_set_status: string) => {
        switch (last_set_status) {
            case doneStatus:
                return greenColor
            case pendingStatus:
                return yellowColor
            case failedStatus:
                return redColor
            default:
                return blueColor
        }
    }

    const checkLastStatus = (last_set_status: string) => {
        switch (last_set_status) {
            case doneStatus:
                return correctExecLabel
            case pendingStatus:
                return executionLabel
            case failedStatus:
                return failedLabel
            default:
                return neverDone
        }
    }

    const checkSummary = useCallback((model: string) => {
        switch (model) {
            case EBlockModel:
                return <EBlockSummary jobValue={initialJob?.config || {}} />
            case ETopPlusModel: 
                return <ETopPlusSummary jobValue={initialJob?.config || {}} />
            default:
                return null
        }
    }, [initialJob])

    if(loading){
        return null
    }

    return(
        <div style={{marginBottom: "20%"}}>
            <TitleWithBack title={signalConfigurationTitle} key={signalConfigurationTitleKey} />
            <Descriptions style={{marginLeft: (width <= 575 || height <= 815) ? "1%" : "20%", marginRight: (width <= 575 || height <= 815) ? "1%" : "20%"}} column={1} bordered>
                <Descriptions.Item label={signalConfigurationTitleLabel}> 
                    <Badge color={checkBadgeColor(initialJob.last_set_status)} text={checkLastStatus(initialJob.last_set_status)} /> 
                </Descriptions.Item>
            </Descriptions>
            <div className="btn-container" key={signalConfigurationBtnDivKey}>
                <ButtonItem buttonType={primaryType} label={modificaLabel} buttonOnClick={() => {history.push(updateSignalConfigurationUrl(installation_id))}} />
            </div>
            {
                checkSummary(installation?.model || "")
            }
        </div>
    )
}

export default SignalConfigurationTable;