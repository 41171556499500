import './DataHistoryPanel.css'
import React, {useCallback, useEffect, useState} from 'react';
import { DatePicker, Form, message } from 'antd';
import { Installation } from '../../../api/requests/installationsService';
import ButtonConfItem from '../../ButtonConfItem';
import ButtonItem from '../../ButtonItem';
import {getInstallation} from "../../../api/services/installationsService";
import moment from 'moment';
import { dataHistoryTitle } from '../title';
import {GetExportInfoRequest} from "../../../api/requests/timeSeries";
import {getExportInfo} from "../../../api/services/timeSeries";
import { dataHistoryPicker, dataHistoryTitleKey, dataHistoyFormKey, downloadError, invioLabel, primaryType, questionLabel, resetLabel, sevenDaysError } from '../../../costants/costants';
import TitleWithBack from '../../TitleWithBack';
import InstallationSummary from '../../installationSummary/InstallationSummary';

type DataHistoryPanelProps = {
    installation_id: string
}

const DataHistoryPanel: React.FC<DataHistoryPanelProps> = ({installation_id}) => {
    const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null)
    const [form] = Form.useForm()

    useEffect(() => {
        getInstallation({id: installation_id}).then((res: any) => {
            if (res && res.installation) {
                setSelectedInstallation(res.installation)
            }
        })
    }, [])

    const downloadFile = useCallback((url: string) => {
        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = url
        
        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    }, [])

    const dateFormatter = (date: Date) => {
        return moment(date).format('YYYY-MM-DD');
    };

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                const first = new Date(values.date[0])
                const second = new Date(values.date[1])
                let differenceInTime = second.getTime() - first.getTime();
                let differenceInDays = differenceInTime / (1000 * 3600 * 24);

                if(differenceInDays <= 6){
                    const info: GetExportInfoRequest = {
                        tag: "data",
                        start: dateFormatter(new Date(values.date[0])),
                        end: dateFormatter(new Date(values.date[1])),
                        entity_id: installation_id
                    }
                    getExportInfo(info).then(
                        resp => {
                            if(resp && resp.url) {
                                downloadFile(resp.url)
                            } else if (resp && resp.err) {
                                message.error(resp.err?.message ?? downloadError)
                            }
                        }
                    ).catch(
                        e => console.log("error: ", e)
                    )
                }else{
                    message.error(sevenDaysError)
                }
            }
        )
    }

    if (!selectedInstallation) {
        return null
    }

    return (
        <>
            <TitleWithBack title={dataHistoryTitle} key={dataHistoryTitleKey}/>
            <InstallationSummary selected_installation={selectedInstallation} />
            <div className="my-data-container my-data-container-responsive">
                <Form className={"exportForm"} layout="vertical" key={1} form={form} name={dataHistoyFormKey} requiredMark="optional">
                    <Form.Item name={dataHistoryPicker.name} label={dataHistoryPicker.label} rules={[ {required: true, message: dataHistoryPicker.rules} ]} >
                        <DatePicker.RangePicker className="datepicker" size="large" />
                    </Form.Item>    
                    <div className="btn-container">
                        <ButtonConfItem buttonLabel={resetLabel} buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel={questionLabel} />
                        <ButtonItem buttonType={primaryType} label={invioLabel} buttonOnClick={handleSubmit} />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default DataHistoryPanel;