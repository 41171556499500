import './summary.css'
import React, {useCallback, useEffect, useState} from 'react';
import {Card, Divider} from 'antd';
import { Installation } from '../../../../api/requests/installationsService';
import Title from "antd/es/typography/Title";
import CustomTag from "../../../CustomTag";
import {getInstallation} from "../../../../api/services/installationsService";
import {Typography} from "@mui/material";
import moment from "moment";

type RealTimeSummaryProps = {
    selected_installation: Installation | null
}

const RealTimeSummary: React.FC<RealTimeSummaryProps> = ({selected_installation}) => {
    const [installation, setInstallation] = useState<Installation | null> (selected_installation);

    const updateRtInfo = useCallback( () => {
        if(selected_installation){
            getInstallation({id: selected_installation.id}).then((res: any) => {
                if(res && res.installation) {
                    setInstallation(res.installation)
                }
            })
        }
    }, [selected_installation]);

    useEffect(() => {
        updateRtInfo();
        const intervalId = setInterval(
            () => {
                updateRtInfo();
            }
        , 15000)
        return () => {
            clearInterval(intervalId);
        }
    }, [selected_installation])

    return(
        <Card
            style={{minHeight: "290px", maxHeight: "290px", overflow: "scroll"}}
            hoverable
            title={<Title level={4} className={"tableTitle"}>Real Time Info</Title>}
            headStyle={{maxHeight: "4vh"}}
        >
            <div key={`container_div_2`}>
                <div className="tag-container">
                    <Typography>Status:</Typography>
                    {
                        installation && installation.status === "online" ? 
                        <CustomTag color={"green"} text={"online"}/> :
                        <CustomTag color={"red"} text={"offline"}/>
                    }
                </div>
                <Divider />
            </div>

            <div key={`container_div_3`}>
                <div className="tag-container">
                    <Typography style={{textTransform: "none"}}>Last connection:</Typography>
                    <div>
                        {
                            selected_installation?.last_connection ?
                                moment(selected_installation.last_connection).format("HH:mm:ss DD/MM/YYYY") :
                                "--"
                        }
                    </div>
                </div>
                <Divider />
            </div>

            <div key={`container_div_4`}>
                <div className="tag-container">
                    <Typography style={{textTransform: "none"}}>Last disconnection:</Typography>
                    <div>
                        {
                            selected_installation?.last_disconnection ?
                                moment(selected_installation.last_disconnection).format("HH:mm:ss DD/MM/YYYY ") :
                                "--"
                        }
                    </div>
                </div>
            </div>

        </Card>
    )
}

export default RealTimeSummary;