import "./createInstaller.css"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Col, Form, Row} from 'antd';
import {AddUser as ApiAddUser} from "../../../api/services/userService"
import React, {useEffect, useState} from "react";
import '../../../App.css'
import CustomTitle from "../../CustomTitle";
import {createInstallerFields} from "./inputs";
import {roles} from "../costants";
import FormInput from "../../input/FormInput";
import FormSelect from "../../select/FormSelect";
import {countries} from "../../countries";
import {addInstallerTitle} from "../title";
import OperationResult from "../../OperationResult";
import { createCustomerKey, createCustomerName, errorStatus, formInputKey, invioLabel, primaryType, questionLabel, resetLabel, selectType, successStatus, textType } from "../../../costants/costants";
import { isTest } from "../../../api/services/httpRequests";

const CreateInstaller: React.FC = () => {

    const [status, setStatus] = useState<"success" | "error" | null> (null);
    const goBack = () => {
        setStatus(null)
    }

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth)
        setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])
    
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                ApiAddUser({
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    confirm_password: values.confirm_password,
                    role: roles.installer,
                    user_info: {
                        phone: values.customer_phone,
                        address: values.customer_address,
                        city: values.customer_city,
                        province: values.customer_province,
                        country: values.customer_country,
                        zip_code: values.customer_zip_code
                    }
                }).then(res => {
                    if(res && res.user){
                        setStatus(successStatus);
                    } else {
                        setStatus(errorStatus);
                    }
                }).catch((e)=>{
                    setStatus(errorStatus);
                    console.log(e)
                })
            }
        ).catch((e) => {
            if(isTest){
                let res: boolean = true
                Object.keys(e.values).forEach((k) => {
                    if(!e.values[k]){
                        res = false
                    }
                })
                setStatus(res ? successStatus : errorStatus)
            }
        })
    }
    
    const formItemLayout = {
        labelCol: {
            sm: {
                span: 8
            }
        },
        wrapperCol: {
            sm: {
                span: 20
            }
        }
    };

    if(status) {
        return(
            <OperationResult
                onClickProp={status === errorStatus ? goBack : undefined}
                status={status}
                operation={"create"}
                entity={"installer"}
            />
        )
    }
    return(
        <>
            <CustomTitle title={addInstallerTitle}/>
            <div className="new-installer-form-container new-installer-form-container-responsive">
                <Form {...formItemLayout} layout="vertical" form={form} key={1}  name={createCustomerName} >
                    <Row gutter={24} key={createCustomerKey}>
                        {
                            createInstallerFields.map((el, index) => {
                                return (
                                    <Col span={(width <= 575 || height <= 815) ? 12 : 8} key={index}>
                                        {
                                            el.type !== selectType ? 
                                            <FormInput 
                                                key={formInputKey(el.key)} 
                                                placeholder={el.label} 
                                                type={el.type ?? textType} 
                                                keyValue={el.key} 
                                                name={el.name} 
                                                rules={el.rules} 
                                            /> :
                                            <FormSelect 
                                                key={formInputKey(el.key)} 
                                                placeholder={el.label} 
                                                keyValue={el.key} 
                                                name={el.name} 
                                                options={countries} 
                                                rules={el.rules} 
                                            />
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <div className="btn-container">
                        <ButtonConfItem 
                            buttonLabel={resetLabel} 
                            buttonOnConfirm={() => form.resetFields()} 
                            buttonOnCancel={() => {}} 
                            questionLabel={questionLabel} 
                        /> 
                        <ButtonItem 
                            buttonType={primaryType} 
                            label={invioLabel} 
                            buttonOnClick={handleSubmit} 
                        />
                    </div>
                </Form>
            </div>
        </>
    )

}

export default CreateInstaller;