import {
    address,
    city, companyAddress, companyCity, companyCountry,
    companyName, companyPec, companyPhone, companyProvince, companySdi, companyVat, companyZip,
    country,
    name,
    numberType,
    pec,
    phone,
    province,
    sdi,
    selectType,
    subscriptonName,
    vat,
    zip
} from "../../../costants/costants";
import { InputType } from "../../types/form/types";


export const companyInfo: InputType[] = [
    {
        key: subscriptonName.key,
        name: subscriptonName.name,
        label: subscriptonName.label,
        rules: [ {required: true, message: subscriptonName.rules} ]
    },
    {
        key: companyName.key,
        name: companyName.name,
        path: name.name,
        label: companyName.label,
        rules: [ {required: true, message: companyName.rules} ]
    },
    {
        key: companySdi.key,
        name: companySdi.name,
        path: sdi.name,
        label: companySdi.label,
    },
    {
        key: companyPec.key,
        name: companyPec.name,
        label: companyPec.label,
        path: pec.name
    },
    {
        key: companyVat.key,
        name: companyVat.name,
        label: companyVat.label,
        path: vat.name,
        rules: [ {required: true, message: companyVat.rules} ],
    },
    {
        key: companyPhone.key,
        name: companyPhone.name,
        label: companyPhone.label,
        path: phone.name,
        rules: [ {required: true, message: companyPhone.rules} ],
        type: numberType
    },
    {
        key: companyAddress.key,
        name: companyAddress.name,
        label: companyAddress.label,
        path: address.name,
        rules: [ {required: true, message: companyAddress.rules} ]
    },
    {
        key: companyCity.key,
        name: companyCity.name,
        label: companyCity.label,
        path: city.name,
        rules: [ {required: true, message: companyCity.rules} ]
    },
    {
        key: companyProvince.key,
        name: companyProvince.name,
        label: companyProvince.label,
        path: province.name
    },
    {
        key: companyCountry.key,
        name: companyCountry.name,
        label: companyCountry.label,
        path: country.name,
        rules: [ {required: true, message: companyCountry.rules} ],
        type: selectType
    },
    {
        key: companyZip.key,
        name: companyZip.name,
        label: companyZip.label,
        path: zip.name,
        rules: [ {required: true, message: companyZip.rules} ]
    }
]