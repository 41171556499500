import './DeviceConnection.css'
import ButtonItem from "../../ButtonItem"
import { Form, Input, Progress, Result, Select } from 'antd';
import React, { useEffect, useState } from "react";
import { CreateIdentity, DeleteIdentity, GetIdentity, getInstallation } from '../../../api/services/installationsService';
import { connectionTitle } from '../title';
import { BackendUrl } from '../../../api/constants';
import { gsm, receiveChannels, resultStatus, resultTitle, select, sendChannels, wifi } from './costant'
import { ResultStatusType } from 'antd/lib/result';
import {Typography} from "@mui/material";
import { dccFormName, dccTitleKey, invioLabel, primaryType, remainingTimeLabel, waitHereLabel, waitLabel } from '../../../costants/costants';
import TitleWithBack from "../../TitleWithBack";
import { Installation } from '../../../api/requests/installationsService';

const { Option } = Select;

type ResultLog = {
    command: string
    stdOutput: any[]
    stdError: any[]
    result: any
}

type DeviceConnectionConfigurationProps = {
    installation_id: string
}

const DeviceConnectionConfiguration: React.FC<DeviceConnectionConfigurationProps> = ({ installation_id }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null)
    useEffect(() => {
        getInstallation({id: installation_id}).then((res: any) => {
            if (res && res.installation) {
                setSelectedInstallation(res.installation)
            }
            setLoading(false)
        })
    }, [])

    const totalEstimatedSeconds = 106;
    const tenPercentRemainingSeconds = 104;
    const twentyPercentRemainingSeconds = 103;
    const thirtyPercentRemainingSeconds = 55;
    const fortyPercentRemainingSeconds = 54;
    const sixtyPercentRemainingSeconds = 37;
    const seventyPercentRemainingSeconds = 37;
    const seventyfivePercentRemainingSeconds = 36;
    const eightyPercentRemainingSeconds = 36;
    const ninetyPercentRemainingSeconds = 26;
    const hundredPercentRemainingSeconds = 0;

    const [formItem, setFormItem] = useState(select.options[1].value)
    const [form] = Form.useForm();
    const [result, setResult] = useState<boolean | null>(null)
    const [progress, setProgress] = useState<boolean>(false)
    const [percent, setPercent] = useState<number>(0)
    const [checkDevice, setCheckDevice] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const [remainingSeconds, setRemainingSeconds] = useState<number>(totalEstimatedSeconds);
    const [interval, setIntervalId] = useState<any>();

    let board: string = ""
    let uid: string = ""
    let phys_id: string = ""
    let path: string = ""

    const connect: () => void = () => {
        setProgress(true)
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            // Electron-specific code
            window.api.send(sendChannels.firmware_download, `${BackendUrl}/firmwares/latest?model=${selectedInstallation?.model || ""}`)
        }
    }

    const secondsToTimer = (r: number) => {
        let minutes, seconds = "";

        let m = Math.floor(r % 3600 / 60);
        let s = Math.floor(r % 3600 % 60);

        if (m < 10) { minutes = "0"+m;} else {minutes = m.toString()}
        if (s < 10) { seconds = "0"+s;} else {seconds = s.toString()}
        return `${minutes}:${seconds}`;
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (progress){
            intervalId = setInterval(() => {
                setRemainingSeconds(remainingSeconds-1);
                if (remainingSeconds <= 0) {
                    setRemainingSeconds(0);
                    clearInterval(intervalId);
                }
                if (percent >= 100) {
                    setRemainingSeconds(0);
                    clearInterval(intervalId);
                }
            }, 1000);
            setIntervalId(intervalId);
        }
        return () => {
            clearInterval(intervalId);
        }
    }, [remainingSeconds, progress, percent])

    const firmware_download = (result: string) => {
        console.log(sendChannels.firmware_download)
        if(result !== ""){
            path = result
            window.api.send(sendChannels.ztc_change_connection, [data, path])
        }else{
            setResult(false)
            clearInterval(interval);
        }
    }

    const ztc_change_connection = (result: ResultLog, lines: any) => {
        console.log("REPLACE net.json: ", result)    
        if(result.result){
            if(remainingSeconds !== tenPercentRemainingSeconds) {
                setRemainingSeconds(tenPercentRemainingSeconds)
            }
            setPercent(10)
            window.api.send(sendChannels.ztc_discover, "")
        }else{
            setResult(false)
            clearInterval(interval);
        }
    }

    const ztc_discover = (result: ResultLog) => {
        console.log("DISCOVER: ", result)
        const devices: any[] = result.result
        board = ""
        uid = ""
        if(devices.length > 0){
            let tmp_board: string | null = null
            let tmp_uid: string | null = null
            devices.map((el) => {
                if(!board && !uid){
                    tmp_board = el.board
                    tmp_uid = el.uid
                }
            })
            if(!tmp_board || !tmp_uid){
                setResult(false)
                clearInterval(interval);
            }else{
                board = tmp_board
                uid = tmp_uid
                setPercent(20)
                if(remainingSeconds !== twentyPercentRemainingSeconds) {
                    setRemainingSeconds(twentyPercentRemainingSeconds)
                }
                window.api.send(sendChannels.ztc_erase, [board, uid])
            }
        }else{
            setResult(false)
            clearInterval(interval);
        }
    }

    const ztc_erase = (result: ResultLog) => {
        console.log("ERASE: ", result)
        if(result.result){
            setPercent(30)
            if(remainingSeconds !== thirtyPercentRemainingSeconds) {
                setRemainingSeconds(thirtyPercentRemainingSeconds)
            }
            window.api.send(sendChannels.ztc_phys_id, uid)
        }else{
            setResult(false)
            clearInterval(interval);
        }
    }

    const ztc_phys_id = async(result: ResultLog) => {
        console.log("GETTING PHYSID: ", result)
        await window.api.send(sendChannels.ztc_check_discover, [board, uid])
        if(checkDevice){
            if(board === "4zerobox_v9"){
                window.api.send(sendChannels.ztc_provision_prepare, [`${BackendUrl}/firmwares/provisioning`, board, uid])
            }else{
                phys_id = String(result.result)
                window.api.send(sendChannels.ztc_provision_prepare, [`${BackendUrl}/firmwares/provisioning`, board, phys_id])
            }
            setPercent(40)
            if(remainingSeconds !== fortyPercentRemainingSeconds) {
                setRemainingSeconds(fortyPercentRemainingSeconds)
            }
        }else{
            setResult(false)
            clearInterval(interval);
        }
    }

    const ztc_provision_prepare = async(result: ResultLog) => {
        console.log("PROVISION PREPARE: ", result)
        await window.api.send(sendChannels.ztc_check_discover, [board, uid])
        if(checkDevice){
            if(Boolean(result.result)){
                setPercent(60);
                if(remainingSeconds !== sixtyPercentRemainingSeconds) {
                    setRemainingSeconds(sixtyPercentRemainingSeconds)
                }
                if(board === "4zerobox_v9"){
                    window.api.send(sendChannels.ztc_provision_command, uid)
                }else{
                    window.api.send(sendChannels.ztc_provision_command, phys_id)
                }
            }else{
                setResult(false)
                clearInterval(interval);
            }
        }else{
            setResult(false)
            clearInterval(interval);
        }
    }

    const ztc_provision_command = async(result: ResultLog) => {
        console.log("PROVISION COMMAND: ", result)
        const bundle: string = String(result.result)
            await window.api.send(sendChannels.ztc_check_discover, [board, uid])
            if(checkDevice){
                setPercent(70)
                if(remainingSeconds !== seventyPercentRemainingSeconds) {
                    setRemainingSeconds(seventyPercentRemainingSeconds)
                }
                GetIdentity(installation_id).then((res) => {
                    if(res && !res.err){
                        if(res.identities){
                            //sullo ZDM c'è già una identity
                            let old_dcn = res.identities[0]?.dcn
                            let new_dcn = Buffer.from(bundle.split(":")[0], 'base64').toString('binary')
                            if(new_dcn.includes(old_dcn)){
                                console.log("aggiornamento firmware")
                                //non si vuole sostituire il device fisico ma solo aggiornare il firmware
                                setPercent(80)
                                if(remainingSeconds !== eightyPercentRemainingSeconds) {
                                    setRemainingSeconds(eightyPercentRemainingSeconds)
                                }
                                window.api.send(sendChannels.zdm_sim_active, [installation_id])
                            }else{
                                console.log("sostituzione device fisico")
                                //si vuole sostituire il device fisico
                                DeleteIdentity({installation_id: installation_id, dcn: old_dcn}).then((res) => {
                                    if(res && !res.err){
                                        setPercent(75);
                                        if(remainingSeconds !== seventyfivePercentRemainingSeconds) {
                                            setRemainingSeconds(seventyfivePercentRemainingSeconds)
                                        }
                                        CreateIdentity({phys_id: phys_id, bundle: bundle}, installation_id).then((res: any) => {
                                            if(res && !res.err){
                                                setPercent(80)
                                                if(remainingSeconds !== eightyPercentRemainingSeconds) {
                                                    setRemainingSeconds(eightyPercentRemainingSeconds)
                                                }                                                
                                                window.api.send(sendChannels.zdm_sim_active, [installation_id])
                                            }else{
                                                setResult(false)
                                                clearInterval(interval);
                                            }
                                        })
                                    }else{
                                        setResult(false)
                                        clearInterval(interval);
                                    }
                                })
                            }
                        }else{
                            console.log("primo collegamento")
                            //sullo ZDM non c'è nessuna identity quindi è il primo collegamento
                            CreateIdentity({phys_id: phys_id, bundle: String(result.result)}, installation_id).then((res: any) => {
                                if(res && !res.err && path !== ""){
                                    setPercent(80)
                                    if(remainingSeconds !== eightyPercentRemainingSeconds) {
                                        setRemainingSeconds(eightyPercentRemainingSeconds)
                                    }
                                    window.api.send(sendChannels.zdm_sim_active, [installation_id])
                                }else{
                                    setResult(false)
                                    clearInterval(interval);
                                }
                            })
                        }
                    }else{
                        setResult(false)
                        clearInterval(interval);
                    }
                })
            }else{
                setResult(false)
                clearInterval(interval);
            }
    }

    const zdm_sim_active = async(result: ResultLog) => {
        console.log("SIM ACTIVE: ", result)
        await window.api.send(sendChannels.ztc_check_discover, [board, uid])
        if(checkDevice){
            if(result.result.length > 0 && !String(result.result).includes("error")){
                setPercent(90)
                if(remainingSeconds !== ninetyPercentRemainingSeconds) {
                    setRemainingSeconds(ninetyPercentRemainingSeconds)
                }
                window.api.send(sendChannels.ztc_burn, [board, uid, path])
            }else{
                setResult(false)
            }
        }else{
            setResult(false)
        }
    }

    const ztc_burn = async(result: ResultLog) => {
        console.log("BURN: ", result)
        await window.api.send(sendChannels.ztc_check_discover, [board, uid])
        if(checkDevice){
            if(result.result.length > 0 && !String(result.result).includes("error")){
                setPercent(100)
                if(remainingSeconds !== hundredPercentRemainingSeconds) {
                    setRemainingSeconds(hundredPercentRemainingSeconds)
                }
                setResult(true)
            }else{
                setResult(false)
                clearInterval(interval);
            }
        }else{
            setResult(false)
            clearInterval(interval);
        }
    }

    const ztc_check_discover = (result: ResultLog) => {
        console.log("DISCOVER: ", result)
        setCheckDevice(Boolean(result.result))
    }

    useEffect( () => {
        const firmware_download_result = window.api.receive(receiveChannels.firmware_download_result, (result: string) => firmware_download(result))
        const ztc_change_connection_result = window.api.receive(receiveChannels.ztc_change_connection_result, (result: ResultLog, lines: any) => ztc_change_connection(result, lines))
        const ztc_discover_result = window.api.receive(receiveChannels.ztc_discover_result, (devices: ResultLog) => ztc_discover(devices))
        const ztc_erase_result = window.api.receive(receiveChannels.ztc_erase_result, (result: ResultLog) => ztc_erase(result))
        const ztc_phys_id_result = window.api.receive(receiveChannels.ztc_phys_id_result, async(result: ResultLog) => ztc_phys_id(result) )
        const ztc_provision_prepare_result = window.api.receive(receiveChannels.ztc_provision_prepare_result, async(result: ResultLog) => ztc_provision_prepare(result) )
        const ztc_provision_command_result = window.api.receive(receiveChannels.ztc_provision_command_result, async(result: ResultLog) => ztc_provision_command(result) )
        const zdm_sim_active_result = window.api.receive(receiveChannels.zdm_sim_active_result, async(result: ResultLog) => zdm_sim_active(result))
        const ztc_burn_result = window.api.receive(receiveChannels.ztc_burn_result, async(result: ResultLog) => ztc_burn(result) )
        const ztc_check_discover_result = window.api.receive(receiveChannels.ztc_burn_result, (result: ResultLog) => ztc_check_discover(result))
        return () => {
            firmware_download_result()
            ztc_change_connection_result()
            ztc_discover_result()
            ztc_phys_id_result()
            ztc_provision_prepare_result()
            ztc_provision_command_result()
            zdm_sim_active_result()
            ztc_burn_result()
            ztc_check_discover_result()
            ztc_erase_result()
        }
    }, [data])

    const submit = () => {
        setResult(null)
        setPercent(0)
        setCheckDevice(true)
        form.validateFields().then(
            async values => {
                if(values.connection_type === "wifi"){
                    setData({
                        sid: values.sid,
                        password: values.password
                    })
                }
                if(values.connection_type === "gsm"){
                    setData({
                        apn: values.operator
                    })
                }
                connect()
            }
        )
    }

    if(loading){
        return null
    }

    return(
        <>
            <TitleWithBack title={connectionTitle} key={dccTitleKey}/>
            <div className="my-connection-container">
                <Form layout="vertical" key={1} name={dccFormName} form={form}>
                    <Form.Item label={select.label} name={select.name} initialValue={select.options[1].value}>
                        <Select placeholder={select.placeholder} onChange={(value: string)=>{setFormItem(value)}}>
                            {
                                select.options.map((opt, i) => {
                                    return <Option value={opt.value} key={i} disabled={opt.disabled}> {opt.label} </Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {
                        formItem === select.options[0].value ?
                        <>
                            <Form.Item label={wifi[0].label} name={wifi[0].name} rules={[ {required: true, message: wifi[0].requiredLabel} ]} >
                                <Input placeholder={wifi[0].placeholder} type={wifi[0].type} />
                            </Form.Item>
                            <Form.Item label={wifi[1].label} name={wifi[1].name} rules={[ {required: true, message: wifi[1].requiredLabel} ]} >
                                <Input placeholder={wifi[1].placeholder} type={wifi[1].type} />
                            </Form.Item>
                        </> :
                        <Form.Item label={gsm.label} rules={[ {required: true, message: gsm.requiredLabel} ]}  name={gsm.name}>
                            <Select placeholder={gsm.placeholder} onChange={(value: string)=>{setFormItem(value)}}>
                                {
                                    gsm.options.map((opt, i) => {
                                        return <Option value={opt.value} key={i}> {opt.label} </Option>
                                    })
                                }
                                </Select>
                        </Form.Item>
                    }
                    <div className="btn-container">
                        <ButtonItem
                            buttonType={primaryType}
                            label={invioLabel}
                            buttonOnClick={submit}
                            disabled={result === null && percent !== 0}
                        />
                    </div>
                </Form>
            </div>
            {
                progress ?
                    <div className="progress">
                        {waitLabel}
                        <Progress className="progress" strokeColor={{from: '#108ee9', to: '#87d068'}} percent={percent} status="active" />
                        {
                            result === false ?
                            null :
                            <Typography> {remainingTimeLabel} {secondsToTimer(remainingSeconds)}</Typography>
                        }
                        <Typography> {waitHereLabel} </Typography>
                        {
                            result === true ?
                                <Result
                                    status={resultStatus.success as ResultStatusType}
                                    title={resultTitle.success}
                                />
                            : result === false ?
                                <Result
                                    status={resultStatus.error as ResultStatusType}
                                    title={resultTitle.error}
                                />
                            : null
                        }
                    </div>
                : null
            }
        </>
    )

}

export default DeviceConnectionConfiguration;
