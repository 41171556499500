/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Button, LinearProgress, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, {useCallback, useEffect, useState} from "react";
import {checkServerPort, getInstallation} from "../../../api/services/installationsService";
import TitleWithBack from "../../TitleWithBack";
import {technicalTestingTitle} from "../title";
import {
    devicesAction,
    FileObject,
    firstPortRange,
    lastPortRange,
    maxConnectionAttempts,
    ResponseObject,
    subtitleError,
    TestObject,
    testSignal,
    titleError
} from "./costant";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import {Button as AntdButton, message, Result} from "antd";
import ResultTesting from "./ResultTesting";
import {useHistory} from "react-router";
import {deviceConnectionConfigurationUrl, selectTestsUrl} from "../costants";
import {receiveChannels, sendChannels} from "../deviceConnectionConfiguration/costant";
import {BackendUrl} from "../../../api/constants";
import {Installation} from "../../../api/requests/installationsService";
import {updateInstallationActionFirst} from "../../../costants/costants";
import "./testing.css"
import { debounceFunc } from "../../../utilities/utilities";

type TechnicalTestingProps = {
    installationId: string
    initialTests: TestObject[]
}

const TechnicalTesting: React.FC<TechnicalTestingProps> = ({ installationId, initialTests }) => {

    document.body.style.background = "#f5f5f5c7"

    const [board, setBoard] = useState<string>("")
    const [uid, setUid] = useState<string>("")
    const [loadPage, setLoadPage] = useState<boolean>(true)

    const [loadInstallatation, setLoadInstallation] = useState<boolean>(true)
    const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null)

    useEffect(() => {
        getInstallation({id: installationId}).then((res: any) => {
            if (res && res.installation) {
                setSelectedInstallation(res.installation)
            }
            setLoadInstallation(false)
        })
    }, [])

    const ztc_check_discover = (result: any) => {
        if(!result){
            message.error(titleError)
            setPortError(true)
        }else{
            setPortError(false)
        }
    }

    const [socketRef, setSocketRef] = useState<WebSocket>()
    const [devicePort, setDevicePort] = useState<string>("")
    const ztc_discover = useCallback((result: any) => {
        console.log("DISCOVER: ", result)
        const devices: any[] = result.result
        if(devices.length > 0){
            let tmp_board: string | null = null
            let tmp_uid: string | null = null
            devices.map((el) => {
                if(!board && !uid){
                    tmp_board = el.board
                    tmp_uid = el.uid
                }
            })
            if(!tmp_board || !tmp_uid){
                setPortError(true)
            }else{
                setBoard(tmp_board)
                setUid(tmp_uid)
                if(socketRef){
                    sendWebSocketCommand(devicePort, "temp")
                }
            }
        }else{
            setPortError(true)
        }
    }, [socketRef, devicePort])

    const firmware_download = (result: string) => {
        console.log(sendChannels.technical_testing)
        if(result !== ""){
            setLoadPage(false)
        }else{
            setPortError(true)
        }
    }

    const connect = useCallback(() => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            // Electron-specific code
            window.api.send(sendChannels.ztc_discover)
        }
    }, [])

    useEffect( () => {
        if(!loadInstallatation){
            const firmware_download_result = window.api.receive(receiveChannels.technical_testing_result, (result: string) => firmware_download(result))
            const ztc_discover_result = window.api.receive(receiveChannels.ztc_discover_result, (devices: any[]) => ztc_discover(devices))
            const check_discover_result = window.api.receive(receiveChannels.ztc_check_discover_result, (result: any) => ztc_check_discover(result))
            return () => {
                firmware_download_result()
                ztc_discover_result()
                check_discover_result()
            }            
        }
    }, [loadInstallatation, socketRef, devicePort])

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        return () => {
            socketRef?.close()
            clearInterval(intervalId)
        }
    }, [])

    const [portError, setPortError] = useState<boolean>(false)
    const [feedback, setFeedback] = useState<ResponseObject[]>([])
    let attempts = 0
    let intervalId: NodeJS.Timeout
    let currentPort = firstPortRange

    const connectSocket = useCallback((port: number) => {
        const socket = new WebSocket(`ws://localhost:${port}`)

        // socket connection
        socket.onopen = () => {
            console.log("Websocket connection opened ");
            setSocketRef(socket)
            listener(socket)
            let request: {[k: string]: any} = {
                action: devicesAction
            };
            socket.send(JSON.stringify(request));
        }


        // socket on close and on error
        socket.onclose = () => {
            console.log("WebSocket closed")
        }
    }, [])

    useEffect(() => {
        if(feedback.length > 0){
            if(feedback[feedback.length -1].msg.toLowerCase().includes("error")){
                setFailedTests(failedTests => [...failedTests, tests[feedback.length -1]])
            }
        }
        
    }, [feedback])

    const listener = useCallback((socket: WebSocket) => {
        let first: boolean = true
        socket.addEventListener('message', function (event) {
            try{
                const jsonMsg = JSON.parse(event.data.toString())
                console.log("RESPONSE ", jsonMsg)
                if(jsonMsg && jsonMsg.action) {
                    switch(jsonMsg.action) {
                        case devicesAction:
                            if((jsonMsg.devices as any[]).length === 1 && jsonMsg.devices[0].board === "4ZeroBoxMobile"){
                                setDevicePort(jsonMsg.devices[0].port)
                                connect()
                            }else{
                                setPortError(true)
                            }
                            break
                        case testSignal: 
                            if((event.data.toString()).includes("OK measure") && first){
                                console.log("1")
                                first = false
                                setLoadPage(false)
                                //window.api.send(sendChannels.technical_testing, [`${BackendUrl}/firmwares/tech_test?model=${selectedInstallation?.model || "e_block"}`, board, uid])
                            }else{
                                console.log((!(event.data.toString()).includes("OK measure")))
                                console.log(loadPage)
                                if((!(event.data.toString()).includes("OK measure")) && first){
                                    console.log("2")
                                    first = false
                                    window.api.send(sendChannels.technical_testing, [`${BackendUrl}/firmwares/tech_test?model=${selectedInstallation?.model || "e_block"}`, board, uid])
                                }else{
                                    console.log("3")
                                    setLoading(false)
                                    setFeedback(feedback => [...feedback, jsonMsg])
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1)                                    
                                }
                            }
                            break
                    }
                }
            }catch{
                console.log("error")
            }
        })
    }, [feedback, loadInstallatation, selectedInstallation])

    const startSocket = useCallback(() => {
        attempts = 0
        intervalId = setInterval(() => {
            checkServerPort(currentPort.toString()).then((res: {status: string}) => {
                if(res && res.status && res.status === 'ok') {
                    clearInterval(intervalId);
                    connectSocket(currentPort);
                }
            }).catch(() => {
                if (currentPort <= lastPortRange) {
                    console.log("Port: ", currentPort, " no server answer. Incrementing")
                    currentPort += 1;
                } else {
                    if (attempts < maxConnectionAttempts) {
                        console.log("Attempt n: ", attempts, " finished, restarting from port: ", firstPortRange);
                        attempts += 1;
                        currentPort = firstPortRange;
                    } else {
                        console.log("Sorry. Connection failed");
                        clearInterval(intervalId);
                    }
                }
            })
        }, 200)
    }, []);

    useEffect(() => {
        if(!loadInstallatation){
            startSocket()
        }
    }, [loadInstallatation])

    const sendWebSocketCommand = (port: string, a: string, u?: string, f?: FileObject[]) => {
        let request: {[k: string]: any} = {
            action: testSignal,
            port: port,
            value: a
        }
        if(u) {
            request = {
                ...request,devicePort
            }
        }
        if(f) {
            request = {
                ...request,
                files: f
            }
        }
        console.log("Request sent: ", request)
        socketRef?.send(JSON.stringify(request));
    }

    const [tests, setTests] = useState<TestObject[]>(initialTests)
    const [failedTests, setFailedTests] = useState<any[]>([])
    const [activeStep, setActiveStep] = useState<number>(0);
    const handleNext = useCallback((action: string) => {
        sendWebSocketCommand(devicePort, action)
        setLoading(true)
    }, [devicePort])
    const handleSkip = useCallback(() => {
        setLoading(false)
        setFeedback(feedback => [...feedback, {action: "", msg: "skip", result: "", status: ""}])
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }, [])
    const handleReset = () => {
        setActiveStep(0)
        setFeedback([])
        setTests([...failedTests])
        setFailedTests([])
    }

    const history = useHistory()

    if(portError){
        return(
            <>
                <TitleWithBack disabled={loading} title={technicalTestingTitle} key={technicalTestingTitle}/>
                <Result
                    status="error"
                    title={titleError}
                    subTitle={subtitleError}
                    extra={[
                        <AntdButton type="primary" key="reload" onClick={debounceFunc(() => {window.api.send(sendChannels.ztc_check_discover, [board, uid])})}>
                            Reload
                        </AntdButton>,
                        <AntdButton key="goback" onClick={debounceFunc(() => {history.push(selectTestsUrl(installationId))})}>
                            Go Back
                        </AntdButton>,
                      ]}
                />
            </>
        )
    }

    if(loadPage || loadInstallatation) {
        return <>
            <TitleWithBack disabled={loading} title={technicalTestingTitle} key={technicalTestingTitle}/>
            <div className="loading-container">
                <h3>We are preparing the device to run technical tests, it may take a few minutes.</h3>
                <LinearProgress color="warning" />
            </div>
        </>
    }

    return(
        <>
            <TitleWithBack disabled={loading} title={technicalTestingTitle} key={technicalTestingTitle}/>
            {
            activeStep !== tests.length ?
            <Stepper activeStep={activeStep} orientation="vertical" color="red" style={{marginRight: "5%", marginLeft: "35%"}}>
                {tests.map((step, index) => (
                    <Step key={step.command}>
                        <StepLabel 
                            optional={feedback[index]?.msg}
                            icon={
                                feedback[index]?.msg.toLowerCase().includes("error") ?
                                <CancelSharpIcon style={{color: "red"}} /> : 
                                feedback[index] && !feedback[index].msg.includes("skip") ?
                                <CheckCircleSharpIcon style={{color: "green"}} /> :
                                <Brightness1Icon style={{color: "#e85112"}} />
                            }
                        >
                            {step.label} 
                        </StepLabel>
                        <StepContent>
                            <Typography> {step.description} </Typography>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={() => {handleNext(step.command)}}
                                        sx={{ mt: 1, mr: 1 }}
                                        loading={loading}
                                        style={{backgroundColor: "#e85112"}} 
                                    >
                                        Run test
                                    </LoadingButton>
                                    <Button
                                        onClick={handleSkip}
                                        disabled={loading}
                                        sx={{ mt: 1, mr: 1 }}
                                        style={{color: !loading ? "#e85112" : "#808080"}}
                                    >
                                        Skip
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper> :
            <>
                <ResultTesting feedback={feedback} steps={tests} />
                <div className="btn-container">
                    {
                        failedTests.length > 0 ? 
                        <Button variant="contained" style={{backgroundColor: "#e85112"}} onClick={debounceFunc(handleReset)} sx={{ mt: 1, mr: 1 }}>
                            Retry failed tests
                        </Button> :
                        null
                    }     
                    <Button variant="contained" style={{backgroundColor: "#e85112"}} onClick={debounceFunc(() => history.push(selectTestsUrl(installationId)))} sx={{ mt: 1, mr: 1 }}>
                        Go Back
                    </Button>
                    <Button variant="contained" style={{backgroundColor: "#e85112"}} onClick={debounceFunc(() => history.push(deviceConnectionConfigurationUrl(installationId)))} sx={{ mt: 1, mr: 1 }}>
                        {updateInstallationActionFirst}
                    </Button>
                </div>
            </>
            }
        </>
    )
}

export default TechnicalTesting;