import "./UpdateSubscription.css"
import 'antd/dist/antd.css';
import '../../../App.css'
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Col, DatePicker, Divider, Form, Row} from 'antd';
import React, {useEffect, useState} from "react";
import {
    getSubscription,
    updateSubscriptionExpDate,
    updateSubscriptionInformation
} from "../../../api/services/subscriptionService"
import {Subscription, UpdateSubscriptionInfoRequest} from "../../../api/requests/subscription"
import {roles} from "../costants";
import moment from 'moment';
import FormInput from "../../input/FormInput";
import {countries} from "../../countries";
import FormSelect from "../../select/FormSelect";
import {updateSubscriptionTitle} from "../title";
import OperationResult from "../../OperationResult";
import { subscriptonName, colKey, createCustomerName, divider, errorStatus, expiryDate, formInputKey, invioLabel, primaryType, questionLabel, resetLabel, selectType, successStatus, textType, updateSubscriptionDivKey, updateSubscriptionRowKey, updateSubscriptionTitleKey, updateSubscriptioSummaryKey } from "../../../costants/costants";
import {companyInfo} from "../addSubscription/inputs";
import CustomerSummary from "./CustomerSummary";
import TitleWithBack from "../../TitleWithBack";


type UpdateSubscriptionProps = {
    subscriptionId: string,
    role: string
}

const UpdateSubscription: React.FC<UpdateSubscriptionProps> = ({ role, subscriptionId }) => {
    const [form] = Form.useForm();
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription|null>(null);
    const [status, setStatus] = useState<"success" | "error" | null>(null);

    const goBack = () => {
        setStatus(null)
    }

    useEffect( () => {
        getSubscription({subscription_id: subscriptionId}).then(
            (res: any) => {
                if (res && res.subscription) {
                    setSelectedSubscription(res.subscription)
                }
            }
        )
    }, [])
    
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);


	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const formItemLayout = {
        labelCol: {
            sm: {
                span: 8
            }
        },
        wrapperCol: {
            sm: {
                span: 20
            }
        }
    };

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                const req: UpdateSubscriptionInfoRequest = {
                    subscription_id: subscriptionId,
                    name: values.subscription_name,
                    company_info: {
                        name: values.company_name,
                        sdi: values.company_sdi,
                        pec: values.company_pec,
                        vat_number: values.company_vat_number,
                        phone: values.company_phone,
                        address: values.company_address,
                        city: values.company_city,
                        province: values.company_province,
                        country: values.company_country,
                        zip_code: values.company_zip_code
                    }
                }
                updateSubscriptionInformation(req).then(res => {
                    if(res && !res.err){
                        setStatus(successStatus);
                    }else{
                        setStatus(errorStatus);
                    }
                })

                if (role === roles.admin) {
                    updateSubscriptionExpDate({
                        subscription_id: subscriptionId,
                        exp_date: values.expiry_date
                    })
                }
            }
        )
    }

    if (!selectedSubscription) {
        return null
    }

    if(status) {
        return (
            <OperationResult
                onClickProp={status === errorStatus ? goBack : undefined}
                status={status}
                operation={"update"}
                entity={"subscription"}
            />
        )
    }

    return(
        <>
            <TitleWithBack title={updateSubscriptionTitle} key={updateSubscriptionTitleKey} />
            <CustomerSummary customer_id={selectedSubscription.customer_id} key={updateSubscriptioSummaryKey} />
            <div className="my-form-container my-form-container-responsive" key={updateSubscriptionDivKey}>
                <Form {...formItemLayout} key={1} form={form} name={createCustomerName}>
                    <Row gutter={24} key={updateSubscriptionRowKey}>
                        {
                            companyInfo.map((el)=>{
                                return (
                                    <Col span={(width <= 575 || height <= 815) ? 12 : 8} key={colKey(el.key)}>
                                        {
                                            el.type !== selectType ?
                                            <FormInput 
                                                key={formInputKey(el.key)} 
                                                keyValue={el.key} 
                                                name={el.name} 
                                                value={el.key === subscriptonName.key ? (selectedSubscription as any).name : (selectedSubscription as any)["company_info"][el?.path || el.key]} 
                                                placeholder={el.label} 
                                                type={el.type ?? textType} 
                                                rules={el.rules} 
                                            /> :
                                            <FormSelect 
                                                key={formInputKey(el.key)} 
                                                keyValue={el.key}
                                                placeholder={el.label} 
                                                name={el.name} 
                                                options={countries} 
                                                value={el.key === subscriptonName.key ? (selectedSubscription as any).name : (selectedSubscription as any)["company_info"][el?.path || el.key]} 
                                                rules={el.rules} 
                                            />
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {
                        role === roles.admin ?
                            <>
                                <Divider orientation="left" className={"divider"} key={divider.name}>{divider.label}</Divider>
                                <Form.Item key={expiryDate.key} name={expiryDate.key} label={expiryDate.label} initialValue={moment(selectedSubscription.expiry_date)}>
                                    <DatePicker key={expiryDate.name}/>
                                </Form.Item>
                            </>
                            : null
                    }

                    <div className="btn-container">
                        <ButtonConfItem buttonLabel={resetLabel} buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel={questionLabel} />
                        <ButtonItem buttonType={primaryType} label={invioLabel} buttonOnClick={handleSubmit} />
                    </div>
                </Form>
            </div>
        </>
    )
    
}

export default UpdateSubscription;
