import { Button, Card, Divider, Modal } from 'antd';
import React, { useEffect, useState } from "react";
import './UpdateInstallation.css'
import Meta from "antd/lib/card/Meta";
import { SettingOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';
import {  alarmConfigurationUrl, deviceConnectionConfigurationUrl, selectTestsUrl, signalConfigurationUrl, technicalTestingUrl } from '../costants';
import { technicalTesting, updateInstallationActionFirst, updateInstallationActionSecond, updateInstallationActionThird, updateInstallationCardDescription, updateInstallationCardTitle, updateInstallationError } from '../../../costants/costants';

type CardOptionProps = {
    installationId: string
}

const CardOption: React.FC<CardOptionProps> = ({ installationId }) => {
    const history = useHistory();
    const [electron, setElectron] = useState(false)

    useEffect(() => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            setElectron(true)
        }else{
            setElectron(false)
        }
    }, [])

    const logError = () => {
        return (
            Modal.error({
                title: updateInstallationError
            })
        ) 
    }

    return(
        <>
            <Card
                hoverable
                bordered
                className="card"
            >
                <Meta
                    avatar={<SettingOutlined />}
                    title={updateInstallationCardTitle}
                    description={updateInstallationCardDescription}
                />
                <Divider />
                <div className='btn-container'>
                    <Button type="primary" className="card-opt" onClick={() => electron ? history.push(deviceConnectionConfigurationUrl(installationId)): logError()}> {updateInstallationActionFirst} </Button>
                    <Button type="primary" className="card-opt" onClick={() => electron ? history.push(selectTestsUrl(installationId)): logError()}> {technicalTesting} </Button>
                    <Button type="primary" className="card-opt" onClick={() => history.push(signalConfigurationUrl(installationId))}> {updateInstallationActionSecond} </Button>
                    <Button type="primary" className="card-opt" onClick={() => history.push(alarmConfigurationUrl(installationId))}> {updateInstallationActionThird} </Button>
                </div>
            </Card>
        </>
    )
}

export default CardOption;