import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import { DeleteTwoTone } from "@ant-design/icons";
import { UserRelation } from "../../../api/requests/userService";
import "../../../App.css";
import {iconType, stringType} from "../../../utilities/utilities";
import { customerEmailRel, customerNameRel, details, disassocia, installerEmailRel, installerNameRel, no, sureQuestion, yes } from "../../../costants/costants";
import {InfoCircleOutlined} from "@ant-design/icons";
import { Popconfirm, Tooltip } from "antd";

export const installersListColumns: (rowData:Object[], SetPopupObject: (obj: any) => void, deleteRelation: (record: UserRelation) => void) => ResponsiveTableColumnType[] = (rowData, SetPopupObject, deleteRelation) => [
    {
        label: installerNameRel.label,
        name: installerNameRel.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <div className="details-container">
                <Tooltip title={details}>
                    <InfoCircleOutlined 
                        className="details-icon"
                        onClick={() => {SetPopupObject([rowData[data.rowIndex]])}} 
                    />
                </Tooltip>
                <div className="details"> 
                    <ResponsiveTableCell
                        type={stringType}
                        value={value}
                    />
                </div>
            </div>
        }
    },
    {
        label: installerEmailRel.label,
        name: installerEmailRel.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: customerNameRel.label,
        name: customerNameRel.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: customerEmailRel.label,
        name: customerEmailRel.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: disassocia,
        name: "",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={iconType}
                    value={""}
                    icon={<Popconfirm
                        title={sureQuestion}
                        onConfirm={() => deleteRelation(data.tableData[data.rowIndex])}
                        onCancel={() => {}}
                        okText={yes}
                        cancelText={no}
                    >
                        <DeleteTwoTone/>
                    </ Popconfirm>}
                />
        }
    }
]