import 'antd/dist/antd.css'
import React from "react";
import {Result} from 'antd';
import TitleWithBack from './TitleWithBack';
import { customersListPageUrl, installationsListToUpdateUrl, installersListPageUrl, mainUrl, subscriptionsListToUpdateUrl } from './pages/costants';

type OperationResultProps = {
    status: "success" | "error"
    operation: "create" | "update"
    entity: "user" | "installer" | "installation" | "subscription" | "signal"
    onClickProp?: () => void
}

const OperationResult: React.FC<OperationResultProps> = ({status, operation, entity, onClickProp}) => {
    const messages = {
        create: {
            user: {
                link: mainUrl,
                success: "User created successfully",
                error: "Error creating user"
            },
            installer: {
                link: mainUrl,
                success: "Installer created successfully",
                error: "Error creating installer"
            },
            installation: {
                link: mainUrl,
                success: "Installation created successfully",
                error: "Error creating installation"
            },
            subscription: {
                link: mainUrl,
                success: "Subscription created successfully",
                error: "Error creating subscription"
            },
            signal: {
                link: mainUrl,
                success: "Signal configuration sent successfully",
                error: "Error when configuring signals"
            }
        },
        update: {
            user: {
                link: customersListPageUrl,
                success: "User updated successfully",
                error: "Error updating user"
            },
            installer: {
                link: installersListPageUrl,
                success: "Installer updated successfully",
                error: "Error updating installer"
            },
            installation: {
                link: installationsListToUpdateUrl,
                success: "Installation updated successfully",
                error: "Error updating installation"
            },
            subscription: {
                link: subscriptionsListToUpdateUrl,
                success: "Subscription updated successfully",
                error: "Error updating subscription"
            },
            signal: {
                link: installationsListToUpdateUrl,
                success: "Signal configuration changed successfully",
                error: "Error while changing the signal configuration"
            }
        }
    }

    return(
        <>
            <TitleWithBack 
                title={""} 
                link={messages[operation][entity]["link"]} 
                onClickProp={onClickProp ? onClickProp : undefined} 
            />
            <Result
                status={status}
                title={messages[operation][entity][status]}
            />
        </>
    )

}

export default OperationResult;
