import { Checkbox, Col, Form, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Installation } from "../../../api/requests/installationsService";
import { getInstallation } from "../../../api/services/installationsService";
import { allLabel, primaryType, questionLabel, resetLabel, selectedLabel, technicalTesting } from "../../../costants/costants";
import ButtonConfItem from "../../ButtonConfItem";
import ButtonItem from "../../ButtonItem";
import TitleWithBack from "../../TitleWithBack";
import { EBlockModel, ETopPlusModel, technicalTestingUrl } from "../costants";
import { eBlockTests, eTopPlusTests, TestObject } from "./costant";

type SelectTestProps = {
    installationId: string
    selectedTests_: TestObject[]
    setSelectedTests_: React.Dispatch<React.SetStateAction<TestObject[]>>
}

const SelectTest: React.FC<SelectTestProps> = ({ installationId, selectedTests_, setSelectedTests_ }) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(true)
    const [selectedTests, setSelectedTests] = useState<TestObject[]>([])
    const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null)
    useEffect(() => {
        getInstallation({id: installationId}).then((res: any) => {
            if (res && res.installation) {
                setSelectedInstallation(res.installation)
            }
            setLoading(false)
        })
    }, [])
    
    const checkModelTests = (model: string) => {
        switch(model){
            case EBlockModel:
                return eBlockTests
            case ETopPlusModel:
                return eTopPlusTests
            default:
                return []
        }
    }

    const history = useHistory()
    const submit = () => {
        setSelectedTests_([...selectedTests])
        history.push(technicalTestingUrl(installationId))
    }
    const submitAll = () => {
        let tmp_res: TestObject[] = []
        checkModelTests(selectedInstallation?.model || "").map((test: any, index: number) => {
            tmp_res = [
                ...tmp_res,
                ...test.tests
            ]
        })
        setSelectedTests_([...tmp_res])
        history.push(technicalTestingUrl(installationId))
    }

    if(loading){
        return null
    }

    return(
        <>
            <TitleWithBack title={technicalTesting} key={technicalTesting}/>
            <div className="my-updateinstallation-container my-updateinstallation-container-responsive">
                <Typography style={{marginBottom: "10px"}}> Select signals to be tested </Typography> 
                <Form layout="vertical" key={1} form={form}>
                    <Row gutter={24}>
                        {
                            checkModelTests(selectedInstallation?.model || "").map((test: any, index: number) => {
                                return <Col span={8} key={index}>
                                    <Form.Item name={test.label}>
                                        <Checkbox
                                            onClick={(e: any) => {
                                                if(e.target.checked){
                                                    setSelectedTests([
                                                        ...selectedTests,
                                                        ...test.tests
                                                    ])
                                                }else{
                                                    let tmp_res: TestObject[] = [...selectedTests]
                                                    test.tests.map((tt: TestObject) => {
                                                        tmp_res.splice(tmp_res.indexOf(tt), 1) 
                                                    })
                                                    setSelectedTests([...tmp_res])
                                                }
                                            }}
                                        >
                                            {test.label}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            })
                        }
                    </Row>
                    <div className="btn-container">
                        <ButtonConfItem 
                            buttonLabel={resetLabel} 
                            buttonOnConfirm={() => { form.resetFields(); }} 
                            buttonOnCancel={() => {}} 
                            questionLabel={questionLabel} 
                        />
                        <ButtonItem 
                            buttonType={primaryType} 
                            label={allLabel} 
                            buttonOnClick={submitAll} 
                        />
                        <ButtonItem 
                            buttonType={primaryType} 
                            label={selectedLabel}
                            buttonOnClick={submit} 
                            disabled={selectedTests.length <= 0}
                        />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default SelectTest;