import { ChangeStatusRequest, Subscription } from "../../../api/requests/subscription";
import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import "../../../App.css";
import React from "react";
import {dateType, linkType, stringType} from "../../../utilities/utilities";
import {roles} from "../costants";
import { Button, Popconfirm, Tooltip } from "antd";
import { changeStatus } from "../../../api/services/subscriptionService";
import { acceptKey, acceptLabel, activationLabel, activationRequest, address, companyInfoLabel, deactivationLabel, deactivationRequest, declineKey, declineLabel, defaultype, enabledLabel, expiryDate, no, primaryType, sureQuestion, vat, yes, zip, details, name, companyLabel } from "../../../costants/costants";
import {InfoCircleOutlined} from "@ant-design/icons";

export const subscriptionsTableColumns: (rowData:Object[], SetPopupObject: (obj: any) => void, link: (v: string) => void, role: string, updSubState: (record: Subscription) => React.ReactNode) => ResponsiveTableColumnType[] = (
    rowData,
    SetPopupObject,
    linkFunction,
    role,
    updSubState
) => [
    {
        label: name.label,
        name: name.name,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <div className="details-container">
                <Tooltip title={details}>
                    <InfoCircleOutlined 
                        className="details-icon"
                        onClick={() => {
                            const tmp: any = rowData[data.rowIndex]
                            const objects: any[] = [
                                {
                                    name: tmp.name,
                                    status: tmp.status,
                                    created_at: tmp.created_at,
                                    expiry_date: tmp.expiry_date
                                },
                                {
                                    detail_title: "Company info",
                                    company: tmp.company_info.name,
                                    address: tmp.company_info.address,
                                    city: tmp.company_info.city,
                                    province: tmp.company_info.province,
                                    country: tmp.company_info.country,
                                    zip_code: tmp.company_info.zip_code,
                                    vat_number: tmp.company_info.vat_number,
                                    phone: tmp.company_info.phone,
                                    sdi: tmp.company_info.sdi,
                                    pec: tmp.company_info.pec
                                },
                                {
                                    detail_title: "Customer info",
                                    name: tmp.customer_name,
                                    address: tmp.customer_info.address,
                                    city: tmp.customer_info.city,
                                    province: tmp.customer_info.province,
                                    country: tmp.customer_info.country,
                                    zip_code: tmp.customer_info.zip_code,
                                    phone: tmp.customer_info.phone
                                }
                            ]
                            SetPopupObject(objects)
                        }} 
                    />
                </Tooltip>
                <div className="details"> 
                    <ResponsiveTableCell
                        type={linkType}
                        value={value}
                        link={() =>{linkFunction((rowData[data.rowIndex] as any).id)}}
                    />
                </div>
            </div>
        }
    },
    {
        label: companyLabel,
        name: `${companyInfoLabel}.${name.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: vat.label,
        name: `${companyInfoLabel}.${vat.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: address.label,
        name: `${companyInfoLabel}.${address.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <ResponsiveTableCell
                type={stringType}
                value={`${value}, ${data.tableData[data.rowIndex].company_info.city}, ${data.tableData[data.rowIndex].company_info.province}`}
            />
        }
    },
    {
        label: zip.label,
        type: stringType,
        name: `${companyInfoLabel}.${zip.name}`,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <ResponsiveTableCell
                type={stringType}
                value={value}
            />
        }
    },
    {
        label: expiryDate.label,
        name: expiryDate.key,
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => <ResponsiveTableCell
                type={dateType}
                value={data.tableData[data.rowIndex].status === enabledLabel ? value : ""}
            />
        }
    },
    ...statusIfAdmin(role, updSubState)
];

const statusIfAdmin = (role: string, updSubState: (record: Subscription) => React.ReactNode) => {
    if(role === roles.admin) {
        return [
            {
                label: "Status",
                name: "",
                options: {
                    sort: true,
                    filter: false,
                    customBodyRender: (value: string, data: any) => <div className="cell"> 
                        {updSubState(data.tableData[data.rowIndex])} 
                    </div>
                }
            },
            {
                label: "Action",
                name: "requested_status",
                options: {
                    sort: true,
                    filter: false,
                    customBodyRender: (value: string, data: any) => <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}> 
                        {
                            value === activationLabel ?
                            <p> {activationRequest} </p> :
                            value === deactivationLabel ?
                            <p> {deactivationRequest} </p> :
                            null
                        }
                        {
                            value !== "" ?
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <Popconfirm
                                    title={sureQuestion}
                                    onConfirm={() => {
                                        const request: ChangeStatusRequest = {
                                            subscription_id: data.tableData[data.rowIndex].id,
                                            response_status: acceptKey
                                        }
                                        changeStatus(request).then((res) => {
                                            if(res && res.subscription){
                                                window.location.reload()
                                            }
                                        })
                                    }}
                                    onCancel={() => {}}
                                    okText={yes}
                                    cancelText={no}
                                >
                                    <Button type={primaryType}>
                                        {acceptLabel}
                                    </Button> 
                                </Popconfirm>
                                <Popconfirm
                                    title={sureQuestion}
                                    onConfirm={() => {
                                        const request: ChangeStatusRequest = {
                                            subscription_id: data.tableData[data.rowIndex].id,
                                            response_status: declineKey
                                        }
                                        changeStatus(request).then((res) => {
                                            if(res && res.subscription){
                                                window.location.reload()
                                            }
                                        })
                                    }}
                                    onCancel={() => {}}
                                    okText={yes}
                                    cancelText={no}
                                >
                                    <Button type={defaultype}>
                                        {declineLabel}
                                    </Button> 
                                </Popconfirm>
                            </div> :
                            null
                        }
                    </div>
                }
            }
        ]
    }else{
        return []
    }
}