import { GrafanaUrl } from "../../api/constants";

export const idParam = ":id"
export const nameParam = ":name"

export const resetPasswordPageUrlResult = `/reset-result`;
export const recoverPageUrlResult = `/recover-result`
export const mainUrl = `/`;
export const profileUrl = `/profile`;
export const loginPageUrl = `/login`;
export const resetPasswordPageUrl = `/recover`;
export const recoverPageUrl = `/auth/recover/end`;
export const createInstallerPageUrl = `/installers/new`;
export const createCustomerPageUrl = `/customers/new`;
export const customersListPageUrl = `/customers`;
export const installersListPageUrl = `/installers`;
export const updateUserPageUrl = (id: string) => `/users/update/${id}`;
export const createSubscriptionPageUrl = `/subscriptions/new`;
export const installationPageUrl = (id: string) => `/installations/${id}`;
export const subscriptionsListToInstallationPageUrl = `/data/subscriptions`;
export const installationListToLineChartUrl = (id: string) => `/data/subscriptions/${id}/installations`;
export const subscriptionsListToUpdateUrl = `/subscriptions/update`;
export const updateSubscriptionUrl = (id: string) => `/subscriptions/update/${id}`;
export const subscriptionsListToDataHistoryPageUrl = `/history/subscriptions`;
export const installationListToDataHistoryUrl = (id: string) => `/history/subscriptions/${id}/installations`;
export const dataHistoryPanelUrl = (id: string) => `/history/subscriptions/installations/${id}`;
export const createInstallationUrl = `/new_installation`;
export const installersAssociationsUrl = `/subscriptions/installers`;
export const installationsListToUpdateUrl = `/subscriptions/installations`;
export const updateInstallationUrl = (id: string) => `/installations/${id}/update`;
export const alarmsPageUrl = `/alarms`;
export const deviceConnectionConfigurationUrl = (id: string) => `/installations/${id}/configuration`;
export const technicalTestingUrl = (id: string) => `/installations/${id}/testing`;
export const selectTestsUrl = (id: string) => `/installations/${id}/select`;
export const adminPanelIconsUrl = `/adminpanel`;
export const installerPanelIconsUrl = `/installerpanel`;
export const configurationAlarmsPageUrl = (id: string) => `/${id}/alarms/configuration`
export const updateAlarmPageUrl = (id: string, name: string) => `/alarms/${id}/update/${name}`
export const signalConfigurationUrl = (id: string) => `/${id}/signal/configuration`
export const updateSignalConfigurationUrl = (id: string) => `/${id}/signal/configuration/update`
export const alarmConfigurationUrl = (id: string) => `/${id}/alarm/configuration`
export const updateAlarmConfigurationUrl = (device_id: string, alarm_id: string) => `/${device_id}/alarm/${alarm_id}/configuration`

export const routes = {
    mainPage: mainUrl,
    adminPanel: adminPanelIconsUrl,
    configuratorPanel: installerPanelIconsUrl,
    login:  loginPageUrl,
    recoverStart: resetPasswordPageUrl,
    recoverEnd: recoverPageUrl,
    resetResult: resetPasswordPageUrlResult,
    recoverResult: recoverPageUrlResult,
    profile: profileUrl,
    addInstaller: createInstallerPageUrl,
    addCustomer: createCustomerPageUrl,
    installationPage: installationPageUrl(idParam),
    customersList: customersListPageUrl,
    installersList: installersListPageUrl,
    updateUser: updateUserPageUrl(idParam),
    installationsListToLineChart: installationListToLineChartUrl(idParam),
    installationsListToDataHistory: installationListToDataHistoryUrl(idParam),
    addSubscription: createSubscriptionPageUrl,
    subscriptionsListToUpdate: subscriptionsListToUpdateUrl,
    updateSubscription: updateSubscriptionUrl(idParam),
    subscriptionsListToDataHistory: subscriptionsListToDataHistoryPageUrl,
    dataHistoryPanel: dataHistoryPanelUrl(idParam),
    subscriptionsListToInstallationPage: subscriptionsListToInstallationPageUrl,
    installersAssociations: installersAssociationsUrl,
    addInstallation: createInstallationUrl,
    installationsListToUpdate: installationsListToUpdateUrl,
    updateInstallation: updateInstallationUrl(idParam),
    alarms: alarmsPageUrl,
    deviceConnectionConfiguration: deviceConnectionConfigurationUrl(idParam),
    technicalTesting: technicalTestingUrl(idParam),
    selectTests: selectTestsUrl(idParam),
    alarmsConfiguration: configurationAlarmsPageUrl(idParam),
    alarmsUpdate: updateAlarmPageUrl(idParam, nameParam),
    signalConfiguration: signalConfigurationUrl(idParam),
    updateSignalConfiguration: updateSignalConfigurationUrl(idParam),
    alarmConfiguration: alarmConfigurationUrl(idParam),
    updateAlarmConfiguration: updateAlarmConfigurationUrl(idParam, nameParam)
}

export const roles = {
  	admin: "admin",
  	installer: "installer",
  	customer: "customer"
}


export const tabList = [
    {
      	key: 'avg',
      	tab: 'Media',
    },
	{
		key: 'sum',
		tab: "Somma"
	}
];

export const lineColors = {
    sum: "#8884D8",
    avg: "#8884D8",
    min: "#008000",
    max: "#FF6347",
}

export const checkbox = [
  	{label:"avg", value:"avg"},
  	{label:"max", value:"max"},
  	{label:"min", value:"min"}
]

export const rowsPerTablePage: number = 15
export const refreshTime: string = "2m"
export const ETopPlusModel: string = "e_top_plus"
export const ETopPlusUrl: (id: string) => string = (id) => `${GrafanaUrl}/d/Q3kvCDc7z/e-top-plus?orgId=1&var-device_id=${id}&refresh=${refreshTime}&kiosk=tv`
export const EBlockModel: string = "e_block"
export const EBlockUrl: (id: string) => string = (id) => `${GrafanaUrl}/d/Q3kvCDcee/e-block?orgId=1&var-device_id=${id}&refresh=${refreshTime}&kiosk=tv`