import {
    addInstallationTitle,
    addInstallerTitle,
    addSubscriptionTitle,
    addUserTitle,
    adminPanelTitle,
    alarmsTitle,
    associationsInstallersTitle,
    ConfiguratorTitle,
    dataHistoryTitle,
    dataRealTimeTitle,
    updateInstallationTitle,
    updateInstallerTitle,
    updateSubscriptionTitle,
    updateUserTitle,
} from "../title";

import data from "./img/graph.png";
import configurator from "./img/inst.png";
import admin from "./img/admin.png";
import installer from "./img/installer.png";
import user from "./img/user.png";
import people from "./img/people.png";
import change from "./img/change.png";
import device from "./img/device.png";
import alarm from "./img/alert.png";
import history from "./img/cloud-download.png";

import {routes} from "../costants";
import { adminRole, installerRole } from "../../../costants/costants";

export type IconCard = {
    img: string
    text: string[]
    link: string[]
};

export const roleBasedIcons: (r: string) => IconCard[] = (r) => {
    if(r === installerRole ) {
        return [
            {
                img: configurator,
                text: [ConfiguratorTitle],
                link: [routes.configuratorPanel]
            }
        ]
    } else if (r === adminRole) {
        return [
            {
                img: admin,
                text: [adminPanelTitle],
                link: [routes.adminPanel]
            }
        ]
    } else {
        return []
    }
}

export const homePageIconCards: (r: string) => IconCard[] = (r) => [
    {
        img: data,
        text: [dataRealTimeTitle],
        link: [routes.subscriptionsListToInstallationPage]
    },
    {
        img: history,
        text: [dataHistoryTitle],
        link: [routes.subscriptionsListToDataHistory]
    },
    {
        img: alarm,
        text: [alarmsTitle],
        link: [routes.alarms]
    },
    ...roleBasedIcons(r)
];


export const adminPanelIconCards: IconCard[] = [
    {
        img: installer,
        text: [addInstallerTitle, updateInstallerTitle],
        link: [routes.addInstaller, routes.installersList]
    },
    {
        img: people,
        text: [associationsInstallersTitle],
        link: [routes.installersAssociations]
    },
    {
        img: change,
        text: [updateSubscriptionTitle],
        link: [routes.subscriptionsListToUpdate]
    },
    {
        img: user,
        text: [updateUserTitle],
        link: [routes.customersList]
    },
];


export const configuratorPanelIconCards: IconCard[] = [
    {
        img: user,
        text: [addUserTitle, updateUserTitle],
        link: [routes.addCustomer, routes.customersList]
    },
    {
        img: change,
        text: [addSubscriptionTitle, updateSubscriptionTitle],
        link: [routes.addSubscription, routes.subscriptionsListToUpdate]
    },
    {
        img: device,
        text: [addInstallationTitle, updateInstallationTitle],
        link: [routes.addInstallation, routes.installationsListToUpdate]
    }
];