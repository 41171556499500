import React, { useEffect, useState } from "react";
import '../../navbar/Navbar.css'
import '../../../App.css'
import {Subscription} from "../../../api/requests/subscription";
import {getSubscription, getSubscriptionInstallations} from "../../../api/services/subscriptionService";
import ResponsiveTable from "../../responsiveTable";
import { useHistory } from "react-router";
import { installationsListTitle } from "../title";
import { installationsKey, name } from "../../../costants/costants";
import { installationsListColumns } from "./inputs";
import TitleWithBack from "../../TitleWithBack";
import SubscriptionSummary from "../../subscriptionSummary/SubscriptionSummary";

type InstallationsListProps = {
    SetPopupObject: (obj: any) => void
    subscription_id: string
    redirectUrl: (id: string) => string
}

const InstallationsListSubscriptionSummary: React.FC<InstallationsListProps> = ({SetPopupObject, subscription_id, redirectUrl}) => {
    const history = useHistory();
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)
    const [data, setData] = useState<Object[]>([])
    useEffect( () => {
        getSubscription({subscription_id}).then(
            (res: any) => {
                if (res && res.subscription) {
                    setSelectedSubscription(res.subscription)
                }
            }
        )
    }, [])
    
    const redirectFun: (value: string) => void = (value: string) => {
        history.push(redirectUrl(value))
    }

    document.body.style.background = "#f5f5f5c7"
    
    return(
        <>
            <TitleWithBack title={installationsListTitle}/>
            <SubscriptionSummary subscription={selectedSubscription}/>
            <ResponsiveTable 
                columns={installationsListColumns(data, SetPopupObject, redirectFun)}
                getPageAndSortedData={getSubscriptionInstallations}
                defaultSort={name.name}
                responseDataName={installationsKey}
                id={subscription_id}
                setData={setData}
            />
        </>
    )
}

export default InstallationsListSubscriptionSummary;