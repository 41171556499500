import {installationFields} from "./inputs"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Form} from 'antd';
import React, {useEffect, useState} from "react";
import {getInstallation, UpdateInstallation as updateInstallationApi} from "../../../api/services/installationsService";
import {Installation} from '../../../api/requests/installationsService';
import './UpdateInstallation.css'
import CardOption from "./CardOption";
import FormInput from "../../input/FormInput";
import {updateInstallationTitle} from "../title";
import OperationResult from "../../OperationResult";
import { cardKey, createCustomerName, errorStatus, formInputKey, invioLabel, primaryType, questionLabel, resetLabel, selectType, successStatus, textType, updateInstallationBtnKey, updateInstallationDivKey, updateInstallationTitleKey } from "../../../costants/costants";
import FormSelect from "../../select/FormSelect";
import TitleWithBack from "../../TitleWithBack";

type UpdateInstallationProps = {
    installationId: string
}

const UpdateInstallation: React.FC<UpdateInstallationProps> = ({ installationId }) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [status, setStatus] = useState<"success" | "error" | null >(null);

    const goBack = () => {
        setStatus(null)
    }

	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null)
    useEffect(() => {
        getInstallation({id: installationId}).then((res: any) => {
            if (res && res.installation) {
                setSelectedInstallation(res.installation)
            } else {
               setStatus(errorStatus);
            }
           
        })
    }, [])

    const [form] = Form.useForm();

    const submit = () => {
        form.validateFields().then(
            values => {
                updateInstallationApi({
                    name: values.name,
                    id: selectedInstallation?.id ?? "",
                    description: values.description,
                    model: values.model
                }).then((res)=>{
                    if(res && !res.err){
                        setStatus(successStatus);
                    }else{
                        setStatus(errorStatus);
                    }
                })    
            }
        )
    }

    if(!selectedInstallation){
        return null
    }

    if(status) {
        return (
            <OperationResult
                onClickProp={status === errorStatus ? goBack : undefined}
                status={status}
                operation={"update"}
                entity={"installation"}
            />
        )
    }
    return(
        <>
            <TitleWithBack title={updateInstallationTitle} key={updateInstallationTitleKey}/>
            <div className="my-updateinstallation-container my-updateinstallation-container-responsive" key={updateInstallationDivKey}>
                <Form layout="vertical" key={1} form={form} name={createCustomerName}>
                    {
                        installationFields.map((el)=> {
                            if(el.type === selectType){
                                return <FormSelect rules={el.rules} key={formInputKey(el.key)} keyValue={el.key} name={el.name} placeholder={el.label} options={el?.options || []} value={(selectedInstallation as any)[el.key]} />
                            }else{
                                return <FormInput rules={el.rules}  key={formInputKey(el.key)} value={(selectedInstallation as any)[el.key]} keyValue={el.key} name={el.name} placeholder={el.label} type={el.type ?? textType} />
                            }
                        })
                    }
                    <div className="btn-container" key={updateInstallationBtnKey}>
                        <ButtonConfItem buttonLabel={resetLabel} buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel={questionLabel} />
                        <ButtonItem buttonType={primaryType} label={invioLabel} buttonOnClick={submit} />
                    </div>
                </Form>
            </div>
            {
                width > 575 ? 
                <div className="my-option-container">
                    <CardOption installationId={selectedInstallation.id} key={cardKey} />
                </div> :
                null
            }
        </>
    )
    
}

export default UpdateInstallation;