import React from 'react';
import {Button, Card, CardContent, Grid} from "@mui/material";
import {configuratorPanelIconCards} from "./constants";
import {useHistory} from "react-router-dom";
import "./homepage.css";
import { gridCardKey, gridKey } from '../../../costants/costants';

const ConfiguratorPanel: React.FC = () => {
    const history = useHistory();

    return (
        <Grid
            container
            justifyContent={"center"}
            spacing={2}
            style={{marginTop: "5%"}}
        >
            {
                configuratorPanelIconCards.map( (iconItem, index) =>
                    <Grid
                        className={"home-icon-card"}
                        item
                        xs={12}
                        sm={4}
                        key={gridKey(String(index))}
                    >
                        <Card
                            key={gridCardKey(String(index))}
                        >
                            <CardContent
                                className={"card-content"}
                            >
                                <img
                                    className={"card-logo"}
                                    src={iconItem.img}
                                />
                            </CardContent>
                            <div
                                className={"card-buttons"}
                            >
                                {
                                    iconItem.text.map( (buttonText, buttonIndex) =>
                                        <Button
                                            style={{textTransform: "none"}}
                                            key={buttonIndex}
                                            className={"z-button"}
                                            onClick={() => { history.push(iconItem.link[buttonIndex])}}
                                        >
                                            { buttonText }
                                        </Button>
                                    )
                                }
                            </div>
                        </Card>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default ConfiguratorPanel;