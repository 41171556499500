import "./RecoverPassword.css"
import "antd/dist/antd.css"
import { Result } from "antd"
import React, { useEffect, useState } from "react"
import ButtonItem from "../../ButtonItem"
import { useHistory } from "react-router"
import { loginPageUrl } from "../costants"
import logo from '../../../logo/logo-armal.png';
import { backgroundColorLogin, loginLabel, primaryType, recoverPasswordResultSubTitle, recoverPasswordResultTitle } from "../../../costants/costants"

type ResultRecoverPasswordProps = {
}

const ResultRecoverPassword: React.FC<ResultRecoverPasswordProps> = () => {
    document.body.style.backgroundColor = backgroundColorLogin
    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const history = useHistory()
    const submit = () => {
        history.push(loginPageUrl)
    }

    return (
        <>
            <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
                <div className="image-container">
                    <img src={logo} className="image" />
                </div>
                <Result
                    status="success"
                    title={recoverPasswordResultTitle}
                    subTitle={recoverPasswordResultSubTitle}
                    extra={[
                        <ButtonItem buttonType={primaryType} label={loginLabel} buttonOnClick={submit} />
                    ]}
                />
            </div>
        </>
    )
}

export default ResultRecoverPassword;