import {
    GetSubscriptionInstallationsUrl,
    UpdateSubscriptionExpDateUrl,
    UpdateSubscriptionInfoUrl,
    UpdateSubscriptionStatusUrl,
    SubscriptionsUrl,
    GetSubscriptionUrl,
    rowsPerTablePage,
    changeStatusUrl
} from "../constants";
import {
    ChangeStatusRequest,
    CreateSubscriptionRequest,
    GetSubscriptionRequest,
    UpdateSubscriptionExpiryRequest, UpdateSubscriptionInfoRequest, UpdateSubscriptionStatusRequest
} from "../requests/subscription";

import {isTest, methodGet, methodPost, methodPut, verticalFetch} from "./httpRequests";
import { getAllSubscriptionsMock, getSubscriptionInstallationsMock, getSubscriptionMock } from "./mocks";

//Get all subscriptions
export const getAllSubscriptions = (page: string, sort: string, search: string) => {
    if(isTest){
        return new Promise((resolve) => resolve(getAllSubscriptionsMock))
    }
    return verticalFetch(methodGet, `${SubscriptionsUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`);
}

//Create a new subscription
export const addSubscription = (r: CreateSubscriptionRequest) => {
    return verticalFetch(methodPost, SubscriptionsUrl, JSON.stringify(r));
}

//Get a subscription
export const getSubscription = (r: GetSubscriptionRequest) => {
    if(isTest){
        return new Promise((resolve) => resolve(getSubscriptionMock))
    }
    return verticalFetch(methodGet, GetSubscriptionUrl(r.subscription_id));
}

//Update subscription information
export const updateSubscriptionInformation = (r: UpdateSubscriptionInfoRequest) => {
    return verticalFetch(methodPut, UpdateSubscriptionInfoUrl(r.subscription_id), JSON.stringify(r));
}

//Update subscription status
export const updateSubscriptionStatus = (r: UpdateSubscriptionStatusRequest) => {
    return verticalFetch(methodPut, UpdateSubscriptionStatusUrl(r.subscription_id), JSON.stringify(r));
}

//Update expiration date of a subscription
export const updateSubscriptionExpDate = (r: UpdateSubscriptionExpiryRequest) => {
    return verticalFetch(methodPut, UpdateSubscriptionExpDateUrl(r.subscription_id), JSON.stringify(r));
}

//Get the installations of a subscription
export const getSubscriptionInstallations = (subscription_id: string, page: string, sort: string, search: string) => {
    if(isTest){
        return new Promise((resolve) => resolve(getSubscriptionInstallationsMock))
    }
    return verticalFetch(methodGet, GetSubscriptionInstallationsUrl(subscription_id, page, sort, search));
}

//Richiesta attivazione\disattivazione abbonamento
export const changeStatus = (request: ChangeStatusRequest) => {
    return verticalFetch(methodPost, changeStatusUrl(request.subscription_id), JSON.stringify(request))
}