import { Badge, Card } from "antd"
import { useEffect, useState } from "react"
import { batteryVoltage, clearWaterLevel, clearWaterLevelMax, clearWaterLevelMin, darkWaterLevel, darkWaterLevelMax, darkWaterLevelMin, disabledLabel, doorOpenStatus, enabledLabel, firstBathroom, flushButton, genericSignals, gpsPosition, handWashButton, interiorLightsStatus, ledFlushStatus, positioningTilt, roomTemperature, secondBathroom, solarPanelVoltage, status } from "../../../costants/costants"

type EBlockSummaryProps = {
    jobValue: any
}

const EBlockSummary: React.FC<EBlockSummaryProps> = ({ jobValue }) => {

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
		setWidth(window.innerWidth)
        setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const checkStatus = (status: boolean) => {
        if(status){
            return enabledLabel
        }
        if(!status){
            return disabledLabel
        }
        return "---"
    }

    return(
        <>
            <div className={(width <= 575 || height <= 815) ? undefined: "summary-container"}>
                <Badge.Ribbon text={genericSignals}>
                    <Card className="badge-container" style={{width: (width <= 575 || height <= 815) ? `100%` : "450px"}} size="default">
                        <div className="p-style"> 
                            <p>{roomTemperature.label}:</p> 
                            <p>{checkStatus(jobValue.temp_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{positioningTilt.label}:</p> 
                            <p>{checkStatus(jobValue.pos_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{gpsPosition.label}:</p> 
                            <p>{checkStatus(jobValue.gps_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{batteryVoltage.label}:</p> 
                            <p>{checkStatus(jobValue.batt_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{solarPanelVoltage.label}:</p> 
                            <p>{checkStatus(jobValue.solar_en) ||  "---"}</p> 
                        </div>
                    </Card>
                </Badge.Ribbon>
                <Badge.Ribbon text={firstBathroom}>
                    <Card className="badge-container" style={{width: (width <= 575 || height <= 815) ? `100%` : "450px"}} size="default">
                        <div className="p-style"> 
                            <p>{doorOpenStatus.label}:</p> 
                            <p>{checkStatus(jobValue.door1_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{interiorLightsStatus.label}:</p> 
                            <p>{checkStatus(jobValue.pir1_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{ledFlushStatus.label}:</p> 
                            <p>{checkStatus(jobValue.led1_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{flushButton.label}:</p> 
                            <p>{checkStatus(jobValue.flush1_en) ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{handWashButton.label}:</p> 
                            <p>{checkStatus(jobValue.sink1_en) ||  "---"}</p> 
                        </div>
                    </Card>
                </Badge.Ribbon>
                <Badge.Ribbon text={secondBathroom}>
                    <Card className="badge-container" style={{width: (width <= 575 || height <= 815) ? `100%` : "450px"}} size="default">
                        <div className="p-style"> 
                            <p>{doorOpenStatus.label}:</p> 
                            <p>{checkStatus(jobValue.door2_en) || "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{interiorLightsStatus.label}:</p> 
                            <p>{checkStatus(jobValue.pir2_en) || "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{ledFlushStatus.label}:</p> 
                            <p>{checkStatus(jobValue.led2_en) || "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{flushButton.label}:</p> 
                            <p>{checkStatus(jobValue.flush2_en) || "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{handWashButton.label}:</p> 
                            <p>{checkStatus(jobValue.sink2_en) || "---"}</p> 
                        </div>
                    </Card>
                </Badge.Ribbon>
            </div>
            <div className={(width <= 575 || height <= 815) ? undefined: "btn-container"}>
                <Badge.Ribbon text={clearWaterLevel.label}>
                    <Card className="badge-container" style={{width: (width <= 575 || height <= 815) ? `100%` : "450px"}} size="default">
                        <div className="p-style"> 
                            <p>{status.label}:</p> 
                            <p>{checkStatus(jobValue.cw_en) || "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{clearWaterLevelMin.label}:</p> 
                            <p>{jobValue.cw_min ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{clearWaterLevelMax.label}:</p> 
                            <p>{jobValue.cw_max ||  "---"}</p> 
                        </div>
                    </Card>
                </Badge.Ribbon>
                <Badge.Ribbon text={darkWaterLevel.label}>
                    <Card className="badge-container" style={{width: (width <= 575 || height <= 815) ? `100%` : "450px"}} size="default">
                        <div className="p-style"> 
                            <p>{status.label}:</p> 
                            <p>{checkStatus(jobValue.dw_en) || "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{darkWaterLevelMin.label}:</p> 
                            <p>{jobValue.dw_min ||  "---"}</p> 
                        </div>
                        <div className="p-style"> 
                            <p>{darkWaterLevelMax.label}:</p> 
                            <p>{jobValue.dw_max ||  "---"}</p> 
                        </div>
                    </Card>
                </Badge.Ribbon>
            </div>
        </>
    )
}
export default EBlockSummary;