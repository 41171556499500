import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ResponseObject, SummaryObject } from "./costant";
import React, { useEffect, useState } from "react";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import Brightness1Icon from '@mui/icons-material/Brightness1';

type ResultTestingProps = {
    feedback: ResponseObject[]
    steps: any[]
}

const ResultTesting: React.FC<ResultTestingProps> = ({ feedback, steps }) => {

    document.body.style.background = "#f5f5f5c7"

    const [loading, setLoading] = useState<boolean>(true)
    const [resultSummary, setResultSummary] = useState<SummaryObject>({
        success: {
            total: 0,
            feedback: [],
            steps: []
        },
        failed: {
            total: 0,
            feedback: [],
            steps: []
        },
        skipped: {
            total: 0,
            feedback: [],
            steps: []
        }
    })

    useEffect(() => {
        setLoading(true)
        let tmp_res: SummaryObject = {
            success: {
                total: 0,
                feedback: [],
                steps: []
            },
            failed: {
                total: 0,
                feedback: [],
                steps: []
            },
            skipped: {
                total: 0,
                feedback: [],
                steps: []
            }
        } 
        feedback.map((f: ResponseObject, index: number) => {
            if(f.msg.toLocaleLowerCase().includes("skip")){
                tmp_res.skipped.total = tmp_res.skipped.total + 1
                tmp_res.skipped.feedback = [...tmp_res.skipped.feedback, f]
                tmp_res.skipped.steps = [...tmp_res.skipped.steps, steps[index]]
            }else{
                if(f.msg.toLocaleLowerCase().includes("error")){
                    tmp_res.failed.total = tmp_res.failed.total + 1
                    tmp_res.failed.feedback = [...tmp_res.failed.feedback, f]
                    tmp_res.failed.steps = [...tmp_res.failed.steps, steps[index]]
                }else{
                    tmp_res.success.total = tmp_res.success.total + 1
                    tmp_res.success.feedback = [...tmp_res.success.feedback, f]
                    tmp_res.success.steps = [...tmp_res.success.steps, steps[index]]
                }
            }
        })
        setResultSummary(tmp_res)
        setLoading(false)
    }, [])

    if(loading){
        return null
    }

    return(
        <div style={{marginLeft: "10%", marginRight: "10%"}}>
            <Accordion style={{backgroundColor: "white"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="success"
                    id="success-header"
                >
                    <CheckCircleSharpIcon style={{color: "green", marginRight: "16px"}} />
                    <h3> Tests passed successfully: {resultSummary.success.total} </h3>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        resultSummary.success.steps.map((s: any, index: number) => {
                            return <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                <h3 style={{marginRight: "16px", marginBottom: "0px"}}> {s.label} </h3>
                                <Typography> {resultSummary.success.feedback[index].msg} </Typography>
                            </div>
                        })
                    }
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="failed"
                    id="failed-header"
                >
                    <CancelSharpIcon style={{color: "red", marginRight: "16px"}} /> 
                    <h3> Tests falied: {resultSummary.failed.total} </h3>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        resultSummary.failed.steps.map((s: any, index: number) => {
                            return <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                <h3 style={{marginRight: "16px", marginBottom: "0px"}}> {s.label} </h3>
                                <Typography> {resultSummary.failed.feedback[index].msg} </Typography>
                            </div>
                        })
                    }
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="skipped"
                    id="skipped-header"
                >
                    <Brightness1Icon style={{color: "#e85112", marginRight: "16px"}} />
                    <h3> Tests skipped: {resultSummary.skipped.total} </h3>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        resultSummary.skipped.steps.map((s: any, index: number) => {
                            return <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                <h3 style={{marginRight: "16px", marginBottom: "0px"}}> {s.label} </h3>
                                <Typography> {resultSummary.skipped.feedback[index].msg} </Typography>
                            </div>
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ResultTesting;