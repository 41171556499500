import { address, city, confirmPassword, country, customerAddress, customerCity, customerCountry, customerPhone, customerProvince, customerZip, email, name, numberType, password, passwordType, phone, province, selectType, zip } from "../../../costants/costants"
import { confirmPasswordRules, emailRules, passwordRules } from "../../types/form/rules"
import { InputType } from "../../types/form/types"

export const createInstallerFields: InputType[] = [
    {
        key: name.key,
        name: name.name,
        label: name.label,
        rules: [ {required: true, message: name.rules} ]
    },
    {
        key: email.key,
        name: email.name,
        label: email.label,
        rules: emailRules
    },
    {
        key: password.key,
        name: password.name,
        label: password.label,
        rules: passwordRules,
        type: passwordType
    },
    {
        key: confirmPassword.key,
        name: confirmPassword.name,
        label: confirmPassword.label,
        rules: confirmPasswordRules,
        type: passwordType
    },
    {
        key: customerPhone.key,
        name: customerPhone.name,
        label: customerPhone.label,
        path: phone.name,
        rules: [ {required: true, message: customerPhone.rules} ],
        type: numberType
    },
    {
        key: customerAddress.key,
        name: customerAddress.name,
        label: customerAddress.label,
        path: address.name,
        rules: [ {required: true, message: customerAddress.rules} ]
    },
    {
        key: customerCity.key,
        name: customerCity.name,
        label: customerCity.label,
        path: city.name,
        rules: [ {required: true, message: customerCity.rules} ]
    },
    {
        key: customerProvince.key,
        name: customerProvince.name,
        label: customerProvince.label,
        path: province.name,
        rules: [ {required: true, message: customerProvince.rules} ]
    },
    {
        key: customerCountry.key,
        name: customerCountry.name,
        label: customerCountry.label,
        path: country.name,
        rules: [ {required: true, message: customerCountry.rules} ],
        type: selectType
    },
    {
        key: customerZip.key,
        name: customerZip.name,
        label: customerZip.label,
        path: zip.name,
        rules: [ {required: true, message: customerZip.rules} ]
    }
]
