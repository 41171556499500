import {installationFields} from "./inputs"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Form, Select} from 'antd';
import React, {useEffect, useState} from "react";
import {CreateInstallation} from "../../../api/services/installationsService";
import {Subscription} from "../../../api/requests/subscription";
import {getAllSubscriptions} from '../../../api/services/subscriptionService';
import CustomTitle from "../../CustomTitle";
import './AddInstallation.css'
import {useHistory} from "react-router-dom";
import {deviceConnectionConfigurationUrl} from "../costants";
import FormInput from "../../input/FormInput";
import {addInstallationTitle} from "../title";
import OperationResult from "../../OperationResult";
import { addInstallationBtnDivKey, addInstallationDivKey, addInstallationTitleKey, errorStatus, formInputKey, formName, invioLabel, placeholder, primaryType, questionLabel, resetLabel, selectType, subscription, successStatus, textType } from "../../../costants/costants";
import FormSelect from "../../select/FormSelect";
import { isTest } from "../../../api/services/httpRequests";

const { Option } = Select;

const AddInstallation: React.FC = () => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [status, setStatus] = useState<"success" | "error" | null> ();

    const goBack = () => {
        setStatus(null)
    }

    const history = useHistory();

    useEffect( () => {
        getAllSubscriptions("-1", "", "").then((res: any) => {
            if(res && res.subscriptions){
                setSubscriptions(res.subscriptions)
            }
        })
    }, [])
    
    const [form] = Form.useForm();

    const submit = () => {
        form.validateFields().then(
            values => {
                CreateInstallation({
                    name: values.name,
                    subscription_id: values.subscription_id ?? "",
                    description: values.description,
                    model: values.model
                }).then((res)=>{
                    if(res && res.installation){
                        let userAgent = navigator.userAgent.toLowerCase();
                        if (userAgent.indexOf(' electron/') > -1) {
                            history.push(deviceConnectionConfigurationUrl(res.installation.id));
                        } else {
                            setStatus(successStatus);
                        }
                    } else {
                        setStatus(errorStatus);
                    }
                })
            }
        ).catch((e) => {
            if(isTest){
                if(e.values.subscription_id && e.values.name && e.values.model && e.values.description){
                    setStatus(successStatus)
                }else{
                    setStatus(errorStatus)
                }
            }
        })
    }

    if(status) {
        return (
            <OperationResult
                onClickProp={status === errorStatus ? goBack : undefined}
                status={status}
                operation={"create"}
                entity={"installation"}
            />
        )
    }

    return(
        <>
            <CustomTitle title={addInstallationTitle} key={addInstallationTitleKey} /> 
            <div className="my-addinstallation-container my-addinstallation-container-responsive" key={addInstallationDivKey}>
                <Form data-testid="addinstallation" layout="vertical" key={1} form={form} name={formName}>
                    <Form.Item label={subscription.label} name={subscription.name} key={formInputKey(subscription.key)}>
                        <Select data-testid={subscription.key} placeholder={placeholder} key={selectType}>
                            {
                                subscriptions.map((el, index) => {
                                    return (
                                         <Option data-testid={el.id} value={el.id} key={index}> {el.company_info.name} </Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    {
                        installationFields.map((el) => {
                            if(el.type === selectType){
                                return <FormSelect 
                                    key={formInputKey(el.key)} 
                                    placeholder={el.label} 
                                    keyValue={el.key} 
                                    name={el.name} 
                                    options={el?.options || []} 
                                    rules={el.rules} 
                                />
                            }else{
                                return <FormInput 
                                    key={formInputKey(el.key)}
                                    name={el.name} 
                                    keyValue={el.key} 
                                    placeholder={el.label} 
                                    type={el.type ?? textType}
                                    rules={el.rules}
                                />
                            }
                        })
                    }
                    <div className="btn-container" key={addInstallationBtnDivKey}>
                        <ButtonConfItem 
                            buttonLabel={resetLabel} 
                            buttonOnConfirm={() => form.resetFields()} 
                            buttonOnCancel={() => {}} 
                            questionLabel={questionLabel} 
                        />
                        <ButtonItem 
                            buttonType={primaryType} 
                            label={invioLabel} 
                            buttonOnClick={submit} 
                        />
                    </div>
                </Form>
            </div>
        </>
    )
    
}

export default AddInstallation;
