import './summary.css'
import React from "react";
import {Descriptions, Tag} from "antd";
import {Subscription} from "../../api/requests/subscription";
import { localeDateFormatter } from "../../utilities/utilities";
import { address, companyName, disabledLabel, enabledLabel, expiryDate, status, subscriptionOverview, subscriptonName, vat } from "../../costants/costants";

type SubscriptionSummaryProps = {
    subscription: Subscription | null
}

const SubscriptionSummary: React.FC<SubscriptionSummaryProps> = ({subscription}) => {

    const tagColor = (status: string) => {
        if(status === enabledLabel){
            return "green"
        }
        if(status === disabledLabel){
            return "red"
        }
        return "orange"
    }

    if (subscription) {
        return (
            <div className="description">
                <Descriptions title={subscriptionOverview} labelStyle={{fontWeight: "bold"}} bordered={true} size={"small"}>
                    <Descriptions.Item label={subscriptonName.label}> {(subscription).name} </Descriptions.Item>
                    <Descriptions.Item label={expiryDate.label}> {localeDateFormatter(String((subscription).expiry_date))} </Descriptions.Item>
                    <Descriptions.Item label={status.label}> <Tag color={tagColor((subscription).status)}> {(subscription).status} </Tag> </Descriptions.Item>
                    <Descriptions.Item label={companyName.label}> {(subscription).company_info.name}  </Descriptions.Item>
                    <Descriptions.Item label={vat.label}> {(subscription).company_info.vat_number} </Descriptions.Item>
                    <Descriptions.Item label={address.label}> {(subscription).company_info.address}, {(subscription).company_info.city} </Descriptions.Item>
                </Descriptions>
            </div>
        )
    } else {
        return null
    }
}

export default SubscriptionSummary;