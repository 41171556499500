import { tableColumn } from './tableColumns'
import './SubscriptionsListTable.css'
import '../../../App.css'
import { getAllSubscriptions } from '../../../api/services/subscriptionService'
import React, { useState } from "react";
import CustomTitle from "../../CustomTitle";
import {useHistory} from "react-router-dom";
import ResponsiveTable from '../../responsiveTable';
import { subscriptionsListTitle } from '../title';
import { expiryDate, subscriptionsLabel } from '../../../costants/costants';

type SubscriptionTableProps = {
    SetPopupObject: (obj: any) => void
    link: (p: string) => string
}

const SubscriptionsListTable: React.FC<SubscriptionTableProps> = ({SetPopupObject, link}) => {

    document.body.style.background = "#f5f5f5c7"
    const [data, setData] = useState<Object[]>([])
    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(link(value))
    }
    
    return(
        <>
            <CustomTitle title={subscriptionsListTitle} />
            <ResponsiveTable 
                columns={tableColumn(data, SetPopupObject, linkFunction)} 
                getPageAndSortedData={getAllSubscriptions}
                defaultSort={expiryDate.key}
                responseDataName={subscriptionsLabel}
                setData={setData}
            />
        </>
    )
}

export default SubscriptionsListTable;