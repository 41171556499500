import {MenuType} from "../types/navbar/types";
import {alarmsPageUrl, subscriptionsListToDataHistoryPageUrl, subscriptionsListToInstallationPageUrl} from "../pages/costants";
import {alarmsTitle, dataHistoryTitle, dataRealTimeTitle} from "../pages/title";
import dataRealTime from "../pages/homePages/img/graph_black.png";
import alarm from "../pages/homePages/img/alert_black.png";
import {alarmsKey, customerKey, historyKey} from "../../costants/costants";
import dataHistory from "../pages/homePages/img/cloud-download_black.png";

export const customerMenuItems: MenuType[] = [
    {   
        key: customerKey, 
        label: dataRealTimeTitle,
        icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToInstallationPageUrl
    },
    {
        key: historyKey,
        label: dataHistoryTitle,
        icon: <img alt={"--"} src={dataHistory} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToDataHistoryPageUrl
    },
    {   
        key: alarmsKey, 
        label: alarmsTitle,
        link: alarmsPageUrl,
        icon: <img alt={"--"} src={alarm} className={"menu-icon"}/>,
        elements: []
    }
]