import {message, Popconfirm, Switch} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import { Subscription } from "../../../api/requests/subscription";
import {getAllSubscriptions, updateSubscriptionStatus} from "../../../api/services/subscriptionService";
import '../../../App.css'
import { updateSubscriptionUrl} from "../costants";
import CustomTitle from "../../CustomTitle";
import './SelectSubscription.css'
import {useHistory} from "react-router-dom";
import ResponsiveTable from "../../responsiveTable";
import {subscriptionsTableColumns} from "./inputs";
import { updateSubscriptionTitle } from "../title";
import { disabledLabel, enabledLabel, no, operationFailed, sureQuestion, yes } from "../../../costants/costants";

type SelectSubscriptionPanelProps = {
    SetPopupObject: (obj: any) => void
    role: string,
}

const SelectSubscription: React.FC<SelectSubscriptionPanelProps> = ({ SetPopupObject, role }) => {

    document.body.style.background = "#f5f5f5c7"
    
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([])

    const history = useHistory();

    const getSubscriptions = useCallback( () => {
        getAllSubscriptions("-1", "", "").then((res: any) => {
            if(res && res.subscriptions){
                const subscriptionsWithKey = res.subscriptions.map((item: Subscription, index: number) => {return {...item, key: index}})
                setSubscriptions(subscriptionsWithKey)
            }
        })
    }, [])

    useEffect(() => {
        getSubscriptions()
    }, [])

    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateSubscriptionUrl(value))
    }

    const updateState = (record: Subscription) => {
        return <div className="button">
            <Popconfirm 
                title={sureQuestion}
                onConfirm={
                    () => {
                        updateSubscriptionStatus({subscription_id: record.id, status: record.status === enabledLabel ? disabledLabel : enabledLabel}).then(
                            res => {
                                if (res && res.result && !res.error) {
                                    message.success(res.result, 4)
                                    getSubscriptions();
                                } else {
                                    message.error(res?.error || operationFailed, 4)
                                }
                            }
                        )
                    }
                } 
                onCancel={()=>{}}
                okText={yes}
                cancelText={no}
            >
                <Switch
                    size={"small"}
                    checked={record?.status === enabledLabel}
                />
            </Popconfirm>
        </div>
    }

    return(
        <>
            <CustomTitle title={updateSubscriptionTitle} />
            <ResponsiveTable
                columns={subscriptionsTableColumns(subscriptions, SetPopupObject, linkFunction, role, updateState)}
                data={subscriptions}
            />
        </>
    )
    
}

export default SelectSubscription;