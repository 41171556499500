import { address, city, country, email, name, phone, province, selectType, zip } from "../../../costants/costants"
import { numberType } from "../../../utilities/utilities"
import { emailRules } from "../../types/form/rules"
import { InputType } from "../../types/form/types"

export const userFields: InputType[] = [
    {
        key: name.key,
        name: name.name,
        label: name.label,
        rules: [ {required: true, message: name.rules} ]
    },
    {
        key: email.key,
        name: email.name,
        label: email.label,
        rules: emailRules
    },
    {
        key: phone.key,
        name: phone.name,
        label: phone.label,
        path: phone.name,
        rules: [ {required: true, message: phone.rules} ],
        type: numberType
    },
    {
        key: address.key,
        name: address.name,
        label: address.label,
        path: address.name,
        rules: [ {required: true, message: address.rules} ]

    },
    {
        key: city.key,
        name: city.name,
        label: city.label,
        path: city.name,
        rules: [ {required: true, message: city.rules} ]

    },
    {
        key: province.key,
        name: province.name,
        label: province.label,
        path: province.name,
        rules: [ {required: true, message: province.rules} ]

    },
    {
        key: country.key,
        name: country.name,
        label: country.label,
        path: country.name,
        rules: [ {required: true, message: country.rules} ],
        type: selectType
    },
    {
        key: zip.key,
        name: zip.name,
        label: zip.label,
        path: zip.name,
        rules: [ {required: true, message: zip.rules} ]
    }
]
