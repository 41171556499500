import React from "react";
import 'antd/dist/antd.css';
import { Button } from 'antd';
import { debounceFunc } from '../utilities/utilities';
import { SizeType } from 'antd/es/config-provider/SizeContext';

type ButtonProps = {
    buttonType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed",
    label: string,
    buttonOnClick: any
    disabled?: boolean
    size?: SizeType
}

const ButtonItem: React.FC<ButtonProps> = ({buttonType, label, buttonOnClick, disabled, size}) => {
    return(
        <Button 
            id={label}
            data-testid={label}
            disabled={disabled ? disabled : undefined}
            htmlType="submit"
            size={size ? size : "large"}
            type={buttonType ?? "default"}
            onClick={debounceFunc(buttonOnClick)}
        >
            {label}
        </Button>
    )
}

export default ButtonItem;