import '../../App.css';
import clsx from 'clsx';
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {
    dataHistoryPanelUrl,
    installationListToDataHistoryUrl,
    installationListToLineChartUrl,
    installationPageUrl,
    routes
} from "./costants";
import Profile from "./profile/Profile";
import {GetUserProfile} from "../../api/services/userService";
import SignalConfiguration from './signalConfiguration/signalConfiguration';
import {useMediaQuery} from '@mui/material';
import {useStyles} from './muiStyle';
import AdminPanel from "./homePages/adminPanel";
import ConfiguratorPanel from "./homePages/configuratorPanel";
import SignalConfigurationTable from './signalConfiguration/signalConfigurationTable';
import { useTheme } from '@mui/material/styles';
import HomePage from './homePages/HomePage';
import CreateInstaller from './createInstaller/createInstaller';
import CreateCustomer from './createCustomer/createCustomer';
import AddSubscription from './addSubscription/AddSubscription';
import AlarmTable from './alarms/AlarmTable';
import AddInstallation from './addInstallation/AddInstallation';
import InstallerCustomerRelationsPage from './tab_admin_subscription/InstallerCustomerRelationsPage';
import Navbar from '../navbar/Navbar';
import DeviceConnectionConfiguration from './deviceConnectionConfiguration/DeviceConnectionConfiguration';
import DataHistoryPanel from './dataExportDownload/DataHistoryPanel';
import UpdateSubscription from './updateSubscription/UpdateSubscription';
import UpdateInstallation from './updateInstallation/UpdateInstallation';
import InstallationPage from './installation/InstallationPage';
import UpdateUser from './updateUser/UpdateUser';
import InstallationsListSubscriptionSummary from './installationsList/InstallationsListSubscriptionSummary';
import SubscriptionsListTable from './subscriptionsList/SubscriptionsListTable';
import HeaderItem from '../header/Header';
import InstallersListTable from './installersList/InstallersListTable';
import UsersListTable from './usersList/UsersListTable';
import InstallationsListPage from './installationsList/InstallationsListPage';
import Details from './Details/Details';
import SelectSubscription from './select_subscription/SelectSubscription';
import TechnicalTesting from './technicalTesting/TechnicalTesting';
import SelectTest from './technicalTesting/SelectTest';
import { TestObject } from './technicalTesting/costant';
import AlarmConfiguration from './alarmConfiguration/AlarmConfiguration';
import UpdateAlarmConfiguration from './alarmConfiguration/UpdateAlarm';

const MainPage: React.FC = () => {

    document.body.style.backgroundColor = "white"
    const history = useHistory();
    
	const [role, setRole] = useState<string>("")
    const [navbarCollapsed, setNavbarCollapsed] = useState<boolean>(true)
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
    const [popubObject, setPopupObject] = useState<Object[]>([])
    const handleClose = () => setIsPopupOpen(false)
    const handleOpen = (objects: Object[]) => {
        setPopupObject(objects)
        setIsPopupOpen(true)
    }
    
    const InstallationsListToData = (props: any) => {
        const {id} = props.match.params;
        return <InstallationsListSubscriptionSummary
            subscription_id={id}
            redirectUrl={installationPageUrl} 
            SetPopupObject={handleOpen}
        />
    }
    
    const InstallationsListToHistory = (props: any) => {
        const {id} = props.match.params;
        return <InstallationsListSubscriptionSummary
            subscription_id={id}
            redirectUrl={dataHistoryPanelUrl} 
            SetPopupObject={handleOpen}
        />
    }
    
    const InstallationPageView = (props: any) => {
        const {id} = props.match.params;
        return <InstallationPage installation_id={id}/>
    }
    
    const UpdateInstallationView = (props: any) => {
        const {id} = props.match.params;
        return <UpdateInstallation installationId={id} />
    }
    
    const UpdateSubscriptionView = (props: any) => {
        const {id} = props.match.params;
        return <UpdateSubscription subscriptionId={id} role={role} />
    }
    
    const SubListToUpdateView = () => {
        return <SelectSubscription SetPopupObject={handleOpen} role={role} />
    }
    
    const SubListToInstallationView = () => {
        return <SubscriptionsListTable link={installationListToLineChartUrl} SetPopupObject={handleOpen} />
    }
    
    const SubListToDataHistory = () => {
        return <SubscriptionsListTable link={installationListToDataHistoryUrl} SetPopupObject={handleOpen} />
    }
    
    const DataHistoryView = (props: any) => {
        const {id} = props.match.params;
        return <DataHistoryPanel installation_id={id}/>
    }
    
    const UpdateUserView = (props:any) => {
        const {id} = props.match.params;
        return <UpdateUser user_id={id} />
    }
    
    const SignalConfigurationView = (props: any) => {
        const {id} = props.match.params;
        return <SignalConfigurationTable installation_id={id} />
    }

    const AlarmConfigurationView = (props: any) => {
        const {id} = props.match.params;
        return <AlarmConfiguration installation_id={id} />
    }
    
    const UpdateSignalConfigurationView = (props: any) => {
        const {id} = props.match.params;
        return <SignalConfiguration installation_id={id} />
    }
    
    const DeviceConnectionConfigurationView = (props: any) => {
        const {id} = props.match.params;
        return <DeviceConnectionConfiguration installation_id={id} />
    }

    const ProfileView = () => {
        return <Profile SetPopupObject={handleOpen} />
    }

    const InstallersListTableView = () => {
        return <InstallersListTable SetPopupObject={handleOpen} />
    }

    const InstallationsListPageView = () => {
        return <InstallationsListPage SetPopupObject={handleOpen} />
    }

    const InstallerCustomerRelationsPageView = () => {
        return <InstallerCustomerRelationsPage SetPopupObject={handleOpen}  />
    }

    const UsersListTableView = () => {
        return <UsersListTable SetPopupObject={handleOpen} />
    }

    const [selectedTests, setSelectedTests] = useState<TestObject[]>([])

    const SelectTestView = (props: any) => {
        const {id} = props.match.params;
        return <SelectTest installationId={id} setSelectedTests_={setSelectedTests} selectedTests_={selectedTests} />
    }

    const TechnicalTestingView = (props: any) => {
        const {id} = props.match.params;
        return <TechnicalTesting installationId={id} initialTests={selectedTests} />
    }

    const UpdateAlarmConfigurationView = (props: any) => {
        const {id} = props.match.params;
        const {name} = props.match.params;
        return <UpdateAlarmConfiguration installation_id={id} alarm_id={name} />
    }

    useEffect(() => {
        GetUserProfile().then((res: any) => {
            if (!res || !res.user) {
                history.push("/login");
            }else{
                setRole(res.user.roles[0])
            }
        })
    }, []);
    
    const classes = useStyles();
    const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <> 
            <Navbar 
                SetNavbarIsCollapsed={setNavbarCollapsed} 
                navbar_collapsed={navbarCollapsed} 
            />
            <HeaderItem 
                SetNavbarIsCollapsed={setNavbarCollapsed} 
                navbar_collapsed={navbarCollapsed}
            /> 
            <div
                className={
                    isMobile ?
                        clsx(classes.content) :
                        clsx(classes.content, {[classes.contentShift]: true})
                }
            >        
                <Switch>
                    <Route exact path={routes.mainPage} component={HomePage} />
                    <Route exact path={routes.technicalTesting} component={TechnicalTestingView} />
                    <Route exact path={routes.selectTests} component={SelectTestView} />
                    <Route exact path={routes.profile} component={ProfileView} />
                    <Route exact path={routes.adminPanel} component={AdminPanel} />
                    <Route exact path={routes.configuratorPanel} component={ConfiguratorPanel} />
                    <Route exact path={routes.addInstaller} component={CreateInstaller} />
                    <Route exact path={routes.addCustomer} component={CreateCustomer} />
                    <Route exact path={routes.installationPage} component={InstallationPageView} />
                    <Route exact path={routes.customersList} component={UsersListTableView} />
                    <Route exact path={routes.updateUser} component={UpdateUserView} />
                    <Route exact path={routes.installersList} component={InstallersListTableView} />
                    <Route exact path={routes.installationsListToLineChart} component={InstallationsListToData} />
                    <Route exact path={routes.installationsListToDataHistory} component={InstallationsListToHistory} />
                    <Route exact path={routes.addSubscription} component={AddSubscription} />
                    <Route exact path={routes.subscriptionsListToUpdate} component={SubListToUpdateView} />
                    <Route exact path={routes.updateSubscription} component={UpdateSubscriptionView} />
                    <Route exact path={routes.subscriptionsListToInstallationPage} component={SubListToInstallationView} />
                    <Route exact path={routes.subscriptionsListToDataHistory} component={SubListToDataHistory} />
                    <Route exact path={routes.installersAssociations} component={InstallerCustomerRelationsPageView} />
                    <Route exact path={routes.addInstallation} component={AddInstallation} />
                    <Route exact path={routes.installationsListToUpdate} component={InstallationsListPageView} />
                    <Route exact path={routes.updateInstallation} component={UpdateInstallationView} />
                    <Route exact path={routes.alarms} component={AlarmTable} />
                    <Route exact path={routes.dataHistoryPanel} component={DataHistoryView} />
                    <Route exact path={routes.deviceConnectionConfiguration} component={DeviceConnectionConfigurationView} />
                    <Route exact path={routes.signalConfiguration} component={SignalConfigurationView} />
                    <Route exact path={routes.updateSignalConfiguration} component={UpdateSignalConfigurationView} />
                    <Route exact path={routes.alarmConfiguration} component={AlarmConfigurationView} />
                    <Route exact path={routes.updateAlarmConfiguration} component={UpdateAlarmConfigurationView} />
                </Switch>
                <Details 
                    ClosePopup={handleClose} 
                    object={popubObject} 
                    open={isPopupOpen} 
                />
            </div>
        </>
    )
}
 
export default MainPage;