import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import { Col, Form, Row } from 'antd';
import {GetUser, UpdateUser as ApiUpdateUser} from "../../../api/services/userService"
import React, {useEffect, useState} from "react";
import '../../navbar/Navbar.css'
import '../../../App.css'
import { User } from "../../../api/requests/userService";
import { userFields } from "./inputs";
import './UpdateUser.css'
import FormInput from "../../input/FormInput";
import { countries } from "../../countries";
import FormSelect from "../../select/FormSelect";
import { updateInstallerTitle, updateUserTitle } from "../title";
import OperationResult from "../../OperationResult";
import { createCustomerKey, createCustomerName, errorStatus, formInputKey, installerRole, invioLabel, primaryType, questionLabel, resetLabel, selectType, successStatus, textType, updateUserBtnDivKey, updateUserDivKey } from "../../../costants/costants";
import TitleWithBack from "../../TitleWithBack";

type UpdateUserProps = {
    user_id: string,
}

const UpdateUser: React.FC<UpdateUserProps> = ({user_id}) => {
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
    const [status, setStatus] = useState<"success" | "error" | null> (null);

    const goBack = () => {
        setStatus(null)
    }

	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const [form] = Form.useForm();

    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    useEffect( () => {
        GetUser({user_id: user_id}).then((res: any) => {
            if(res && !res.err){
                setSelectedUser(res.user)
            }
        })
    }, [])

    const handleSubmit = () => {
        if(selectedUser){
            form.validateFields().then(
                values => {
                    ApiUpdateUser({
                        user_id: selectedUser?.uid || "",
                        name: values.name,
                        email: values.email,
                        user_info: {
                            phone: values.phone,
                            address: values.address,
                            city: values.city,
                            province: values.province,
                            country: values.country,
                            zip_code: values.zip_code
                        }
                    }).then(res => {
                        if (res && res.result) {
                            setStatus(successStatus);
                        } else {
                            setStatus(errorStatus);
                        }
                    })
                }
            )
        }
    }

    if(!selectedUser){
        return null
    }

    const formItemLayout = {
        labelCol: {
            sm: {
                span: 8
            }
        },
        wrapperCol: {
            sm: {
                span: 20
            }
        }
    };

    if(status) {
        return (
            <OperationResult
                onClickProp={status === errorStatus ? goBack : undefined}
                status={status}
                operation={"update"}
                entity={selectedUser.roles.includes(installerRole) ? "installer" : "user"}
            />
        )
    }

    return(
        <>
            <TitleWithBack title={selectedUser.roles.includes(installerRole) ? updateInstallerTitle : updateUserTitle} key={updateUserTitle}/>
            <div className="my-updateuser-container my-updateuser-container-responsive" key={updateUserDivKey}>
                <Form {...formItemLayout} layout="vertical" key={1} form={form} name={createCustomerName}>
                    <Row gutter={24} key={createCustomerKey}>
                        {
                            userFields.map((el, index) => {
                                return (
                                    <Col span={(width <= 575 || height <= 815) ? 12 : 8} key={index}>
                                        {
                                            el.type !== selectType ?
                                            <FormInput rules={el.rules} key={formInputKey(el.key)} keyValue={el.key} name={el.name} placeholder={el.label} type={el.type ?? textType} value={(selectedUser as any)[el.key] || (selectedUser as any)["user_info"][el.key]} /> :
                                            <FormSelect rules={el.rules} key={formInputKey(el.key)} placeholder={el.label} keyValue={el.key} name={el.name} options={countries} value={(selectedUser as any)[el.key] || (selectedUser as any)["user_info"][el.key]} />
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <div className="btn-container" key={updateUserBtnDivKey}>
                        <ButtonConfItem
                            buttonLabel={resetLabel}
                            buttonOnConfirm={() => { form.resetFields(); }}
                            buttonOnCancel={() => {}}
                            questionLabel={questionLabel}
                        /> 
                        <ButtonItem
                            buttonType={primaryType}
                            label={invioLabel}
                            buttonOnClick={handleSubmit}
                        />
                    </div>
                </Form>
            </div>
        </>
    )

}

export default UpdateUser;