import React, { useEffect, useState } from "react"
import { Descriptions, Tag } from "antd"
import { GetUserProfile } from "../../../api/services/userService"
import { UserType } from "../../types/profile/types"
import {useHistory} from 'react-router-dom'
import moment from "moment"
import './Profile.css'
import { roles } from "../costants"
import { address, blueColor, city, country, createdAt, email, name, phone, province, ruoloLabel, userDataLabel, zip } from "../../../costants/costants"
import SubscriptionManagement from "../subscriptionSManagement/SubscriptionManagement"

type ProfileProps = {
    SetPopupObject: (objects: Object[]) => void
}

const Profile: React.FC<ProfileProps> = ({SetPopupObject}) => {
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [heigth, setHeigth] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeigth(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const [profileItem, setProfileItem] = useState<UserType | null> (null)
    
    const history = useHistory();
    useEffect(() => {
        GetUserProfile().then((res: any) => {
            if(res && res.user && res.user.roles && res.user.roles.length > 0){
				setProfileItem({
                    ...profileItem, 
                    uid: res["user"]["uid"],
                    name: res["user"]["name"],
                    email: res["user"]["email"],
                    role: res["user"]["roles"][0],
                    user_info: {
                        phone: res["user"]["user_info"]["phone"],
                        address: res["user"]["user_info"]["address"],
                        city: res["user"]["user_info"]["city"],
                        province: res["user"]["user_info"]["province"],
                        country: res["user"]["user_info"]["country"],
                        zip_code: res["user"]["user_info"]["zip_code"]
                    },
                    created_at: res["user"]["created_at"]
                })			
			}else{
                history.push("/");
            }
		})
    }, [])

    const dateFormatter = (date: string) => {
        return moment(date).format('DD/MM/YYYY HH:mm'); 
    };


    if(profileItem) {
        return (
            <>
                <div className={"profileDescriptionContainer"}>
                    <Descriptions 
                        className={"profileDescription profileDescriptionResponsive"} 
                        title={userDataLabel}
                        column={width < 575 || heigth < 815 ? 1 : 2} 
                        bordered 
                    >
                        <Descriptions.Item label={name.label}> {profileItem.name} </Descriptions.Item>
                        <Descriptions.Item label={email.label}> {profileItem.email} </Descriptions.Item>
                        <Descriptions.Item label={ruoloLabel}> <Tag color={blueColor}> {profileItem.role} </Tag> </Descriptions.Item>
                        <Descriptions.Item label={phone.label}> {profileItem.user_info.phone} </Descriptions.Item>
                        <Descriptions.Item label={address.label}> {profileItem.user_info.address} </Descriptions.Item>
                        <Descriptions.Item label={city.label}> {profileItem.user_info.city} </Descriptions.Item>
                        <Descriptions.Item label={province.label}> {profileItem.user_info.province} </Descriptions.Item>
                        <Descriptions.Item label={country.label}> {profileItem.user_info.country} </Descriptions.Item>
                        <Descriptions.Item label={zip.label}> {profileItem.user_info.zip_code} </Descriptions.Item>
                        <Descriptions.Item label={createdAt.label}> {dateFormatter(profileItem.created_at)} </Descriptions.Item>
                    </Descriptions>
                </div>
                {
                    profileItem.role === roles.customer ?
                    <SubscriptionManagement SetPopupObject={SetPopupObject} /> :
                    null
                }
            </>
        )
    } else return null

}

export default Profile;