import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { Descriptions } from "antd"
import React from "react"
import { details } from "../../../costants/costants"
import { debounceFunc, localeDateFormatter } from "../../../utilities/utilities"

type DetailsProps = {
    ClosePopup: () => void
    object: Object[]
    open: boolean
}

const Details: React.FC<DetailsProps> = ({ ClosePopup, object, open }) => {

    const checkType = (value: any) => {
        if(!isNaN(new Date(value).getDate()) && String(value).endsWith("Z")){
            return localeDateFormatter(value) 
        }else{
            return String(value)
        }
    }

    const labelFormatter = (label: string) => {
        return label.charAt(0).toUpperCase() + label.slice(1).split("_").join(" ")
    }

    return(
        <Dialog
            data-testid="details"
            onClose={ClosePopup}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
        >
            <DialogTitle id="customized-dialog-title">
                {details}
            </DialogTitle>
            <DialogContent> {
                (object).map((obj: Object, index: number) => {
                    return <Descriptions
                        key={index}
                        style={{marginBottom: "8%"}}
                        title={obj.hasOwnProperty("detail_title") ? (obj as any).detail_title : undefined}
                        column={1} 
                        bordered 
                    >
                        {
                            Object.keys(obj).map((k: string) => {
                                if(!k.toLowerCase().includes("id") && !k.toLowerCase().includes("requested_status") && !k.toLowerCase().includes("detail_title")){
                                    if(typeof((obj as any)[k]) !== "string"){
                                        if((obj as any)[k] && Object.keys((obj as any)[k]).length > 0){
                                            if( Array.isArray((obj as any)[k])){
                                                return <Descriptions.Item key={k} label={labelFormatter(k)}> {((obj as any)[k] as any[]).join(", ")} </Descriptions.Item>
                                            }else{
                                                return Object.keys((obj as any)[k]).map((k2) => {
                                                    return <Descriptions.Item key={k2} label={labelFormatter(k2)}> {checkType((obj as any)[k][k2])} </Descriptions.Item>
                                                })                                                     
                                            }
                                        }else{
                                            return <Descriptions.Item key={k} label={labelFormatter(k)}>  </Descriptions.Item>
                                        }
                                    }else{
                                        return <Descriptions.Item key={k} label={labelFormatter(k)}> {checkType((obj as any)[k])} </Descriptions.Item>
                                    }                                
                                }
                            })
                            
                        }
                    </Descriptions>
                })
            }        
            </DialogContent>
            <DialogActions>
                <Button data-testid="close" color="inherit" onClick={debounceFunc(ClosePopup)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Details;