import React, { useEffect, useState } from "react";
import './signalConfiguration.css'
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Col, Divider, Form, Row, Switch} from 'antd';
import {signalConfigurationTitle} from "../title";
import FormInput from "../../input/FormInput";
import { Installation, JobRequest } from "../../../api/requests/installationsService";
import OperationResult from "../../OperationResult";
import { radioType } from "../../../utilities/utilities";
import { getInstallation, getJob, setJob } from "../../../api/services/installationsService";
import { batteryVoltage, calibrationLabel, clearWaterLevel, clearWaterLevelMax, clearWaterLevelMin, darkWaterLevel, darkWaterLevelMax, darkWaterLevelMin, divKey, doorOpenStatus, errorStatus, flushButton, formInputKey, gpsPosition, handWashButton, htmlForName, interiorLightsStatus, invioLabel, jobPanelLabel, labelKey, ledFlushStatus, neverDone, positioningTilt, primaryType, questionLabel, resetLabel, roomTemperature, signalConfigurationBtnDivKey, signalConfigurationDivKey, signalConfigurationTitleKey, solarPanelVoltage, successStatus } from "../../../costants/costants";
import TitleWithBack from "../../TitleWithBack";
import { modelJob } from "./costant";

type SignalConfigurationProps = {
    installation_id: string
}

const SignalConfiguration: React.FC<SignalConfigurationProps> = ({ installation_id }) => {

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const [loading, setLoading] = useState<boolean>(true)
    const [installation, setInstallation] = useState<Installation | null>(null)
    const [status, setStatus] = useState<"success" | "error" | null >(null);
    const [initialJob, setInitialJob] = useState({
        config: {
            pwr_ratio: "",          //consumo potenza
            pwr_rbias: "",          //consumo potenza
            pwr_threshold: "",      //consumo potenza
            spd_en: "",             //velocita volano
            spd_min: "",            //velocita volano
            spd_max: "",            //velocita volano
            drag_en: "",            //velocita trascinamento
            am_en: ""               //automatico manuale
        }, 
        installation_id: "",
        last_set_status: neverDone,
    })
    const [disabled, setDisabled] =useState<boolean[]>([true, false, false, false])
    useEffect(() => {
        getJob(installation_id, calibrationLabel).then((res: any) => {
            if(res && res.calibration){
                setInitialJob(res.calibration)
            }
            getInstallation({id: installation_id}).then((res: any) => {
                if (res && res.installation) {
                    setInstallation(res.installation)
                }
                setLoading(false)
            })
        })
    }, [installation_id])

    const goBack = () => {
        setStatus(null)
    }

    const [form] = Form.useForm();
    const submitJob = () => {
        return form.validateFields().then(
            values => {
                const request: JobRequest = { 
                    config: {
                        temp_en: values[roomTemperature.name],
                        pos_en: values[positioningTilt.name],
                        gps_en: values[gpsPosition.name],
                        batt_en: values[batteryVoltage.name],
                        solar_en: values[solarPanelVoltage.name],
                        cw_en: values[clearWaterLevel.name],
                        cw_max: Number(values[clearWaterLevelMax.name]),
                        cw_min: Number(values[clearWaterLevelMin.name]),
                        dw_en: values[darkWaterLevel.name],
                        dw_max: Number(values[darkWaterLevelMax.name]),
                        dw_min: Number(values[darkWaterLevelMin.name]),
                        door1_en: values[`first_${doorOpenStatus.name}`],
                        pir1_en: values[`first_${interiorLightsStatus.name}`],
                        led1_en: values[`first_${ledFlushStatus.name}`],
                        flush1_en: values[`first_${flushButton.name}`],
                        sink1_en: values[`first_${handWashButton.name}`],
                        door2_en: values[`second_${doorOpenStatus.name}`],
                        pir2_en: values[`second_${interiorLightsStatus.name}`],
                        led2_en: values[`second_${ledFlushStatus.name}`],
                        flush2_en: values[`second_${flushButton.name}`],
                        sink2_en: values[`second_${handWashButton.name}`],
                    }
                }
                setJob(installation_id, calibrationLabel, request).then((res) => {
                    if(res && !res.err){
                        setStatus(successStatus)
                    }else{
                        setStatus(errorStatus)
                    }
                })
            }
        )
    }

    if (status) {
        return (
            <OperationResult
                onClickProp={status === errorStatus ? goBack : undefined}
                status={status}
                operation={"create"}
                entity={"signal"}
            />
        )
    }
    
    if(loading){
        return null
    }

    return(
        <>
            <TitleWithBack title={signalConfigurationTitle} key={signalConfigurationTitleKey}/>
            <div className="my-signalconfiguration-container my-signalconfiguration-container-responsive" key={signalConfigurationDivKey}>
                <Form layout="vertical" key={1} form={form} name={jobPanelLabel}>
                    <Row gutter={24}>
                    {
                        modelJob(installation?.model || "", initialJob?.config || {}).map((item: any, index: number) => {
                            if(item.divider){
                                return <Divider>{item.divider}</Divider>
                            }else{
                                if(item.type === radioType){
                                    return (
                                        <Col span={(width <= 575 || height <= 815) ? 12 : 8} key={index} >
                                            <div className="form__group field" key={divKey(item.key)}>
                                                <label key={labelKey(item.key)} htmlFor={htmlForName} className="form__label">{item.label}</label>
                                                <Form.Item key={item.key} rules={item.rules || undefined} name={item.name} initialValue={item.value}>
                                                    <Switch
                                                        defaultChecked={item.value}
                                                        onChange={(e) => {
                                                            if(item.disabled){
                                                                let tmp_disabled = disabled
                                                                tmp_disabled[item.disabled] = e
                                                                setDisabled([...tmp_disabled])
                                                            }
                                                        }}
                                                        checkedChildren={
                                                            <h3 style={{color: "white"}}>
                                                            Enabled
                                                            </h3>
                                                        }
                                                        unCheckedChildren={
                                                            <h3 style={{color: "white"}}>
                                                            Disabled
                                                            </h3>
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    )
                                }else{
                                    return (
                                        <Col span={(width <= 575 || height <= 815) ? 12 : 8} key={index} >
                                            <FormInput 
                                                key={formInputKey(item.key)}
                                                name={item.name}
                                                placeholder={item.label}
                                                type={item.type} 
                                                value={item.value}
                                                keyValue={item.key}
                                                rules={ item.disabled ? !disabled[item.disabled] ? undefined : item.rules: undefined }
                                                disabled={ item.disabled ? !disabled[item.disabled] : undefined }
                                            />
                                        </Col>
                                    )                                
                                }
                            }
                        })
                    }
                    </Row>
                    <div className="btn-container" key={signalConfigurationBtnDivKey}>
                        <ButtonConfItem buttonLabel={resetLabel} buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel={questionLabel} />
                        <ButtonItem buttonType={primaryType} label={invioLabel} buttonOnClick={submitJob} />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default SignalConfiguration;