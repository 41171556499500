import './Navbar.css'
import {adminMenuItems} from './adminMenuItems'
import {installerMenuItems} from './installerMenuItems'
import {customerMenuItems} from './customerMenuItems'
import React, {useEffect, useState} from "react";
import {GetUserProfile} from '../../api/services/userService';
import {MenuType, SubMenuType} from '../types/navbar/types';
import {useHistory} from "react-router-dom";
import logo from '../../logo/logo-armal.png';
import {Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {routes} from "../pages/costants";
import { adminRole, collapseKey, divKey, drawerKey, iconKey, installerRole, listKey, logo as logoKey, persistentVariant, secondaryListKey, textKey } from '../../costants/costants';

type NavbarProps = {
	SetNavbarIsCollapsed: (collapsed: boolean) => void
	navbar_collapsed: boolean,
}

const Navbar: React.FC<NavbarProps> = ({
	SetNavbarIsCollapsed,
	navbar_collapsed
}) => {

    const [selectedItem, setSelectedItem] = useState<string>("")
	const [panelOpen, setPanelOpen] = useState<boolean>(false)
	const [menuItem, setMenuItem] = useState<MenuType[]>([]);
	const history = useHistory();

    const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
        GetUserProfile().then((res: any) => {
			if(res && res.user && res.user.roles && res.user.roles.length > 0){
				const role: string = res["user"]["roles"][0]
				if(role.includes(installerRole)){
					setMenuItem(installerMenuItems)
				}else{
					if(role.includes(adminRole)){
						setMenuItem(adminMenuItems)
					}else{
						setMenuItem(customerMenuItems)
					}
				}
			}
		})
    }, [])

	return (
		<>
			<Drawer
				key={drawerKey}
				onClose={() => SetNavbarIsCollapsed(true)}
				open={isMobile ? !navbar_collapsed : true}
				variant={!isMobile ? persistentVariant : undefined}
			>
				<img
					key={logoKey}
					className={'logo'}
					alt={logoKey}
					src={logo}
					onClick={
						() => {
							history.push(routes.mainPage)
							SetNavbarIsCollapsed(true);
					}}
				/>
				<List style={{zIndex: -1}} key={listKey}>
					{
					menuItem.map((item: MenuType) => {
						return(
							<div key={divKey(item.key)}>
								<ListItem
									button
									key={item.key}
									onClick={() => {
										if(item?.link){
											if (typeof item.link === "string") {
												history.push(item.link)
											} else{
												history.push(item.link());
											}
										}
										if(item.elements.length > 0) {
											setPanelOpen(!panelOpen);
										} else {
											setSelectedItem(item.key);
											SetNavbarIsCollapsed(true);
										}
									}}
									selected={selectedItem === item.key}
								>
									<ListItemIcon key={iconKey(item.key)}>
										{item.icon}
									</ListItemIcon>
									<ListItemText key={textKey(item.key)} primary={item.label}/>
									{item.elements.length > 0 ? panelOpen ? <ExpandLess /> : <ExpandMore /> : null}
								</ListItem>
								{
									item.elements.length > 0 ?
										<Collapse
											key={collapseKey(item.key)}
											in={panelOpen}
											timeout="auto"
											unmountOnExit
										>
											<List key={secondaryListKey(item.key)} component="div" disablePadding>
												{
													item.elements.map((e: SubMenuType) =>
														<ListItem
															key={e.key}
															selected={selectedItem === e.key}
															button
															onClick={() => {
																if(e?.link){
																	setSelectedItem(e.key);
																	if (typeof e.link === "string") {
																		history.push(e.link)
																	} else{
																		history.push(e.link());
																	}
																	SetNavbarIsCollapsed(true)
																}
															}}
														>
															<ListItemIcon key={iconKey(e.key)}>
																{e.icon}
															</ListItemIcon>
															<ListItemText key={textKey(e.key)} primary={e.label} />
														</ListItem>
													)
												}
											</List>
										</Collapse>
									:
									null
								}
							</div>
						)
					})
					}
				</List>
        	</Drawer>
		</>
	)
}

export default Navbar;