export const isTest = process.env.NODE_ENV === "test";

export const verticalFetch = (method: string, url: string, param?: string): Promise<any> => {
    return fetch(url, {
        method: method,
        credentials: "include",
        body: param,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(
        response => response.json()
    ).catch(
        error => {
            console.log(error)
        }
    )
}

export const httpFetch = (method: string, url: string, param?: string): Promise<any> => {
    const hrs = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }

    return fetch(url, {
        method: method,
        body: param,
        headers: hrs
    }).then(
        response => response.json()
    ).catch(
        error => {
            throw Error(error.responseText)
        }
    )
}

// used to download files
export const blobFetch = (method: string, url: string, param?: string): Promise<any> => {
    return fetch(url, {
        method: method,
        body: param,
        headers: {
            'Accept': 'application/octet-stream'
        }
    })
        .then(
            response => response.blob()
        )
        .catch(error => {
                console.log(error)
        });
}

export const verticalCheck = (method: string, url: string, param?: string): Promise<any> => {
    return fetch(url, {
        method: method,
        body: param,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(
        response => {
            if(response.status !== 200){
                return response.json()
            }else{
                return response
            }
        } 
    ).catch(
        error => {
            console.log(error)
        }
    )
}

export const methodPost = "POST"
export const methodPut = "PUT"
export const methodGet = "GET"
export const methodDelete = "DELETE"