import './summary.css';
import React from "react";
import moment from 'moment';
import {Descriptions} from "antd";
import { Installation } from "../../api/requests/installationsService";
import { createdAt, installationLabel, model, name } from '../../costants/costants';
import { modelLabel } from '../../utilities/utilities';

type InstallationSummaryProps = {
    selected_installation: Installation | null,
}

const InstallationSummary: React.FC<InstallationSummaryProps> = ({selected_installation}) => {

    const dateFormatter = (date: string) => {
        return moment(date).format('DD/MM/YY');
    };

    return (
        <div className="description">
            <Descriptions labelStyle={{fontWeight: "bold"}} style={{margin: "2%"}} title={installationLabel} bordered>
                <Descriptions.Item label={name.label} span={3}>
                    {selected_installation?.name ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label={model.label} span={3}>
                    {modelLabel(selected_installation?.model?? "")}
                </Descriptions.Item>
                <Descriptions.Item label={createdAt.label} span={3}>
                    {dateFormatter(selected_installation?.created_at ?? "")}
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default InstallationSummary;