import { InputType } from "../../types/form/types";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { email, password, passwordType } from "../../../costants/costants";

export const inputs: InputType[] = [
    {   
        key: email.key, 
        name: email.name,
        label: email.label,
        icon: <UserOutlined className="site-form-item-icon" />
    },
    {   
        key: password.key,
        name: password.name,
        label: password.label,
        type: passwordType,
        icon: <LockOutlined className="site-form-item-icon" />            
    }
]