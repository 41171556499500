import "./AddSubscription.css";
import 'antd/dist/antd.css';
import '../../../App.css';
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import {Col, Form, Row, Select} from 'antd';
import React, {useEffect, useState} from "react";
import {GetCustomers} from "../../../api/services/userService";
import {User} from "../../../api/requests/userService";
import {addSubscription} from "../../../api/services/subscriptionService";
import {CreateSubscriptionRequest} from "../../../api/requests/subscription";
import CustomTitle from "../../CustomTitle";
import FormInput from "../../input/FormInput";
import {companyInfo} from "./inputs";
import FormSelect from "../../select/FormSelect";
import {countries} from "../../countries";
import {addSubscriptionTitle} from "../title";
import OperationResult from "../../OperationResult";
import { children, companyInfoKey, errorStatus, formInputKey, invioLabel, primaryType, questionLabel, resetLabel, selectType, successStatus, textType, user } from "../../../costants/costants";
import { isTest } from "../../../api/services/httpRequests";

const { Option } = Select;

const AddSubscription: React.FC = () => {
    const [customers, setCustomers] = useState<User[]>([]);
    const [status, setStatus] = useState<"success" | "error" | null >(null);

    const goBack = () => {
        setStatus(null)
    }
    
    useEffect(() => {
        GetCustomers("-1", "", "").then((res: any) => {
			if(res && res.customers){
				setCustomers(res.customers) 
			}
		})
    }, [])

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            sm: {
                span: 8
            }
        },
        wrapperCol: {
            sm: {
                span: 20
            }
        }
    };

    const handleSubmit = () => {
        return form.validateFields().then(
            values => {
                const req: CreateSubscriptionRequest = {
                    customer_id: values.selectCustomer,
                    name: values.subscription_name,
                    company_info: {
                        name: values.company_name,
                        sdi: values.company_sdi,
                        pec: values.company_pec,
                        vat_number: values.company_vat_number,
                        phone: values.company_phone,
                        address: values.company_address,
                        city: values.company_city,
                        province: values.company_province,
                        country: values.company_country,
                        zip_code: values.company_zip_code
                    }
                }
                addSubscription(req).then(res => {
                    if(res && !res.err){
                        setStatus(successStatus);
                    }else{
                        setStatus(errorStatus);
                    }
                })
            }
        ).catch((e) => {
            if(isTest){
                let res: boolean = true
                Object.keys(e.values).forEach((k) => {
                    if(!e.values[k]){
                        res = false
                    }
                })
                setStatus(res ? successStatus : errorStatus)
            }
        })
    }

    if (status) {
        return (
            <OperationResult
                onClickProp={status === errorStatus ? goBack : undefined}
                status={status}
                operation={"create"}
                entity={"subscription"}
            />
        )
    }

    return(
        <>
            <CustomTitle title={addSubscriptionTitle} /> 
            <div className="form-container-sub my-form-container-sub-responsive">
                <Form {...formItemLayout} key={1} form={form} name={user.name} requiredMark="optional">
                    <div style={{display:"flex", justifyContent: "center"}}>
                        <p>{user.label}</p>
                    </div>
                    <Form.Item className="selectdiv" key={formInputKey(user.key)} name={user.key} rules={[ {required: true} ]}>
                        <Select key={user.key} size='large' placeholder={user.label} optionFilterProp={children}>
                        {
                            customers.map((el, index) =>
                                <Option value={el.uid} key={index}> {el.email} </Option>
                            )
                        }
                        </Select>
                    </Form.Item>                        
                    <Row gutter={24} key={companyInfoKey}>
                        {
                            companyInfo.map((el, index) => {
                                return (
                                    <Col span={(width <= 575 || height <= 815) ? 12 : 8} key={index} >
                                        {
                                            el.type !== selectType ?
                                            <FormInput 
                                                key={formInputKey(el.key)} 
                                                keyValue={el.key} 
                                                name={el.name} 
                                                rules={el.rules} 
                                                placeholder={el.label} 
                                                type={el.type ?? textType} 
                                            /> :
                                            <FormSelect 
                                                key={formInputKey(el.key)} 
                                                placeholder={el.label} 
                                                keyValue={el.key} 
                                                rules={el.rules} 
                                                name={el.name} 
                                                options={countries} 
                                            />
                                        }
                                    </Col>
                                )
                            })  
                        }
                    </Row>
                    <div className="btn-container">
                        <ButtonConfItem 
                            buttonLabel={resetLabel} 
                            buttonOnConfirm={() => form.resetFields()} 
                            buttonOnCancel={() => {}} 
                            questionLabel={questionLabel} 
                        />
                        <ButtonItem 
                            buttonType={primaryType} 
                            label={invioLabel} 
                            buttonOnClick={handleSubmit} 
                        />
                    </div>
                </Form>
            </div>
        </>
    )
    
}

export default AddSubscription;
