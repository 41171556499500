export const countries = [
    {"Afghanistan": "Afghanistan"},
    {"Albania": "Albania"},
    {"Algeria": "Algeria"},
    {"American Samoa": "American Samoa"},
    {"Andorra": "Andorra"},
    {"Angola": "Angola"},
    {"Anguilla": "Anguilla"},
    {"Antarctica": "Antarctica"},
    {"Antigua And Barbuda": "Antigua and Barbuda"},
    {"Argentina": "Argentina"},
    {"Armenia": "Armenia"},
    {"Aruba": "Aruba"},
    {"Australia": "Australia"},
    {"Austria": "Austria"},
    {"Azerbaijan": "Azerbaijan"},
    {"Bahamas": "Bahamas"},
    {"Bahrain": "Bahrain"},
    {"Bangladesh": "Bangladesh"},
    {"Barbados": "Barbados"},
    {"Belarus": "Belarus"},
    {"Belgium": "Belgium"},
    {"Belize": "Belize"},
    {"Benin": "Benin"},
    {"Bermuda": "Bermuda"},
    {"Bhutan": "Bhutan"},
    {"Bolivia": "Bolivia"},
    {"Bosnia Herzegovina": "Bosnia Herzegovina"},
    {"Botswana": "Botswana"},
    {"Bouvet": "Bouvet"},
    {"Brazil": "Brazil"},
    {"British Indian Ocean Territory": "British Indian Ocean Territory"},
    {"Brunei": "Brunei"},
    {"Bulgaria": "Bulgaria"},
    {"Burkina Faso": "Burkina Faso"},
    {"Burundi": "Burundi"},
    {"Cambodia": "Cambodia"},
    {"Cameroon": "Cameroon"},
    {"Canada": "Canada"},
    {"Cape Verde": "Cape Verde"},
    {"Cayman Islands": "Cayman Islands"},
    {"Central African Republic": "Central African Republic"},
    {"Chad": "Chad"},
    {"Chile": "Chile"},
    {"China": "China"},
    {"Colombia": "Colombia"},
    {"Comoros": "Comoros"},
    {"Congo": "Congo"},
    {"Cook Islands": "Cook Islands"},
    {"Costa Rica": "Costa Rica"},
    {"Ivory Coast": "Ivory Coast"},
    {"Croatia": "Croatia"},
    {"Cuba": "Cuba"},
    {"Curacao": "Curacao"},
    {"Cyprus": "Cyprus"},
    {"Czech Republic": "Czech Republic"},
    {"Democratic Republic of the Congo": "Democratic Republic of the Congo"},
    {"Denmark": "Denmark"},
    {"Djibouti": "Djibouti"},
    {"Dominican Republic": "Dominican Republic"},
    {"Ecuador": "Ecuador"},
    {"Egypt": "Egypt"},
    {"El Salvador": "El Salvador"},
    {"Equatorial Guinea": "Equatorial Guinea"},
    // "Eritrea": ​​"Eritrea"},
    {"Estonia": "Estonia"},
    {"Ethiopia": "Ethiopia"},
    {"Falkland Islands": "Falkland Islands"},
    {"Faroe Islands": "Faroe Islands"},
    {"Fiji": "Fiji"},
    {"Finland": "Finland"},
    {"France": "France"},
    {"French Guiana": "French Guiana"},
    {"French Polynesia": "French Polynesia"},
    {"French Southern Territories": "French Southern Territories"},
    {"Gabon": "Gabon"},
    {"Gambia": "Gambia"},
    {"Georgia": "Georgia"},
    {"Germany": "Germany"},
    {"Ghana": "Ghana"},
    {"Gibraltar": "Gibraltar"},
    {"Greece": "Greece"},
    {"Greenland": "Greenland"},
    {"Grenada": "Grenada"},
    {"Guadeloupe": "Guadeloupe"},
    {"Guam": "Guam"},
    {"Guatemala": "Guatemala"},
    {"Guernsey": "Guernsey"},
    {"Guinea": "Guinea"},
    {"Guinea-Bissau": "Guinea-Bissau"},
    {"Guyana": "Guyana"},
    {"Haiti": "Haiti"},
    {"Heard and Mc Donald Islands": "Heard and Mc Donald Islands"},
    {"Honduras": "Honduras"},
    {"Hong Kong": "Hong Kong"},
    {"Iceland": "Iceland"},
    {"India": "India"},
    {"Indonesia": "Indonesia"},
    {"Iran": "Iran"},
    {"Iraq": "Iraq"},
    {"Ireland": "Ireland"},
    {"Isle of Man": "Isle of Man"},
    {"Israel": "Israel"},
    {"Italy": "Italy"},
    {"Jamaica": "Jamaica"},
    {"Japan": "Japan"},
    {"Jersey": "Jersey"},
    {"Jordan": "Jordan"},
    {"Kazakhstan": "Kazakhstan"},
    {"Kenya": "Kenya"},
    {"Kiribati": "Kiribati"},
    {"Kuwait": "Kuwait"},
    {"Kyrgyzstan": "Kyrgyzstan"},
    {"Laos": "Laos"},
    {"Latvia": "Latvia"},
    {"Lebanon": "Lebanon"},
    {"Lesotho": "Lesotho"},
    {"Liberia": "Liberia"},
    {"Libya": "Libya"},
    {"Liechtenstein": "Liechtenstein"},
    {"Lithuania": "Lithuania"},
    {"Luxembourg": "Luxembourg"},
    {"Macao": "Macao"},
    {"Macedonia": "Macedonia"},
    {"Madagascar": "Madagascar"},
    {"Malawi": "Malawi"},
    {"Malaysia": "Malaysia"},
    {"Maldives": "Maldives"},
    {"Mali": "Mali"},
    {"Malta": "Malta"},
    {"Marshall Islands": "Marshall Islands"},
    {"Martinique": "Martinique"},
    {"Mauritania": "Mauritania"},
    {"Mauritius": "Mauritius"},
    {"Mayotte": "Mayotte"},
    {"Mexico": "Mexico"},
    {"Micronesia": "Micronesia"},
    {"Moldova": "Moldova"},
    {"Monaco": "Monaco"},
    {"Mongolia": "Mongolia"},
    {"Montenegro": "Montenegro"},
    {"Montserrat": "Montserrat"},
    {"Morocco": "Morocco"},
    {"Mozambique": "Mozambique"},
    {"Myanmar": "Myanmar"},
    {"Namibia": "Namibia"},
    {"Nauru": "Nauru"},
    {"Nepal": "Nepal"},
    {"Netherlands": "Netherlands"},
    {"Netherlands Antilles": "Netherlands Antilles"},
    {"New Caledonia": "New Caledonia"},
    {"New Zealand": "New Zealand"},
    {"Niger": "Niger"},
    {"Nigeria": "Nigeria"},
    {"Niue": "Niue"},
    {"Norfolk Island":"Norfolk Island"},
    {"North Korea": "North Korea"},
    {"Northern Mariana Islands": "Northern Mariana Islands"},
    {"Norway": "Norway"},
    {"Oman": "Oman"},
    {"Pakistan": "Pakistan"},
    {"Palau": "Palau"},
    {"Palestine": "Palestine"},
    {"Panama": "Panama"},
    {"Papua New Guinea": "Papua New Guinea"},
    {"Paraguay": "Paraguay"},
    {"Peru": "Peru"},
    {"Philippines": "Philippines"},
    {"Pitcairn": "Pitcairn"},
    {"Poland": "Poland"},
    {"Portugal": "Portugal"},
    {"Puerto Rico": "Puerto Rico"},
    {"Qatar": "Qatar"},
    {"Republic of Kosovo": "Kosovo"},
    {"Romania": "Romania"},
    {"Russia": "Russian Federation"},
    {"Rwanda": "Rwanda"},
    {"Saint Kitts and Nevis": "St Kitts and Nevis"},
    {"Santa Lucia": "Santa Lucia"},
    {"Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines"},
    {"Samoa": "Samoa"},
    {"San Marino": "San Marino"},
    {"Sao Tome and Principe": "Sao Tome and Principe"},
    {"Saudi Arabia": "Saudi Arabia"},
    {"Senegal": "Senegal"},
    {"Serbia": "Serbia"},
    {"Seychelles": "Seychelles"},
    {"Sierra Leone": "Sierra Leone"},
    {"Singapore": "Singapore"},
    {"Sint Maarten": "Sint Maarten"},
    {"Slovakia": "Slovakia"},
    {"Slovenia": "Slovenia"},
    {"Solomon Islands": "Solomon Islands"},
    {"Somalia": "Somalia"},
    {"South Africa": "South Africa"},
    {"South Georgia and the South Sandwich Islands": "South Georgia and the South Sandwich Islands"},
    {"South Korea": "South Korea"},
    {"South Sudan": "South Sudan"},
    {"Spain": "Spain"},
    {"Sri Lanka": "Sri Lanka"},
    {"St. Elena": "St. Elena"},
    {"St. Pierre and Miquelon": "St. Pierre and Miquelon"},
    {"Sudan": "Sudan"},
    {"Suriname": "Suriname"},
    {"Svalbard and Jan Mayen": "Svalbard and Jan Mayen"},
    {"Syria": "Syria"},
    {"United Arab Emirates": "United Arab Emirates"},
    {"United Kingdom": "United Kingdom"},
    {"United States": "United States"},
    {"Sweden": "Sweden"},
    {"Switzerland": "Switzerland"},
    {"Swaziland": "Swaziland"},
    {"Taiwan": "Taiwan"},
    {"Tajikistan": "Tajikistan"},
    {"Tanzania": "Tanzania"},
    {"Thailand": "Thailand"},
    {"East Timor": "East Timor"},
    {"Togo": "Togo"},
    {"Tokelau": "Tokelau"},
    {"Tonga": "Tonga"},
    {"Trinidad and Tobago": "Trinidad and Tobago"},
    {"Tunisia": "Tunisia"},
    {"Turkey": "Turkey"},
    {"Turkmenistan": "Turkmenistan"},
    {"Turks and Caicos": "Turks and Caicos"},
    {"Tuvalu": "Tuvalu"},
    {"Ukraine": "Ukraine"},
    {"Uganda": "Uganda"},
    {"Hungary": "Hungary"},
    {"Uruguay": "Uruguay"},
    {"Uzbekistan": "Uzbekistan"},
    {"Vanuatu": "Vanuatu"},
    {"Vatican City": "Vatican City"},
    {"Venezuela": "Venezuela"},
    {"Vietnam": "Vietnam"},
    {"Wallis and Futuna": "Wallis and Futuna"},
    {"Yemen": "Yemen"},
    {"Zambia": "Zambia"},
    {"Zimbabwe": "Zimbabwe"}
]