import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import { routes } from './components/pages/costants';
import RecoverPassword from './components/pages/recoverPassword/RecoverPassword';
import ResultResetPassword from './components/pages/resetPassword/Result';
import ResultRecoverPassword from './components/pages/recoverPassword/Result';
import { createMuiTheme, ThemeProvider } from '@mui/material/styles';
import LoginPanel from './components/pages/form_login/LoginPanel';
import MainPage from './components/pages/mainPage';
import ResetPassword from './components/pages/resetPassword/resetPassword';

declare global {
  interface Window {
    _env_: any,
      api: any
  }
}

const theme = createMuiTheme();

function App() {
    return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Switch>
					<Route exact path={routes.login} component={LoginPanel} />
					<Route exact path={routes.recoverStart} component={ResetPassword} />
					<Route exact path={routes.recoverEnd} component={RecoverPassword} />
					<Route exact path={routes.resetResult} component={ResultResetPassword} />
					<Route exact path={routes.recoverResult} component={ResultRecoverPassword} />
					<Route component={MainPage} />
				</Switch>
			</BrowserRouter>			
		</ThemeProvider>
    );
}

export default App;