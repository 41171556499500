import { LockOutlined } from "@ant-design/icons";
import { confirmPassword, password, passwordType } from "../../../costants/costants";
import { confirmPasswordRules, passwordRules } from "../../types/form/rules";
import { InputType } from "../../types/form/types";

export const inputs: InputType[] = [
    {   
        key: password.key,
        name: password.name,
        label: password.label,
        rules: passwordRules,
        type: passwordType,
        icon: <LockOutlined className="site-form-item-icon" />            
    },
    {
        key: confirmPassword.key,
        name: confirmPassword.name,
        label: confirmPassword.label,
        rules: confirmPasswordRules,
        type: passwordType,
        icon: <LockOutlined className="site-form-item-icon" />   
    }
]