import './summary.css'
import React, {} from 'react';
import {Card, Divider} from 'antd';
import { Installation } from '../../../../api/requests/installationsService';
import moment from 'moment';
import Title from "antd/es/typography/Title";
import {Typography} from "@mui/material";


type InstallationSummaryProps = {
    selected_installation: Installation | null
}

const InstallationSummary: React.FC<InstallationSummaryProps> = ({ selected_installation }) => {
    return(
            <Card
                style={{minHeight: "290px", maxHeight: "290px", overflow: "scroll"}}
                hoverable
                title={<Title level={4} className={"tableTitle"}>Installation</Title>}
                headStyle={{maxHeight: "4vh"}}
            >
                <div key={`container_div_1`}>
                    <div className="tag-container">
                        <Typography>Name:</Typography>
                        <Typography>{selected_installation?.name || ""}</Typography>
                    </div>
                </div>
                <Divider />

                <div key={`container_div_2`}>
                    <div className="tag-container">
                        <Typography>Model:</Typography>
                        <Typography>{selected_installation?.model || ""}</Typography>
                    </div>
                    <Divider />
                </div>
                <div key={`container_div_3`}>
                    <div className="tag-container">
                        <Typography>Creation Date:</Typography>
                        <Typography> {moment(selected_installation?.created_at || "").format('DD/MM/YYYY')} </Typography>
                    </div>
                </div>
            </Card>
    )
}

export default InstallationSummary