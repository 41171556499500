export const getAllInstallationsMock = {
    installations: [
        {"id":"dev-6ig14vugwpb0","name":"test01","description":"description01","fleet_id":"","subscription_id":"sub6ig13scjs935","installation_info":{},"created_at":"2021-12-13T08:04:31.814466Z","status":"offline","created_by":"1639382598"},
        {"id":"dev-6ig14witf2n1","name":"test01","description":"description01","fleet_id":"","subscription_id":"sub6ig13scjs935","installation_info":{},"created_at":"2021-12-13T08:04:32.165235Z","status":"offline","created_by":"1639382598"},
        {"id":"dev-6ig14witf2n8","name":"test01","description":"description01","fleet_id":"","subscription_id":"sub6ig13scjs935","installation_info":{},"created_at":"2021-12-13T08:04:32.165235Z","status":"offline","created_by":"1639382598"}
    ]
}

export const getInstallationMock = {
    installation: {"id":"dev-6ig14vugwpb0","name":"test01","description":"description01","fleet_id":"flt-6i6912a8yoei","subscription_id":"sub6ig13scjs935","installation_info":{},"created_at":"2021-12-13T08:04:31.814466Z","status":"offline","created_by":"1639382598", "model": "e_block"}
}

export const getAllAlarmsMock = {
    alarms: [
        {start: "2021-10-10T08:52:42.367", finish: "2021-10-16T08:52:42.367", installation_name: "Installazione01", tag: "offline", level: "warning"},
        {start: "2021-11-10T08:52:42.367", finish: "2021-11-16T08:52:42.367", installation_name: "Installazione01", tag: "offline", level: "warning"},
        {start: "2021-12-10T08:52:42.367", finish: "2021-12-16T08:52:42.367", installation_name: "Installazione01", tag: "offline", level: "warning"}
    ]
}

export const getJobMock = {
    calibration:{"installation_id":"dev-6ig14witf2n1","type":"calibration","config":{"batt_en":false,"cw_en":true,"cw_max":10,"cw_min":5,"door1_en":true,"door2_en":true,"dw_en":false,"dw_max":10,"dw_min":5,"flush1_en":true,"flush2_en":true,"gps_en":true,"led1_en":true,"led2_en":true,"pir1_en":true,"pir2_en":true,"pos_en":false,"sink1_en":true,"sink2_en":true,"solar_en":true,"temp_en":true},"last_set_status":"done"}
}

export const getAllSubscriptionsMock = {
    subscriptions: [
        {"id":"sub6ig13scjs935","company_info":{"name":"azienda01","sdi":"sdi","pec":"pec@pec.com","vat_number":"123456789","phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"customer_id":"1639382638","customer_name":"user","customer_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"status":"enabled","requested_status":"","created_at":"2021-12-13T08:04:11.315323Z","expiry_date":"2022-12-14T00:00:00Z"},
        {"id":"sub6ig13scjs936","company_info":{"name":"azienda02","sdi":"sdi","pec":"pec@pec.com","vat_number":"123456789","phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"customer_id":"1639382638","customer_name":"user","customer_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"status":"enabled","requested_status":"","created_at":"2021-12-13T08:04:11.315323Z","expiry_date":"2022-12-14T00:00:00Z"},
        {"id":"sub6ig13scjs937","company_info":{"name":"azienda03","sdi":"sdi","pec":"pec@pec.com","vat_number":"123456789","phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"customer_id":"1639382638","customer_name":"user","customer_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"status":"enabled","requested_status":"","created_at":"2021-12-13T08:04:11.315323Z","expiry_date":"2022-12-14T00:00:00Z"}
    ]
}

export const getSubscriptionMock = {
    "subscription":{"id":"sub6ig13scjs935","company_info":{"name":"azienda","sdi":"sdi","pec":"pec@pec.com","vat_number":"123456789","phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"customer_id":"1639382638","customer_name":"user","customer_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"},"status":"enabled","requested_status":"","created_at":"2021-12-13T08:04:11.315323Z","expiry_date":"2022-12-14T00:00:00Z"}
}

export const getSubscriptionInstallationsMock = {
    "installations": [
        {"id":"dev-6ig14vugwpb0","name":"test01","description":"description01","fleet_id":"flt-6i6912a8yoei","subscription_id":"sub6ig13scjs935","installation_info":{},"created_at":"2021-12-13T08:04:31.814466Z","status":"offline","created_by":"1639382598"},
        {"id":"dev-6ig14vugwpb0","name":"test01","description":"description01","fleet_id":"flt-6i6912a8yoei","subscription_id":"sub6ig13scjs935","installation_info":{},"created_at":"2021-12-13T08:04:31.814466Z","status":"offline","created_by":"1639382598"},
        {"id":"dev-6ig14vugwpb0","name":"test01","description":"description01","fleet_id":"flt-6i6912a8yoei","subscription_id":"sub6ig13scjs935","installation_info":{},"created_at":"2021-12-13T08:04:31.814466Z","status":"offline","created_by":"1639382598"}
    ]
}

export const getUserMock = {
    "user":{"uid":"1639382638","email":"user@user.com","name":"user","roles":["customer"],"created_at":"2021-12-13T08:03:58.596162Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"}}
}

export const getInstallersMock = {
    installers: [
        {"uid":"1639382598","email":"installer@installer.com","name":"installer","roles":["installer"],"created_at":"2021-12-13T08:03:18.633446Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Afghanistan","zip_code":"56021"}},
        {"uid":"1639382597","email":"installer@installer.com","name":"installer","roles":["installer"],"created_at":"2021-12-13T08:03:18.633446Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Afghanistan","zip_code":"56021"}},
        {"uid":"1639382596","email":"installer@installer.com","name":"installer","roles":["installer"],"created_at":"2021-12-13T08:03:18.633446Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Afghanistan","zip_code":"56021"}}
    ]
}

export const getCustomersMock = {
    "customers":[
        {"uid":"1639382637","email":"user01@user.com","name":"user01","roles":["customer"],"created_at":"2021-12-13T08:03:58.596162Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"}},
        {"uid":"1639382638","email":"user02@user.com","name":"user02","roles":["customer"],"created_at":"2021-12-13T08:03:58.596162Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"}},
        {"uid":"1639382639","email":"user03@user.com","name":"user03","roles":["customer"],"created_at":"2021-12-13T08:03:58.596162Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"}}
    ]
}

export const getRelInstallersCustomerMock = {
    "relations":[
        {"installer_id":"1639382598","installer_email":"installer@installer.com","installer_name":"installer","customer_id":"1639382638","customer_email":"user@user.com","customer_name":"user"},{"installer_id":"1639382598","installer_email":"installer@installer.com","installer_name":"installer","customer_id":"1639661395","customer_email":"s.falchi@zerynth.com","customer_name":"Stefania Falchi"}
    ]
}

export const getUserProfileMock = {
    "user":{
        "uid":"1639382638","email":"installer@installer.com","name":"user","roles":["installer"],"created_at":"2021-12-13T08:03:58.596162Z","user_info":{"phone":"3333333333","address":"via Mazzini 12","city":"Pisa","province":"Pisa","country":"Italia","zip_code":"56021"}
    }
}
