export const firstPortRange = 50000
export const lastPortRange =  50030
export const maxConnectionAttempts = 3

export type FileObject = {
    name: string
    content: Object
}

export type ResponseObject = {
    action: string
    status: string
    result: string
    msg: string
}

export type TestObject = {
    label: string
    command: string
    description: string
}

export type SummaryObject = {
    success: {
        total: number
        steps: any[]
        feedback: ResponseObject[]
    },
    failed: {
        total: number
        steps: any[]
        feedback: ResponseObject[]
    },
    skipped: {
        total: number
        steps: any[]
        feedback: ResponseObject[]
    }
}

export const devicesAction: string = "devices"
export const testSignal: string = "test_signal"

export const titleError: string = "Device not detected correctly"
export const subtitleError: string = " Please check that the device is connected properly and reload this page"

export const eBlockTests = [
    {
        label: "Presence/light sensor toilet 1 (PIR 1)",
        tests: [
            {
                label: 'Presence/light sensor toilet 1 (PIR 1) - condition: lights ON',
                command: 'pir1 on',
                description: `Make sure that lights on toilet 1 are turned ON, then press run test`,
            },
            {
                label: 'Presence/light sensor toilet 1 (PIR 1) - condition: lights OFF',
                command: 'pir1 off',
                description: `Make sure that lights on toilet 1 are turned OFF, then press run test`,
            }
        ]
    },
    {
        label: "Presence/light sensor toilet 2 (PIR 2)",
        tests: [
            {
                label: 'Presence/light sensor toilet 2 (PIR 2) - condition: lights ON',
                command: 'pir2 on',
                description: `Make sure that lights on toilet 2 are turned ON, then press run test`,
            },
            {
                label: 'Presence/light sensor toilet 2 (PIR 2) - condition: lights OFF',
                command: 'pir2 off',
                description: `Make sure that lights on toilet 2 are turned OFF, then press run test`,
            }
        ]
    },
    {
        label: "Led on flush button toilet 1",
        tests: [
            {
                label: 'Led on flush button toilet 1 - condition: led ON',
                command: 'led1 on',
                description: `Make sure that led on flush button toilet 1 is turned ON, then press run test`,
            },
            {
                label: 'Led on flush button toilet 1 - condition: led OFF',
                command: 'led1 off',
                description: `Make sure that led on flush button toilet 1 is turned OFF, then press run test`,
            },
            {
                label: 'Led on flush button toilet 1 - condition: led blink FAST',
                command: 'led1 fast',
                description: `Make sure that led on flush button toilet 1 is blinking FAST, then press run test`,
            },
            {
                label: 'Led on flush button toilet 1 - condition: led blink SLOW',
                command: 'led1 slow',
                description: `Make sure that led on flush button toilet 1 is blinking SLOW, then press run test`,
            }
        ]
    },
    {
        label: "Led on flush button toilet 2",
        tests: [
            {
                label: 'Led on flush button toilet 2 - condition: led ON',
                command: 'led2 on',
                description: `Make sure that led on flush button toilet 2 is turned ON, then press run test`,
            },
            {
                label: 'Led on flush button toilet 2 - condition: led OFF',
                command: 'led2 off',
                description: `Make sure that led on flush button toilet 2 is turned OFF, then press run test`,
            },
            {
                label: 'Led on flush button toilet 2 - condition: led blink FAST',
                command: 'led2 fast',
                description: `Make sure that led on flush button toilet 2 is blinking FAST, then press run test`,
            },
            {
                label: 'Led on flush button toilet 2 - condition: led blink SLOW',
                command: 'led2 slow',
                description: `Make sure that led on flush button toilet 2 is blinking SLOW, then press run test`,
            }
        ]
    },
    {
        label: "Door status toilet 1",
        tests: [
            {
                label: 'Door status toilet 1 - condition: OPEN',
                command: 'door1 open',
                description: `Make sure that the door on toilet 1 is OPEN, then press run test`,
            },
            {
                label: 'Door status toilet 1 - condition: CLOSED',
                command: 'door1 close',
                description: `Make sure that the door on toilet 1 is CLOSED, then press run test`,
            }
        ]
    },
    {
        label: "Door status toilet 2",
        tests: [
            {
                label: 'Door status toilet 2 - condition: OPEN',
                command: 'door2 open',
                description: `Make sure that the door on toilet 2 is OPEN, then press run test`,
            },
            {
                label: 'Door status toilet 2 - condition: CLOSED',
                command: 'door2 close',
                description: `Make sure that the door on toilet 2 is CLOSED, then press run test`,
            }
        ]
    },
    {
        label: "Fresh water analog sensor",
        tests: [
            {
                label: 'Fresh water analog sensor - condition: EMPTY',
                command: 'cw_an empty',
                description: `Make sure that fresh water analog sensor is out of tank, then press run test`,
            },
            {
                label: 'Fresh water analog sensor - condition: FULL',
                command: 'cw_an full',
                description: `Make sure that fresh water analog sensor is inside the tank with at least 10cm of water, then press run test`,
            }
        ]
    },
    {
        label: "Waste water analog sensor",
        tests: [
            {
                label: 'Waste water analog sensor - condition: EMPTY',
                command: 'dw_an empty',
                description: `Make sure that waste water analog sensor is out of tank, then press run test`,
            },
            {
                label: 'Waste water analog sensor - condition: FULL',
                command: 'dw_an full',
                description: `Make sure that waste water analog sensor is inside the tank with at least 10cm of water, then press run test`,
            }
        ]
    },
    {
        label: "Flush button toilet 1",
        tests: [
            {
                label: 'Flush button toilet 1',
                command: 'flush1',
                description: `Press run test then go to press flush button toilet 1 at least once`,
            }
        ]
    },
    {
        label: "Flush button toilet 2",
        tests: [
            {
                label: 'Flush button toilet 2',
                command: 'flush2',
                description: `Press run test then go to press flush button toilet 2 at least once`,
            }
        ]
    },
    {
        label: "Sink button toilet 1",
        tests: [
            {
                label: 'Sink button toilet 1',
                command: 'sink1',
                description: `Press run test then go to press sink button toilet 1 at least once`,
            }
        ]
    },
    {
        label: "Sink button toilet 2",
        tests: [
            {
                label: 'Sink button toilet 2',
                command: 'sink2',
                description: `Press run test then go to press sink button toilet 2 at least once`,
            }
        ]
    },
    {
        label: "Voltage battery level",
        tests: [
            {
                label: "Voltage battery level - condition: ATTACHED",
                command: "batt attached",
                description: "Make sure that battery is ATTACHED to the system, then press run test"
            },
            {
                label: "Voltage battery level - condition: DETACHED",
                command: "batt detached",
                description: "Make sure that battery is DETACHED to the system, then press run test"
            }
        ]
    },
    {
        label: "Solar panel voltage level",
        tests: [
            {
                label: "Solar panel voltage level - condition: CHARGING / HIT BY THE SUN",
                command: "solar sun",
                description: "Make sure that solar panel is HIT BY THE SUN, then press run test"
            },
            {
                label: "Solar panel voltage level - condition: NOT CHARGING / SHELTERED FROM THE SUN",
                command: "solar shadow",
                description: "Make sure that solar panel is SHELTERED FROM THE SUN, then press run test"
            }
        ]
    },
    {
        label: "Temperature sensor",
        tests: [
            {
                label: "Temperature sensor",
                command: "temp",
                description: "Make sure that temperature sensor is correctly wired, then press ok and check the measure"
            }
        ]
    }
]

export const eTopPlusTests = [
    {
        label: "Presence/light sensor (PIR 1)",
        tests: [
            {
                label: 'Presence/light sensor (PIR 1) - condition: lights ON',
                command: 'pir1 on',
                description: `Make sure that lights on are turned ON, then press run test`,
            },
            {
                label: 'Presence/light sensor (PIR 1) - condition: lights OFF',
                command: 'pir1 off',
                description: `Make sure that lights on are turned OFF, then press run test`,
            }
        ]
    },
    {
        label: "Led on flush button",
        tests: [
            {
                label: 'Led on flush button - condition: led ON',
                command: 'led1 on',
                description: `Make sure that led on flush button is turned ON, then press run test`,
            },
            {
                label: 'Led on flush button - condition: led OFF',
                command: 'led1 off',
                description: `Make sure that led on flush button is turned OFF, then press run test`,
            },
            {
                label: 'Led on flush button - condition: led blink FAST',
                command: 'led1 fast',
                description: `Make sure that led on flush button is blinking FAST, then press run test`,
            },
            {
                label: 'Led on flush button - condition: led blink SLOW',
                command: 'led1 slow',
                description: `Make sure that led on flush button is blinking SLOW, then press run test`,
            }
        ]
    },
    {
        label: "Door status",
        tests: [
            {
                label: 'Door status - condition: OPEN',
                command: 'door1 open',
                description: `Make sure that the door on is OPEN, then press run test`,
            },
            {
                label: 'Door status - condition: CLOSED',
                command: 'door1 close',
                description: `Make sure that the door is CLOSED, then press run test`,
            }
        ]
    },
    {
        label: "Fresh water analog sensor",
        tests: [
            {
                label: 'Fresh water analog sensor - condition: EMPTY',
                command: 'cw_an empty',
                description: `Make sure that fresh water analog sensor is out of tank, then press run test`,
            },
            {
                label: 'Fresh water analog sensor - condition: FULL',
                command: 'cw_an full',
                description: `Make sure that fresh water analog sensor is inside the tank with at least 10cm of water, then press run test`,
            }
        ]
    },
    {
        label: "Waste water analog sensor",
        tests: [
            {
                label: 'Waste water analog sensor - condition: EMPTY',
                command: 'dw_an empty',
                description: `Make sure that waste water analog sensor is out of tank, then press run test`,
            },
            {
                label: 'Waste water analog sensor - condition: FULL',
                command: 'dw_an full',
                description: `Make sure that waste water analog sensor is inside the tank with at least 10cm of water, then press run test`,
            }
        ]
    },
    {
        label: "Flush button",
        tests: [
            {
                label: 'Flush button',
                command: 'flush1',
                description: `Press run test then go to press flush button at least once`,
            }
        ]
    },
    {
        label: "Sink button",
        tests: [
            {
                label: 'Sink button',
                command: 'sink1',
                description: `Press run test then go to press sink button at least once`,
            }
        ]
    },
    {
        label: "Voltage battery level",
        tests: [
            {
                label: "Voltage battery level - condition: ATTACHED",
                command: "batt attached",
                description: "Make sure that battery is ATTACHED to the system, then press run test"
            },
            {
                label: "Voltage battery level - condition: DETACHED",
                command: "batt detached",
                description: "Make sure that battery is DETACHED to the system, then press run test"
            }
        ]
    },
    {
        label: "Solar panel voltage level",
        tests: [
            {
                label: "Solar panel voltage level - condition: CHARGING / HIT BY THE SUN",
                command: "solar sun",
                description: "Make sure that solar panel is HIT BY THE SUN, then press run test"
            },
            {
                label: "Solar panel voltage level - condition: NOT CHARGING / SHELTERED FROM THE SUN",
                command: "solar shadow",
                description: "Make sure that solar panel is SHELTERED FROM THE SUN, then press run test"
            }
        ]
    },
    {
        label: "Temperature sensor",
        tests: [
            {
                label: "Temperature sensor",
                command: "temp",
                description: "Make sure that temperature sensor is correctly wired, then press ok and check the measure"
            }
        ]
    }
]