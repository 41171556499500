import {
    CreateIdentityRequest,
    CreateInstallationRequest, DeleteIdentityRequest, GetJobStatusRequest,
    GetInstallationRequest, GetInstallationVariablesRequest,
    SendJobToInstallationRequest, SetInstallationVariableRequest,
    UpdateIdentityRequest,
    UpdateInstallationRequest, InstallationUsageRequest, JobRequest, AlarmRequest
} from "../requests/installationsService";
import {httpFetch, isTest, methodDelete, methodGet, methodPost, methodPut, verticalFetch} from "./httpRequests";
import {BackendUrl, InstallationsServiceUrl, rowsPerTablePage} from "../constants";
import { getAllAlarmsMock, getAllInstallationsMock, getInstallationMock, getJobMock } from "./mocks";

export const CreateInstallation = (r: CreateInstallationRequest) => {
    return verticalFetch(methodPost, InstallationsServiceUrl, JSON.stringify(r))
}

export const GetAllInstallations = (page: string, sort: string, search: string) => {
    if(isTest){
        return new Promise((resolve) => resolve(getAllInstallationsMock))
    }
    return verticalFetch(methodGet, `${InstallationsServiceUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`)
}

export const UpdateInstallation = (r: UpdateInstallationRequest) => {
    return verticalFetch(methodPut, `${InstallationsServiceUrl}/${r.id}`, JSON.stringify(r))
}

export const getInstallation = (r: GetInstallationRequest) => {
    if(isTest){
        return new Promise((resolve) => resolve(getInstallationMock))
    }
    return verticalFetch(methodGet, `${InstallationsServiceUrl}/${r.id}`)
}

export const SendJobToInstallation = (r: SendJobToInstallationRequest) => {
    return verticalFetch(methodPost, `${InstallationsServiceUrl}/${r.id}/jobs/${r.job_name}`, JSON.stringify(r))
}

export const GetJobStatus = (r: GetJobStatusRequest) => {
    return verticalFetch(methodGet, `${InstallationsServiceUrl}/${r.id}/jobs/${r.job_name}`)
}

export const SetInstallationVariable = (r: SetInstallationVariableRequest) => {
    return verticalFetch(methodPost, `${InstallationsServiceUrl}/${r.id}/vars/${r.variable_name}`, JSON.stringify(r))
}

export const GetInstallationVariable = (r: GetInstallationVariablesRequest) => {
    return verticalFetch(methodGet, `${InstallationsServiceUrl}/${r.id}/vars`)
}

//Identity
export const CreateIdentity = (r: CreateIdentityRequest, installation_id: string) => {
    return verticalFetch(methodPost, `${InstallationsServiceUrl}/${installation_id}/identities`, JSON.stringify(r))
}

export const GetIdentity = (installation_id: string) => {
    return verticalFetch(methodGet, `${InstallationsServiceUrl}/${installation_id}/identities`)
}

export const UpdateIdentity = (r: UpdateIdentityRequest, installation_id: string, dcn: string) => {
    return verticalFetch(methodPut, `${InstallationsServiceUrl}/${installation_id}/identities/${dcn}`, JSON.stringify(r))
}

export const DeleteIdentity = (r: DeleteIdentityRequest) => {
    return verticalFetch(methodDelete, `${InstallationsServiceUrl}/${r.installation_id}/identities/${r.dcn}`)
}

export const GetInstallationUsage = (r: InstallationUsageRequest) => {
    return verticalFetch(methodGet, `${InstallationsServiceUrl}/${r.installation_id}/usage?start=${r.start}&end=${r.end}`)
}

export const GetAllAlarms = (page: string, sort: string, search: string) => {
    if(isTest){
        return new Promise((resolve) => resolve(getAllAlarmsMock))
    }
    return verticalFetch(methodGet, `${BackendUrl}/timeseries/alarms/installations?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`)
}

export const GetAlarms = (device_id: string, start: string, end: string) => {
    return verticalFetch(methodGet, `${BackendUrl}/timeseries/alarms/installations?installation_id=${device_id}&start=${start}&end=${end}&sort=-start`)
}

export const getJob = (device_id: string, type: string) => {
    if(isTest){
        return new Promise((resolve) => resolve(getJobMock))
    }
    return verticalFetch(methodGet, `${BackendUrl}/installations/${device_id}/calibrations/${type}`)
}

export const setJob = (device_id: string, type: string, request: JobRequest) => {
    return verticalFetch(methodPost, `${BackendUrl}/installations/${device_id}/calibrations/${type}`, JSON.stringify(request))
}

//Get the history of an installation
export const getHistory = (installation_id: string, startHour: string) => {
    return verticalFetch(methodGet, `${BackendUrl}/timeseries/installations/${installation_id}/history?start=${startHour}&finish=${new Date().toISOString()}&page=-1`)
}

//Installation maintenance
export const maintenanceInstallation = (installation_id: string, note: string) => {
    return verticalFetch(methodPut, `${BackendUrl}/installations/${installation_id}/maintenance`, JSON.stringify({notes: note}))
}

export const checkServerPort = (p: string) => {
    return httpFetch(methodGet, `http://127.0.0.1:${p}/check`);
};

//Get alarms calibration
export const getAlarmsCalibration = (device_id: string) => {
    return verticalFetch(methodGet, `${BackendUrl}/installations/${device_id}/alarms`);
}

//Update alarms calibration
export const updateAlarmsCalibration = (device_id: string, request: AlarmRequest) => {
    return verticalFetch(methodPut, `${BackendUrl}/installations/${device_id}/alarms`, JSON.stringify(request))
}